import React, { useState, useEffect, useCallback } from 'react';
import InputContainer from '../inputContainer/InputContainer';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import  KeywordModal  from '../tags/KeywordModal';
import './InputBox.css';

const InputBox = ({  className,title, variant = 'primary', initialValue, helpText, maxLength, type='text', errorText='', isDisabled = false, isRequired=false, isSearch=false, isTag=false, size, forceValidation=false, value, tagsProp, onChange, onTagRemove}) => {
const [isFocused, setIsFocused] = useState(false);
const [localErrorText, setLocalErrorText] = useState('');
const [tags, setTags] = useState(initialValue || []);
const [isModalOpen, setIsModalOpen] = useState(false);
const [currentInput, setCurrentInput] = useState('');


useEffect(() => {
if (isTag && Array.isArray(value)) {
setTags(value);
}
}, [value, isTag]);


const handleModalSelect = (selectedKeywords) => {
setTags(selectedKeywords);
onChange && onChange(selectedKeywords);  // Add this
setIsModalOpen(false);
};

const validateEmail = (email) => {
var re = /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
return re.test(String(email).toLowerCase());
};

const validateInput = useCallback(() => {
if (isRequired && value === '') setLocalErrorText('This field is mandatory');
if (type === 'email' && value !== '' && !validateEmail(value)) setLocalErrorText();
}, [isRequired, value, type]);

useEffect(() => {
if (forceValidation) {
validateInput();
}
}, [forceValidation, validateInput]);

const handleFocus = () => setIsFocused(true);
const handleBlur = () => {
setIsFocused(false);
validateInput();
};

const handleInputChange = (e) => {
if (e && e.target && typeof e.target.value !== 'undefined') {
if (isTag) {
setCurrentInput(e.target.value); // Update currentInput state instead of tags
}
onChange && onChange(e);
}
};

const handleKeyDown = (e) => {
if (e.key === ',') {
e.preventDefault();
if (currentInput.trim() !== '') {
const newTags = [...tags, currentInput.trim()];
setTags(newTags);
if (typeof onChange === 'function') {
onChange(newTags);
}
setCurrentInput('');
}
}
};

useEffect(() => {
if (isTag && Array.isArray(value)) {
setTags(value);
}

if (!isTag) {
setCurrentInput(value);
}
}, [value, isTag]);

const handleInput = (e) => {
if (typeof onChange === 'function') {
onChange(e);
}
setLocalErrorText('');
};

const handleTagRemove = (tag) => {
const newTags = tags.filter(t => t !== tag);
setTags(newTags);
if (typeof onChange === 'function') {
onChange(newTags);
}
if (typeof onTagRemove === 'function') {
onTagRemove(tag);
}
};


const isError = localErrorText !== '' || errorText !== '';

let inputElement;

if (type === "paragraph") {
inputElement = (
<textarea
disabled={isDisabled}
className={`form-control input-paragraph custom-input ${isFocused ? 'focused' : ''} ${isError ? 'error' : ''} ${isSearch ? 'search-input' : ''} ${isDisabled ? `disabled-${variant}` : ''}`}
placeholder={helpText}
onFocus={handleFocus}
onBlur={handleBlur}
onChange={handleInput}
value={value}
maxLength={maxLength}
/>
);
} else if (type === "number") {
inputElement = (
<input 
type="number"
disabled={isDisabled}
className={`form-control custom-input ${isFocused ? 'focused' : ''} ${isError ? 'error' : ''} ${isSearch ? 'search-input' : ''} ${isTag ? 'tag-input' : ''} ${isDisabled ? `disabled-${variant}` : ''}`}
placeholder={helpText}
onFocus={handleFocus}
onBlur={handleBlur}
onChange={handleInput}
value={value}
/>
);
} else {
inputElement = (
<input 
type={type}
disabled={isDisabled}
className={`form-control custom-input ${isFocused ? 'focused' : ''} ${isError ? 'error' : ''} ${isSearch ? 'search-input' : ''} ${isTag ? 'tag-input' : ''} ${isDisabled ? `disabled-${variant}` : ''}`}
placeholder={helpText}
onFocus={handleFocus}
onBlur={handleBlur}
onChange={isTag ? handleInputChange : handleInput}
value={isTag ? currentInput : value}
onKeyDown={isTag ? handleKeyDown : undefined}
maxLength={maxLength}
/>
);
}


return (
<InputContainer size={size} error={isError} className={className}>
{!isSearch && <label className='input-label'>{title + (isRequired ? '*' : '')}</label>}
<div className="tags-input-container" style={{ position: 'relative' }}>
{isSearch && <SearchIcon style={{ position: 'absolute', left: '8px', top: '50%', transform: 'translateY(-50%)', zIndex: 1 }} />}

{isTag && tags.map((tag, index) => (
<div key={index} className="tag">
<span className="tag-text">{tag}</span>
<CloseIcon className="tag-remove" onClick={() => handleTagRemove(tag)} width={12} height={12} />
</div>
))}
{inputElement}
</div>

{isError && <div className='error-text text-h6'>{localErrorText || errorText}</div>}
{isModalOpen && <KeywordModal selectedItems={tags}  onClose={() => setIsModalOpen(false)} onConfirm={handleModalSelect} />}
</InputContainer>
);
};

export default InputBox;