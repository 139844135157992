import React from 'react';
import AdminWrapper from '../components/adminwrapper/AdminWrapper';

function ManageAdmin() {
  return (
    <AdminWrapper>
    <div>
      <h1>This is the Manage Admin page.</h1>
    </div>
    </AdminWrapper>
  );
}

export default ManageAdmin;
