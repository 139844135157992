import React, { createContext, useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import UserContext from '../components/UserContext';

const getUserIdFromToken = () => {
  const token = localStorage.getItem('token');
  return token ? jwtDecode(token).userId : null;
};

const myBucketlistReducer = (state, action) => {
  switch (action.type) {
      case 'ADD_TO_MYBUCKETLIST':
          return [...state, action.activityId];
      case 'REMOVE_FROM_MYBUCKETLIST':
          return state.filter(id => id !== action.activityId);
      case 'SET_MYBUCKETLIST':
          return action.activityIds;
      case 'CLEAR_MYBUCKETLIST':
          return [];
      default:
          return state;
  }
};


export const MyBucketlistContext = createContext();

export const MyBucketlistProvider = ({ children }) => {
  const [myBucketlist, dispatch] = useReducer(myBucketlistReducer, []);
  const { userStatus } = useContext(UserContext); 

  useEffect(() => {
    const initializeMyBucketlist = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const userId = getUserIdFromToken();
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/user/${userId}/myBucketlisted-activities`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const activityIds = (response?.data?.activities || []).map(activity => activity._id);
        dispatch({ type: 'SET_MYBUCKETLIST', activityIds });
      } catch (error) {
        console.error('Error initializing myBucketlist:', error);
      }
    };

    initializeMyBucketlist();
  }, []);

  useEffect(() => {
    const initializeMyBucketlist = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;
  
      try {
        const userId = getUserIdFromToken();
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/user/${userId}/myBucketlisted-activities`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        const activityIds = (response?.data?.activities || []).map(activity => activity._id);
        dispatch({ type: 'SET_MYBUCKETLIST', activityIds });
      } catch (error) {
        console.error('Error initializing myBucketlist:', error);
      }
    };
  
    initializeMyBucketlist();
  }, [userStatus]);

  return (
    <MyBucketlistContext.Provider value={{ myBucketlist, dispatch }}>
      {children}
    </MyBucketlistContext.Provider>
  );
};

export const useMyBucketlist = () => {
  const context = useContext(MyBucketlistContext);
  if (!context) {
    throw new Error('useMyBucketlist must be used within a MyBucketlistProvider');
  }
  return context;
};
