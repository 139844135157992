import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "../components/button/Button";
import { useParams, useNavigate } from 'react-router-dom';
import "./CreateEditPlan.css";
import CloseIcon from "../assets/icons/close-white.svg";
import ItineraryPlan from "../components/Planner/ItineraryPlan";
import SharePlanModal from '../components/sharemodal/SharePlanModal';
import { useCurrentUser} from '../components/currentUser';
import DeleteIcon from '../assets/icons/delete.svg';
import Modal from '../components/modal/modal';
import EditIcon from '../assets/icons/edit-color.svg'
import LoadingAnimation from '../components/loading/Loading';
import jwt_decode from "jwt-decode";

const CreateEditPlan = () => {
  const [plan, setPlan] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);
  const [tempPlanName, setTempPlanName] = useState("");
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const { slug } = useParams();
  const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const [planImageUrl, setPlanImageUrl] = useState("");
  const [note, setNote] = useState("");
  const [noteCharacterCount, setNoteCharacterCount] = useState(0);
  const [showShareModal, setShowShareModal] = useState(false);
  const currentUser = useCurrentUser();
  const [sharedUserProfiles, setSharedUserProfiles] = useState([]);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
const [userToRemove, setUserToRemove] = useState(null);
const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
const navigate = useNavigate();
const isPrimaryUser = currentUser && plan && currentUser.userId === plan.primaryUser;

const handleDeleteButtonClick = () => {
  if (isPrimaryUser) {
    openDeleteConfirmationModal();
  } else {
    removeCurrentUserFromSharedList();
  }
};

const removeCurrentUserFromSharedList = async () => {
  try {
    await axios.post(`${serverBaseUrl}/api/remove-user-from-plan`, {
      planId: plan._id,
      userEmail: currentUser.email
    });
    fetchPlan(); // Refetch plan data to update UI
    navigate('/planner'); // Redirect the user to the planner page
  } catch (error) {
    console.error("Error removing user from plan:", error);
  }
};

const fetchPlan = async () => {
  try {
    // Get JWT token directly from the browser's localStorage
    const token = localStorage.getItem('token'); // Assuming the token is stored with the key 'token'
    
    if (!token) {
      navigate('/login');
      return;
    }
    const decodedToken = jwt_decode(token);

    const userId = decodedToken.userId;

    console.log(`Fetching profile for userId: ${userId}`);
    // Fetch the current user's profile using their user ID
    const currentUserProfileResponse = await axios.get(`${serverBaseUrl}/profile`, { params: { userId } });
    
    const currentUserEmail = currentUserProfileResponse.data.email;
    // Fetch the plan data
    const response = await axios.get(`${serverBaseUrl}/api/plans/${slug}`);
    const fetchedPlan = response.data;

    // Check if the current user is the primary user or a shared user
    const isPrimaryUser = fetchedPlan.primaryUser === userId;

    const isSharedUser = fetchedPlan.sharingWith.some(shared => shared.email === currentUserEmail);

    if (!isPrimaryUser && !isSharedUser) {
      navigate('/login');
      return;
    }

    setPlan(fetchedPlan);

    // Fetch profile details of users you are sharing with using their emails
    const userEmails = fetchedPlan.sharingWith.map(shared => shared.email);
    const userProfilesResponse = await axios.post(`${serverBaseUrl}/api/users/bulk-profiles`, { userEmails });
    setSharedUserProfiles(userProfilesResponse.data);

    setPlanImageUrl(fetchedPlan.planImage);
  } catch (error) {
    console.error("Error fetching plan:", error);
    navigate('/error'); // Navigate to an error page or handle the error as you see fit
  }
};

useEffect(() => {
  fetchPlan(); // Call fetchPlan inside useEffect
}, [slug, serverBaseUrl]);

useEffect(() => {
  if (plan && plan.planImage) {
    setPlanImageUrl(plan.planImage);
  }
}, [plan]);


if (!plan) {
  return <LoadingAnimation />;
}


// Function to toggle edit mode
const toggleEditName = () => {
  setIsEditingName(!isEditingName);
  setTempPlanName(plan.name); // Set initial value to current plan name
};

// Function to handle plan name change
const handlePlanNameChange = (e) => {
  setTempPlanName(e.target.value);
};

const savePlanName = async () => {
  if (!tempPlanName.trim()) {
      // Handle case where the name is empty or just whitespace
      alert("Please enter a valid plan name."); // Replace alert with a more user-friendly approach
      return;
  }

  try {
      const response = await axios.put(`${serverBaseUrl}/api/plans/${plan._id}/name`, { newName: tempPlanName });
      // Update the local state or re-fetch the plan as needed
      setPlan(response.data);
      setIsEditingName(false);
      alert("Plan name updated successfully."); // Notify user of success
  } catch (error) {
      console.error('Error saving plan name:', error);

      let errorMessage = "An error occurred while saving the plan name.";

      // Customize error message based on the response status code
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 404) {
              errorMessage = "Plan not found.";
          } else if (error.response.status === 400) {
              errorMessage = "Invalid plan name provided.";
          } else {
              errorMessage = error.response.data.message || errorMessage;
          }
      } else if (error.request) {
          // The request was made but no response was received
          errorMessage = "No response received from server.";
      }

      // Display the error message to the user
      alert(errorMessage); // Replace alert with a more user-friendly approach
  }
};


const openRemoveUserModal = (userEmail) => {
  setUserToRemove(userEmail);
  setShowRemoveUserModal(true);
};

const closeRemoveUserModal = () => {
  setShowRemoveUserModal(false);
  setUserToRemove(null);
};

const removeUser = async () => {
  // Call backend API to remove user from sharingWith
  try {
    await axios.post(`${serverBaseUrl}/api/remove-user-from-plan`, {
      planId: plan._id,
      userEmail: userToRemove
    });
    fetchPlan(); // Refetch plan data to update UI
  } catch (error) {
    console.error("Error removing user from plan:", error);
  }
  closeRemoveUserModal();
};


  const handleOpenShareModal = () => {
    setShowShareModal(true); // This should open the modal
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false); // This should close the modal
  };


  const handleNoteChange = (e) => {
    setNote(e.target.value);
    setNoteCharacterCount(e.target.value.length);
  };

  const submitNote = async () => {
    if (note.trim()) {
  
      const payload = {
        text: note,
        userId: currentUser.userId
      };
  
      try {
        const response = await axios.post(`${serverBaseUrl}/api/plans/${plan._id}/notes`, payload);
        if (response && response.data) {
          setPlan(response.data); // Update the plan state
          setNote(""); // Reset the note input field
  
          fetchPlan(); 
        }
      } catch (error) {
        console.error("Error submitting note:", error);
      }
    }
  };
  
  
  const formatDate = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const deleteNote = async (noteId) => {
    try {
      const response = await axios.delete(`${serverBaseUrl}/api/plans/${plan._id}/notes/${noteId}`);
      setPlan(response.data.plan); // Update plan after note deletion
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  const triggerProfileImageUpload = () => {
    document.getElementById("profile-image-upload").click();
  };

    // Function to call the backend to delete the plan
    const deletePlan = async () => {
      try {
        const response = await axios.delete(`${serverBaseUrl}/api/plans/${plan._id}`);
        if (response.status === 200) {
          navigate('/planner'); // Redirect to '/planner' after successful deletion
        }
      } catch (error) {
        console.error('Error deleting the plan:', error);
      }
    };

      // Function to open the delete confirmation modal
  const openDeleteConfirmationModal = () => {
    setShowDeleteConfirmation(true);
  };

  // Function to close the delete confirmation modal
  const closeDeleteConfirmationModal = () => {
    setShowDeleteConfirmation(false);
  };

  const handleProfileImageUpload = async (event) => {
    setIsUploading(true);
    const fileInput = document.getElementById("profile-image-upload");
    const file = fileInput.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);

    const fileSizeInMB = file.size / 1024 / 1024;
    const totalFakeLoadTime = fileSizeInMB * 20000;

    setLoadingPercent(0);
    let loadTimeElapsed = 0;
    const fakeLoadInterval = 200;

    const fakeLoad = setInterval(() => {
      loadTimeElapsed += fakeLoadInterval;
      const percentComplete = Math.floor(
        Math.min(100, (loadTimeElapsed / totalFakeLoadTime) * 100)
      );
      setLoadingPercent(percentComplete);
      setUploadStatus(
        `${percentComplete.toString().padStart(3, "0")}% UPLOADED`
      );
      if (loadTimeElapsed >= totalFakeLoadTime) {
        clearInterval(fakeLoad);
        setUploadStatus("Processing upload...");
        setTimeout(async () => {
          try {
            const response = await axios.post(
              `${serverBaseUrl}/api/upload-plan-profile-image/${plan._id}`,
              formData
            );
            setPlan((prevPlan) => ({
              ...prevPlan,
              planImage: response.data.Location,
            }));
            setUploadStatus("Profile image uploaded successfully!");
            setIsUploading(false);
          } catch (error) {
            setUploadStatus("Error uploading profile image");
            setShowErrorToast(true);
            setIsUploading(false);
          }

          fileInput.value = "";
        }, 100);
      }
    }, fakeLoadInterval);
  };
  const removeProfileImage = async () => {
    setIsUploading(true);
    try {
      await axios.post(`${serverBaseUrl}/api/remove-plan-image/${plan._id}`, {
        imageType: "planImage",
      });
      setPlan({ ...plan, planImage: "" });
      setUploadStatus("Profile image removed");
      setIsUploading(false);
    } catch (error) {
      console.error("Error removing profile image:", error);
      setIsUploading(false);
    }
  };

  const closeErrorToast = () => setShowErrorToast(false);

  const renderPlanTitle = () => {
    if (isEditingName) {
        return (
            <div className="title-plan">
                <input
                    type="text"
                    value={tempPlanName}
                    onChange={handlePlanNameChange}
                    autoFocus
                />
                <Button
                    label="Save"
                    variant="primary"
                    size="small"
                    handleClick={savePlanName}
                />
            </div>
        );
    } else {
        return (
            <div className="title-plan">
                <h1>{plan.name}</h1>
                <img
                    src={EditIcon}
                    alt="Edit"
                    onClick={toggleEditName}
                />
                {!isEditingName && (
                  <Button
                    label={isPrimaryUser ? "Delete Plan" : "Remove from My Plans"}
                    variant="tertiary"
                    size="small"
                    handleClick={handleDeleteButtonClick}
                  />
                )}

            </div>
        );
    }
};

  const planImageStyle = planImageUrl
    ? { backgroundImage: `url(${planImageUrl})` }
    : {};

    const renderCollaborators = () => {
  if (sharedUserProfiles.length === 0) {
    return <div className="no-collaborators">You are not sharing this plan with anyone.</div>;
  }

  return sharedUserProfiles.map((user, index) => {
    const firstNameInitial = user.firstName ? user.firstName[0].toUpperCase() : '';
    const lastNameInitial = user.lastName ? user.lastName[0].toUpperCase() : '';
    const initials = firstNameInitial + lastNameInitial;
    const tooltipText = user.userName || `${user.firstName || ''} ${user.lastName || ''}`.trim() || user.email;
    const displayInitials = initials || user.email[0].toUpperCase();

    return (
      <div key={index} className="collaborator-info" title={tooltipText}>
        {user.profilePhoto ? (
          <img src={user.profilePhoto} alt="Collaborator" className="profilePhoto" />
        ) : (
          <div className="collaborator-initials">{displayInitials}</div>
        )}
        {currentUser.userId === plan.primaryUser && (
          <div className="delete-icon-container" onClick={() => openRemoveUserModal(user.email)}>
            <img src={DeleteIcon} alt="Delete" />
          </div>
        )}
      </div>
    );
  });
};


    const renderNotesTable = () => {
        return (
          <div className="notes-column">
            <table className="itinerary-table">
              <thead>
                <tr>
                  <th>Notes & Reminders</th>
                </tr>
              </thead>
              <div className="note-input-section">
                <textarea
                  value={note}
                  onChange={handleNoteChange}
                  maxLength={320}
                  placeholder="Write a note..."
                />
                <div className="note-input-footer">
                  <span>{noteCharacterCount}/320</span>
                  <button onClick={submitNote}>Submit</button>
                </div>
              </div>
              <tbody>
              {plan.notes.map((note, index) => (
                <tr key={index}>
                    <td>
                    <div className="note-entry">
                        <div className="note-user-details">
                        {/* Safely access createdBy properties */}
                        {note.createdBy?.profilePhoto ? (
                            <img src={note.createdBy.profilePhoto} alt="Profile" className="note-profile-photo" />
                        ) : (
                            <div className="note-profile-initials">
                            {note.createdBy?.firstName?.[0]}{note.createdBy?.lastName?.[0]}
                            </div>
                        )}
                        <div className="note-info">
                            <strong>{note.createdBy?.userName || note.createdBy?.firstName || note.createdBy?.email || 'Unknown User'}</strong>
                            <p>{note.text}</p>
                            <small>{formatDate(note.createdAt)}</small>
                        </div>
                        </div>
                        {/* Delete icon for the user's own notes */}
                        {currentUser && note.createdBy._id === currentUser.userId && (
                          <img src={DeleteIcon} alt="Delete" className="note-delete-icon" onClick={() => deleteNote(note._id)} />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      };
      

  return (
    <div className="create-edit-plan">
      {showRemoveUserModal && (
    <Modal
      title="Remove User"
      message={`Are you sure you want to remove ${userToRemove}?`}
      show={showRemoveUserModal}
      handleClose={closeRemoveUserModal}
      actions={[
        { label: 'Remove User', onClick: removeUser, variant: 'danger' },
        { label: 'Cancel', onClick: closeRemoveUserModal, variant: 'secondary' }
      ]}
    />
  )}
      {showErrorToast && (
        <div className="error-toast">
          <p>{uploadStatus}</p>
          <img src={CloseIcon} alt="Close" onClick={closeErrorToast} />
        </div>
      )}
      <div className="plan-header">
        <div className="plan-title-area">
          {renderPlanTitle()}
          <div className="shared-plan-friends">
            <p>You are sharing this plan with:</p>
            <div className="sharedWith-images">
  {renderCollaborators()}
</div>
            <Button label="Add Collaborators" size='medium' variant='cta' handleClick={handleOpenShareModal} />
        </div>
        </div>

        {showShareModal && (
        <SharePlanModal
          planId={plan?._id}
          isOpen={showShareModal}
          onClose={handleCloseShareModal}
          currentUser={currentUser}
          planDetails={plan}
        />
      )}
      {showDeleteConfirmation && (
  <Modal
    title={isPrimaryUser ? "Delete Plan" : "Remove Plan"}
    message={isPrimaryUser
      ? "Once deleted, it cannot be retrieved."
      : "Are you sure you want to remove this plan from your profile?"
    }
    show={showDeleteConfirmation}
    handleClose={closeDeleteConfirmationModal}
    actions={[
      { label: 'Yes, Delete', onClick: isPrimaryUser ? deletePlan : removeCurrentUserFromSharedList, variant: 'danger' },
      { label: 'Cancel', onClick: closeDeleteConfirmationModal, variant: 'secondary' }
    ]}
  />
)}

        <div className="plan-profile-image-container">
          <div className="plan-profile-image" style={planImageStyle}>
            <div className={`image-overlay ${isUploading ? "show" : ""}`}>
              <input
                type="file"
                id="profile-image-upload"
                hidden
                onChange={handleProfileImageUpload}
              />
              <div className="overlay-content">
                {isUploading && (
                  <div className="overlay-status">
                    {loadingPercent}%<small>UPLOADED</small>
                  </div>
                )}
                <div className="upload-image-buttons">
                {!isUploading && (
                  <>
                  
                    <Button
                      label={plan.planImage ? "Change Image" : "Upload Image"}
                      variant="primary"
                      size="small"
                      handleClick={triggerProfileImageUpload}
                    />
                    {plan.planImage && (
                      <Button
                        label="Remove Image"
                        variant="secondary"
                        size="small"
                        handleClick={removeProfileImage}
                      />
                      
                    )}
                    
                  </>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="itinerary-section">
        <div className="itinerary-creator">
          <h2>Itinerary creator</h2>
          <p>
            Write Notes and Reminders, Move Cards and Create Itinerary For Your
            Next Holiday
          </p>
        </div>
        <ItineraryPlan planId={plan._id} setPlan={setPlan} createdBy={currentUser.userId} />
        {renderNotesTable()}
      </div>
    </div>
  );
};

export default CreateEditPlan;
