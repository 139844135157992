import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ActivityHover from "../activityCard/ActivityHover";
import "./ItineraryPlan.css";
import editIcon from "../../assets/icons/edit-color.svg";
import HorizontalIcon from "../../assets/icons/horizontalMenuBlack.svg";
import InputBox from "../InputBox/InputBox";
import Button from "../button/Button";
import io from "socket.io-client";
import AddIcon from '../../assets/icons/add.svg';
import Modal from "../modal/modal";

let socket;

const ItineraryPlan = ({ planId, createdBy }) => {
const [activities, setActivities] = useState([]);
const [droppedItems, setDroppedItems] = useState([]);
const [dragOverIndex, setDragOverIndex] = useState(null);
const [editModes, setEditModes] = useState({});
const [draggedItem, setDraggedItem] = useState(null);
const [showRemoveActivityModal, setShowRemoveActivityModal] = useState(false);
const [activityToRemove, setActivityToRemove] = useState(null);

const [showModal, setShowModal] = useState(false);
const [selectedItineraryId, setSelectedItineraryId] = useState(null);
const [editableTitle, setEditableTitle] = useState("Itinerary Zone");
const [activityDetails, setActivityDetails] = useState({});
const [activeDropdown, setActiveDropdown] = useState(null);
const [showMenu, setShowMenu] = useState(false);
const iconRefs = useRef({});
const dropdownMenuRef = useRef(null);
const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
const [currentPlan, setCurrentPlan] = useState({});
const [currentItineraryId, setCurrentItineraryId] = useState(null);
const [itineraries, setItineraries] = useState([]);
const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
const [tempTitles, setTempTitles] = useState({});
const [activityTimes, setActivityTimes] = useState({});
const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
const [activityPendingRemoval, setActivityPendingRemoval] = useState(null);
const [hiddenActivities, setHiddenActivities] = useState([]);
const [stagedChanges, setStagedChanges] = useState({ adds: {}, removes: {} });

const promptRemoveActivity = (activityId) => {
setActivityPendingRemoval(activityId);
setShowConfirmRemoveModal(true);
};

const { slug } = useParams();

const toggleEditMode = (itineraryId) => {
setEditModes(prevModes => ({
...prevModes,
[itineraryId]: !prevModes[itineraryId]
}));
};

const handleEditClick = (itineraryId) => {
if (!editModes[itineraryId]) {
toggleEditMode(itineraryId);
}
};

const handleDropdownClick = (itineraryId, event) => {
event.stopPropagation(); 
if (editModes[itineraryId]) {
toggleDropdown(itineraryId, event);
}
};

const onToggleEditMode = (itineraryId) => {
setEditModes(prev => ({
...prev,
[itineraryId]: !prev[itineraryId]
}));
};

const toggleDropdown = (itineraryId, event) => {
event.stopPropagation(); // Prevent click from propagating to the document
if (activeDropdown !== itineraryId) {
const iconRect = iconRefs.current[itineraryId].getBoundingClientRect();
const scrollY = window.scrollY || document.documentElement.scrollTop;
setDropdownPosition({
top: iconRect.bottom + scrollY + 8, // Account for scrolling
left: iconRect.left,
});
}
setActiveDropdown(activeDropdown === itineraryId ? null : itineraryId);
};

const closeDropdown = () => {
setActiveDropdown(null);
};

useEffect(() => {
const handleClickOutside = (event) => {
if (
activeDropdown &&
iconRefs.current[activeDropdown] &&
dropdownMenuRef.current &&
!iconRefs.current[activeDropdown].contains(event.target) &&
!dropdownMenuRef.current.contains(event.target)
) { 
closeDropdown();
}
};

document.addEventListener("mousedown", handleClickOutside);
return () => document.removeEventListener("mousedown", handleClickOutside);
}, [activeDropdown]);

// Drag and Drop Functions

const handleDragOver = (e, index) => {
e.preventDefault();
setDragOverIndex(index);
};

const handleDrop = (e, itineraryId) => {
  e.preventDefault();
  if (!editModes[itineraryId]) {
    return;
  }

  const activityId = e.dataTransfer.getData('activityId');
  // Find the activity in the current state to check if it's already added
  const existingIndex = droppedItems[itineraryId]?.findIndex(item => item._id === activityId);

  // If the activity is found, remove it from its current position
  if (existingIndex > -1) {
    setDroppedItems(prevDroppedItems => {
      const updatedDroppedItems = { ...prevDroppedItems };
      const itineraryActivities = [...(updatedDroppedItems[itineraryId] || [])];
      // Remove the activity from its current position
      itineraryActivities.splice(existingIndex, 1);
      // Insert the activity at the new position determined by dragOverIndex
      itineraryActivities.splice(dragOverIndex, 0, droppedItems[itineraryId][existingIndex]);
      updatedDroppedItems[itineraryId] = itineraryActivities;
      return updatedDroppedItems;
    });
  } else {
    // If the activity is not already in the itinerary, add it
    const activityToAdd = activities.find(act => act._id === activityId);
    if (activityToAdd) {
      setDroppedItems(prevDroppedItems => {
        const updatedDroppedItems = { ...prevDroppedItems };
        const itineraryActivities = [...(updatedDroppedItems[itineraryId] || [])];
        itineraryActivities.splice(dragOverIndex, 0, activityToAdd);
        updatedDroppedItems[itineraryId] = itineraryActivities;
        return updatedDroppedItems;
      });
    }
  }

  setDragOverIndex(null);
};


const handleTitleChange = (itineraryId, newTitle) => {
setTempTitles({ ...tempTitles, [itineraryId]: newTitle });
};

const handleTimeChange = (itineraryId, activityId, newTime) => {
  setActivityTimes(prev => ({
    ...prev,
    [itineraryId]: {
      ...(prev[itineraryId] || {}),
      [activityId]: newTime
    }
  }));
};

const handleActivityRemoval = (activityId, itineraryId) => {
  // Mark the activity as hidden
  setHiddenActivities(prevHiddenActivities => [...prevHiddenActivities, activityId]);
  
  // Existing removal logic
  setStagedChanges(prev => {
    const newRemoves = [...(prev.removes[itineraryId] || []), activityId];
    return { ...prev, removes: { ...prev.removes, [itineraryId]: newRemoves } };
  });
};




const publishItinerary = async (itineraryId, event) => {
  event.preventDefault();
  event.stopPropagation();

  // Prepare the final list of activities for submission, including handling adds and removes.
  const finalActivities = (droppedItems[itineraryId] || [])
    .filter(activity => !stagedChanges.removes[itineraryId]?.includes(activity._id)) // Exclude removed activities
    .concat(stagedChanges.adds[itineraryId] || []) // Include added activities
    .map(activity => ({
      activityId: activity._id,
      order: droppedItems[itineraryId].findIndex(item => item._id === activity._id), // Find original order, if exists
      time: activityTimes[itineraryId]?.[activity._id] || '' // Include time, if specified
    }));

  const updatedTitle = tempTitles[itineraryId] || itineraries.find(itin => itin._id === itineraryId)?.title;

  try {
    const response = await axios.put(`${serverBaseUrl}/api/plans/${planId}/itineraries/${itineraryId}/publish`, {
      title: updatedTitle,
      activities: finalActivities.map(({ activityId, order, time }) => ({
        activity: activityId,
        order,
        time
      })),
    });

    if (response.status === 200) {
      window.location.reload(); // Or update state as needed to reflect changes
    }
  } catch (error) {
    console.error("Error publishing itinerary:", error);
  }
};





const confirmDeleteItinerary = (itineraryId, event) => {
event.preventDefault();
event.stopPropagation();
setSelectedItineraryId(itineraryId);
setShowModal(true);
closeDropdown();
};

const deleteItinerary = async () => {
console.log('Selected Itinerary: ', selectedItineraryId);
await axios.delete(`${serverBaseUrl}/api/plans/${planId}/itineraries/${selectedItineraryId}`);
setItineraries(itineraries.filter(it => it._id !== selectedItineraryId));
setShowModal(false);
};

const addNewItinerary = async () => {
try {
const newItinerary = {
createdBy: createdBy,
activities: [],
title: 'Itinerary 1'
};

const response = await axios.post(`${serverBaseUrl}/api/plans/${planId}/itineraries`, newItinerary);
if (response.data) {
setItineraries(prevItineraries => [...prevItineraries, response.data]);
}
} catch (error) {
console.error('Error creating new itinerary:', error);
}
};

const onAddToItinerary = (activityId, itineraryId) => {
const activityToAdd = activities.find(act => act._id === activityId);

if (activityToAdd && editModes[itineraryId]) {
setDroppedItems(prevDroppedItems => {
const updatedDroppedItems = { ...prevDroppedItems };
if (!updatedDroppedItems[itineraryId]) {
updatedDroppedItems[itineraryId] = [];
}

let newActivities = [...updatedDroppedItems[itineraryId]];
// Remove the activity if it already exists in the list
newActivities = newActivities.filter(act => act._id !== activityId);
// Add the activity to the end of the list
newActivities.push(activityToAdd);

updatedDroppedItems[itineraryId] = newActivities;
return updatedDroppedItems;
});
}
};


useEffect(() => {
const fetchPlanAndItinerary = async () => {
try {
const response = await axios.get(`${serverBaseUrl}/api/plans/${slug}`);
if (response.data) {
setActivities(response.data.activities || []);

const newDroppedItems = {};
const newActivityDetails = {};
const newActivityTimes = {};

response.data.itineraries.forEach(itinerary => {
newDroppedItems[itinerary._id] = [];
newActivityTimes[itinerary._id] = {};

itinerary.activities.forEach(activityTime => {
const { activity, time } = activityTime;
const activityObj = response.data.activities.find(a => a._id === activity);

if (activityObj) {
newDroppedItems[itinerary._id].push(activityObj);
newActivityDetails[activity] = { time };
newActivityTimes[itinerary._id][activity] = time;
}
});
});

setDroppedItems(newDroppedItems);
setActivityDetails(newActivityDetails);
setActivityTimes(newActivityTimes);
setItineraries(response.data.itineraries);
}
} catch (error) {
console.error("Error fetching plan and itinerary:", error);
}
};

fetchPlanAndItinerary();
}, [slug, serverBaseUrl]);

const handleOpenActivityPage = (activityId) => {
window.open(`/activity/${activityId}`, '_blank');
};

return (
<div className="itinerary-plan">
<div className="activitiesHoverCard-container">
{/* Heading and Add Button */}
<div className="heading-itineraryPlan">
<div className="title-line-itinerary">
<h4>Activities and Places</h4>
<img src={AddIcon} alt="Add An Itinerary" onClick={addNewItinerary} />
</div>
<p>Long press to drag or click on the cards to get actions.</p>
</div>

{/* Activities List */}
{activities.map((activity) => (
<ActivityHover
key={activity._id}
activity={activity}
isInActivitiesSection={true}
itineraries={itineraries}
editModes={editModes}
onOpenActivityPage={handleOpenActivityPage}
onAddToItinerary={onAddToItinerary}
planId={planId}
serverBaseUrl={serverBaseUrl}
promptRemoveActivity={promptRemoveActivity}
/>
))}

</div>

{/* Itineraries List */}
<div className="itineraries-container">
{itineraries.map(itinerary => (
<div 
key={itinerary._id} 
className="drop-zone" 
onDragOver={editModes[itinerary._id] ? handleDragOver : undefined}
onDrop={editModes[itinerary._id] ? (e) => handleDrop(e, itinerary._id) : undefined}
>
<div className="heading-itineraryPlan">
<div className="title-line-itinerary">
{editModes[itinerary._id] ? (
<React.Fragment>
<InputBox
value={tempTitles[itinerary._id] || itinerary.title}
helpText='Name the Itinerary'
onChange={(e) => handleTitleChange(itinerary._id, e.target.value)}
title='Itinerary Name'
maxLength={30}
size='medium'
/>
<img
ref={el => iconRefs.current[itinerary._id] = el}
src={HorizontalIcon}
alt="Menu"
onClick={(event) => handleDropdownClick(itinerary._id, event)}
/>
</React.Fragment>
) : (
<React.Fragment>
<h4>{itinerary.title}</h4>
<img
src={editIcon}
alt="Edit"
onClick={() => handleEditClick(itinerary._id)}
/>
</React.Fragment>
)}
{activeDropdown === itinerary._id && (
<div
className="dropdown-menu"
ref={dropdownMenuRef}
style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
onClick={(e) => e.stopPropagation()}
>
<button className="dropdown-option" onClick={(e) => publishItinerary(itinerary._id, e)}>Publish Now</button>
<button className="dropdown-option" onClick={(e) => confirmDeleteItinerary(itinerary._id, e)}>Delete Itinerary</button>
</div>
)}


</div>
{/* <p>Long press to drag or click on the cards to get actions.</p> */}
</div>
<div className={`dropped-items-container ${editModes[itinerary._id] ? 'edit-modes' : ''}`}>
{editModes[itinerary._id] ? (
<>
{/* Edit Mode Content */}
<div className="drop-area">Drop the activity cards here</div>
<hr/>

{droppedItems[itinerary._id]?.map((activity, index) => {
  const isHidden = hiddenActivities.includes(activity._id);
  
  return (
    <div 
      key={activity._id} 
      className={`activityHover-editMode ${isHidden ? 'hidden' : ''}`} // Apply 'hidden' class based on condition
    >
      {dragOverIndex === index && <div className="drag-over-line"></div>}
      <InputBox
        value={activityTimes[itinerary._id]?.[activity._id] || ""}
        onChange={(e) => handleTimeChange(itinerary._id, activity._id, e.target.value)}
        maxLength={30}
        title="Add Time or any text (30 characters max)"
        size="medium"
        className={isHidden ? 'hidden' : ''} // Also hide the input box if the activity is hidden
      />
      <ActivityHover
        activity={activity}
        itineraryId={itinerary._id}
        editModes={editModes}
        serverBaseUrl={serverBaseUrl}
        isVisible={!isHidden}
        planId={planId}
        onRemoveActivity={handleActivityRemoval}
        onOpenActivityPage={handleOpenActivityPage}
        onAddToItinerary={onAddToItinerary}
        promptRemoveActivity={promptRemoveActivity}
        // No need to pass isVisible prop here, the CSS class takes care of visibility
      />
    </div>
  );
})}




</>
) : (
<>
{/* Read-Only Mode */}
{droppedItems[itinerary._id]?.map(activity => (
<div className="activityHover-cards-readonly" key={activity._id}>
<h5>{activityTimes[itinerary._id]?.[activity._id] || "Not Set"}</h5>
<ActivityHover activity={activity} />
</div>
))}
<p className="placeholder-text">
To add Activity Cards to the Itinerary, enable edit mode by clicking on the pencil icon.
</p>
</>
)}
</div>
</div>
))}
</div>

{/* Modals for Removing Activity and Deleting Itinerary */}
<Modal
title="Remove Activity from Plan"
message="Removing the activity from a plan will also remove the activity from your itineraries."
handleClose={() => setShowConfirmRemoveModal(false)}
show={showConfirmRemoveModal}
actions={[
{
label: "Yes, Delete It",
variant: "danger",
},
{
label: "Cancel",
variant: "secondary",
onClick: () => setShowConfirmRemoveModal(false)
}
]}
/>
<Modal
title="Delete Itinerary"
message="Are you sure you want to delete this itinerary? This action cannot be undone."
handleClose={() => setShowModal(false)}
show={showModal}
actions={[
{ label: "Delete It", variant: "danger", onClick: deleteItinerary },
{ label: "Cancel", variant: "secondary", onClick: () => setShowModal(false) }
]}
/>
</div>
);
};

export default ItineraryPlan;