import React, { useState, useEffect, useRef, useContext, useCallback} from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Button from "../button/Button";
import MenuOutline from "../../assets/icons/menu-outline.svg";
import MenuFill from "../../assets/icons/menu-fill.svg";
import "./Navbar.css";
import UserContext from "../UserContext";
import jwtDecode from "jwt-decode";
import { MyBucketlistContext } from "../MyBucketlistContext";
import SearchBar from "../searchBar/SearchBar";


const Navbar = () => {
const [menuOpen, setMenuOpen] = useState(false);
const [userDetails, setUserDetails] = useState(null);
const { userType, setUserType } = useContext(UserContext);
const { dispatch } = useContext(MyBucketlistContext);
const navigate = useNavigate();
const menuRef = useRef(null);
const menuButtonRef = useRef(null);
const profilePhotoRef = useRef();
const guestMenuButtonRef = useRef(null);
const location = useLocation();
const shouldHideSearchBar = location.pathname === '/' || location.pathname === '/userDashboard';
  const isOnAdminSubdomain = window.location.hostname === "admin.rebrid.co";

const isCurrentRoute = (route) => {
const match = location.pathname === route || location.pathname.startsWith(`${route}/`);
return match;
};


// Utility Functions
const getRandomColor = () => {
const h = Math.floor(Math.random() * 361);
const s = Math.floor(Math.random() * (100 - 60) + 60);
const l = Math.floor(Math.random() * (60 - 30) + 30);
return `hsl(${h}, ${s}%, ${l}%)`;
};

const toggleMenu = () => {
setMenuOpen((prev) => !prev);
};

const closeMenu = () => {
  setMenuOpen(false);
};


const getProfileIcon = () => {
if (userDetails?.profilePhoto) {
return (
<img
src={userDetails.profilePhoto}
alt="Profile Icon"
className="profile-icon"
/>
);
}
return <span className="profile-initials">{getInitials()}</span>;
};

const getInitials = () => {
if (userDetails?.firstName && userDetails?.lastName) {
return userDetails.firstName[0] + userDetails.lastName[0];
}
return "";
};

const checkTokenExpiration = (token) => {
let decoded;
try {
decoded = jwtDecode(token);
} catch (error) {
console.error("Failed to decode the token:", error);
return true;
}
const currentTimestamp = Math.floor(Date.now() / 1000);
return decoded.exp && currentTimestamp > decoded.exp;
};

const handleLogout = useCallback(async () => {
const token = localStorage.getItem("token");
if (!token) return;

try {
await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/session/end`, {
token,
});
} catch (error) {
console.error(error);
}

["token", "user", "userType", "profileAlertClosed"].forEach((item) =>
localStorage.removeItem(item)
);

dispatch({ type: "CLEAR_MYBUCKETLIST" });
setUserType(null);
navigate("/");
window.location.reload();
setUserDetails(null);
}, [dispatch, setUserType, navigate]);

useEffect(() => {
const randomColor = getRandomColor();
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(
`.profile-icon-container { background-color: ${randomColor}; }`,
styleSheet.cssRules.length
);

const handleClickOutside = (event) => {
// If the menu is open and the clicked target is outside both the menu and the menu button, close the menu
if (
menuRef.current &&
!menuRef.current.contains(event.target) &&
menuButtonRef.current &&
!menuButtonRef.current.contains(event.target) &&
(!profilePhotoRef.current ||
!profilePhotoRef.current.contains(event.target))
) {
setMenuOpen(false);
}
};

document.addEventListener("click", handleClickOutside);

return () => {
document.removeEventListener("click", handleClickOutside);
};
}, []);

useEffect(() => {
  const token = localStorage.getItem('token');
  const storedUserType = localStorage.getItem('userType');

  if (token && storedUserType) {
    setUserType(storedUserType);  // Update the userType in context
  } else {
    setUserType(null);  // Reset userType if no token is found
  }
}, [location, setUserType]); 

useEffect(() => {
const token = localStorage.getItem("token");
if (!token || checkTokenExpiration(token)) {
handleLogout();
} else {
const intervalId = setInterval(() => {
if (checkTokenExpiration(token)) handleLogout();
}, 120000);
return () => clearInterval(intervalId);
}
}, [handleLogout]);

useEffect(() => {
const token = localStorage.getItem("token");
if (!token) return;

const fetchUserDetails = async () => {
let userId;
try {
const decoded = jwtDecode(token);
userId = decoded.userId;
} catch (error) {
console.error("Failed to decode the token:", error);
return;
}

try {
const response = await axios.get(
`${process.env.REACT_APP_SERVER_BASE_URL}/user/profile?userId=${userId}`,
{ headers: { Authorization: `Bearer ${token}` } }
);
setUserDetails(response.data);
} catch (error) {
console.error(error);
}
};
fetchUserDetails();
}, [userType]);

const getAuthButtons = () => {
  if (!isOnAdminSubdomain) {
    return (
      <>
        <Button
          variant="secondary"
          to="/login"
          label="Sign In"
          type="button"
          className={`signin ${isCurrentRoute('/login') ? 'disabled' : ''}`}
        />
        <Button
          variant="primary"
          to="/register"
          label="Register"
          type="button"
          className={`signup ${isCurrentRoute('/register') ? 'disabled' : ''}`}
        />
      </>
    );
  } else {
    return (
      <Button
        variant="secondary"
        to="/login"
        label="Sign In"
        type="button"
        className={`signin ${isCurrentRoute('/login') ? 'disabled' : ''}`}
      />
    );
  }
};

const getMobileAuthButtons = () => {
  if (!isOnAdminSubdomain) {
    return (
      <>
        <Button
          variant={menuOpen ? "secondary-navbar-black" : "secondary-navbar-white"}
          size={menuOpen ? "large" : "medium"}
          to="/login"
          handleClick={closeMenu}
          label="Login"
          type="button"
          className={`signin ${isCurrentRoute('/login') ? 'disabled' : ''}`}
        />
        <Button
          variant="primary"
          to="/register"
          handleClick={closeMenu}
          label="Register"
          size={menuOpen ? "large" : "medium"}
          type="button"
          className={`register ${isCurrentRoute('/register') ? 'disabled' : ''}`}
        />
      </>
    );
  } else {
    return (
      <Button
        variant={menuOpen ? "secondary-navbar-black" : "secondary-navbar-white"}
        size={menuOpen ? "large" : "medium"}
        to="/login"
        handleClick={closeMenu}
        label="Login"
        type="button"
        className={`signin ${isCurrentRoute('/login') ? 'disabled' : ''}`}
      />
    );
  }
};

const getNavbar = (userState) => {
switch (userState) {
case "user":
case "admin":
return (
<>
<div className="navbar-buttons">
{
isCurrentRoute('/') ? 
<div className="navbar-brand-user-admin logo navbar-brand-link home" style={{ color: 'green' }}>
Rebrid
</div> : 
<Link to="/">
<div className="navbar-brand-user-admin logo navbar-brand-link">
Rebrid
</div>
</Link>
}


<div className={`navbar-nav`}>
{userState === "user" ? (
<>
{isCurrentRoute('/discover') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/discover" label="Discover" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/discover" label="Discover" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/location') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/location" label="Location" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/location" label="Location" type="button" className="nav-link" size="small" />
}

{isCurrentRoute('/myBucketlist') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/myBucketlist" label="My Bucketlist" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/myBucketlist" label="My Bucketlist" type="button" className="nav-link" size="small" />
}
<Button
variant="tertiary"
to="/planner"
label="Planner"
type="button"
className= {`nav-link planner ${isCurrentRoute('/planner') ? 'disabled' : ''}`}
size="small"
/>
</>
) : (
<>
<div className="admin-buttons">
{isCurrentRoute('/stats') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/stats" label="Stats" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/stats" label="Stats" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/manageActivities') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/manageActivities" label="Manage Activities" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/manageActivities" label="Manage Activities" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/managePromotions') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/managePromotions" label="Manage Promotions" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/managePromotions" label="Manage Promotions" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/manageBlogs') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/manageBlogs" label="Manage Blogs" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/manageBlogs" label="Manage Blogs" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/manageAdmin') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/manageAdmin" label="Manage Admin" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/manageAdmin" label="Manage Admin" type="button" className="nav-link" size="small" />
}
</div>
</>
)}
</div>
</div>
{userState === "admin" && (
<div className="admin-logout-button">
<SearchBar variant="search-nav" />
<Button
to="/"
variant="danger"
handleClick={handleLogout}
label="Logout"
type="button"
className="logout-btn admin-logout-button"
/>
</div>
)}
{userType === "user" && (
<div className="search-menu-profile">
{!shouldHideSearchBar && <SearchBar variant="search-nav" />}

<div
className={`menu-btn ${
userType === "user" ? "hide-on-large" : ""
}`}
onClick={toggleMenu}
ref={menuButtonRef}
>
<img
src={menuOpen ? MenuFill : MenuOutline}
alt="menu icon"
style={{ cursor: "pointer" }}
/>
</div>

<div
className="profile-icon-container hide-on-small"
onClick={toggleMenu}
ref={profilePhotoRef}
>
{getProfileIcon()}
</div>
</div>
)}

<div
className={`mobile-menu ${menuOpen ? "show" : ""}`}
ref={menuRef}
>
<div className="navbar-nav">
{userState === "user" ? (
<>
<div
className="profile-icon-container-mobile"
style={{ backgroundColor: getRandomColor() }}
>
{userDetails?.profilePhoto ? (
<img
src={userDetails.profilePhoto}
alt="Profile Icon"
className="profile-icon"
/>
) : (
<span className="profile-initials">
{getInitials()}
</span>
)}
</div>
<div className="navlink-hide">

{isCurrentRoute('/discover') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/discover" label="Discover" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/discover" label="Discover" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/location') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/location" label="Location" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/location" label="Location" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/myBucketlist') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/myBucketlist" label="My Bucketlist" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/myBucketlist" label="My Bucketlist" type="button" className="nav-link" size="small" />
}
<Button
variant="tertiary"
to="/planner"
label="Planner"
type="button"
className={`nav-link planner ${isCurrentRoute('/planner') ? 'disabled' : ''}`}
/>
</div>
{isCurrentRoute('/userProfile') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/userProfile" label="My Profile" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/userProfile" label="My Profile" type="button" className="nav-link" size="small" />
}
<Button
variant="tertiary"
to="/"
label="Logout"
type="button"
className="mx-3"
handleClick={handleLogout}
size="small"
/>
</>
) : (
<>
<div className="admin-navbar">
{isCurrentRoute('/stats') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/stats" label="Stats" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/stats" label="Stats" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/manageActivities') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/manageActivities" label="Manage Activities" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/manageActivities" label="Manage Activities" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/managePromotions') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/managePromotions" label="Manage Promotions" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/managePromotions" label="Manage Promotions" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/manageBlogs') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/manageBlogs" label="Manage Blogs" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/manageBlogs" label="Manage Blogs" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/manageAdmin') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/manageAdmin" label="Manage Admin" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/manageAdmin" label="Manage Admin" type="button" className="nav-link" size="small" />
}
<Button
variant="danger"
to="/"
label="Logout"
type="button"
className="nav-links-large logout"
handleClick={handleLogout}
size={menuOpen ? "large" : "small"}
/>
</div>
</>
)}
</div>
</div>
</>
);

default:
return (
<>
<div className="navbar-buttons">
<Link to="/">
<div className="navbar-brand-user logo">Rebrid</div>
</Link>
<div className={`navbar-nav ${menuOpen ? "hidden" : ""}`}>
{isCurrentRoute('/discover') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/discover" label="Discover" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/discover" label="Discover" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/location') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/location" label="Location" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/location" label="Location" type="button" className="nav-link" size="small" />
}
{isCurrentRoute('/myBucketlist') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/myBucketlist" label="My Bucketlist" type="button" size="small" /> </div> :
<Button variant="tertiary" to="/myBucketlist" label="My Bucketlist" type="button" className="nav-link" size="small" />
}
{/* Need to update to above format when live */}

<Button variant="tertiary" to="/planner" label="Planner" type="button" className= {`nav-link planner ${isCurrentRoute('/planner') ? 'disabled' : ''}`} size="small"/>
</div>
</div>

<div className={`auth-buttons ${menuOpen ? "hidden" : ""}`}>
{!shouldHideSearchBar && <SearchBar variant="search-nav" />}
{getAuthButtons()}
</div>
<div className="menu-search">
{!shouldHideSearchBar && <SearchBar variant="search-nav" />}
<div
className="menu-btn"
onClick={toggleMenu}
ref={guestMenuButtonRef}
>
<img
src={menuOpen ? MenuFill : MenuOutline}
alt="menu icon"
style={{ cursor: "pointer" }}
/>
</div>
</div>
<div
className={`mobile-menu ${menuOpen ? "show" : ""}`}
ref={menuRef}
>
<div className="navbar-nav">
{isCurrentRoute('/discover') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/discover" label="Discover" type="button" size="small" handleClick={closeMenu}/> </div> :
<Button variant="tertiary" to="/discover" label="Discover" type="button" className="nav-link" size="small" handleClick={closeMenu} />
}
{isCurrentRoute('/location') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/location" label="Location" type="button" size="small" handleClick={closeMenu}/> </div> :
<Button variant="tertiary" to="/location" label="Location" type="button" className="nav-link" size="small" handleClick={closeMenu}/>
}
{/* {isCurrentRoute('/category') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/category" label="Category" type="button" size="small" handleClick={closeMenu}/> </div> :
<Button variant="tertiary" to="/category" label="Category" type="button" className="nav-link" size="small" handleClick={closeMenu}/>
} */}
{isCurrentRoute('/myBucketList') ? 
<div className="nav-link inactive"><Button variant="tertiary" to="/myBucketlist" label="My Bucketlist" type="button" size="small" handleClick={closeMenu}/> </div> :
<Button variant="tertiary" to="/myBucketList" label="My Bucketlist" type="button" className="nav-link" size="small" handleClick={closeMenu} />
}

<Button variant="tertiary" to="/planner" label="Planner" type="button" handleClick={closeMenu} className= {`nav-link planner ${isCurrentRoute('/planner') ? 'disabled' : ''}`} size="small"/>
</div>
<div className="auth-buttons">
{getMobileAuthButtons()}
</div>
</div>
</>
);
}
};

return <nav className="navbar-container">{getNavbar(userType)}</nav>;
};

export default Navbar;
