import React from 'react';
import './LoadingOverlay.css'; // Ensure to create this CSS file

const LoadingOverlay = ({ isVisible, statusMessage }) => {
  if (!isVisible) return null;

  return (
    <div className="loading-overlay">
      <div className="loading-modal">
        <h2>{statusMessage.title}</h2>
        <h5>Live Status</h5>
        <p>{statusMessage.detail}</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;
