import React from 'react';
import AdminWrapper from '../components/adminwrapper/AdminWrapper';
import UserCount from '../components/widgets/UserCount';
import ViewCount from '../components/widgets/ViewCount';
import KpiTracker from '../components/widgets/KpiTracker';
import TopCountries from '../components/widgets/TopCountries';
import TopCities from '../components/widgets/TopCities';
import './AdminDashboard.css';

const AdminDashboard = () => {

  return (
    <AdminWrapper>
    <div className='dashboard-container'>
      <h1>Welcome, Admin!</h1>
      <div>
        <UserCount/>
        <ViewCount/>
        <KpiTracker/>
        <TopCountries/>
        <TopCities/>
      </div>
    </div>
     </AdminWrapper>
  );
};

export default AdminDashboard;
