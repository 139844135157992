import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Button.css';
import LikeOutline from '../../assets/icons/like-outline.svg';
import LikeFill from '../../assets/icons/like-fill.svg';
import MenuOutline from '../../assets/icons/menu-outline.svg';
import MenuFill from '../../assets/icons/menu-fill.svg';
import submitIcon from '../../assets/icons/submitIcon.svg';

const Button = ({ label, type, variant, handleClick, disabled, withIcon, icon, size, to, animation, menuOpen }) => {
  const [filled, setFilled] = useState(false);

  const toggleFill = () => {
    if (variant === "like" || variant === "menu") {
      setFilled(!filled);
    }
  };

  const handleButtonClick = (e) => {
    if (variant === "like" || variant === "menu") {
      toggleFill();
    }
    if (handleClick) handleClick(e);
  };

  const isDisabled = variant === "disabled" || disabled;
  const isSubmitting = variant === "submit";
  const buttonClass = `btn btn-${variant} btn-${size} text-p ${variant === "cta" ? `btn-cta-${size}` : ''} ${animation} ${isDisabled ? 'disabled' : ''} ${variant === 'tertiary' ? 'btn-tertiary' : ''} ${variant === 'secondary-navbar-white' && menuOpen ? 'secondary-navbar-black' : ''} ${variant === 'remove' ? 'btn-remove' : ''}`;
  
  const getIcon = () => {
    if (variant === "like") {
      return filled ? LikeFill : LikeOutline;
    } else if (variant === "menu") {
      return filled ? MenuFill : MenuOutline;
    } else {
      return icon;
    }
  };

  const buttonContent = (
    <>
      {(withIcon || variant === "like" || variant === "menu") && (
        <span className="btn-icon-text">
          <img src={getIcon()} alt="button icon" className="icon" />
          {label}
        </span>
      )}
      {!(withIcon || variant === "like" || variant === "menu") && label}
      {isSubmitting && <img src={submitIcon} alt="Submitting" className="submit-icon" />}
    </>
  );

  const button = (
    <button 
      type={type} 
      className={buttonClass} 
      onClick={!isDisabled ? handleButtonClick : undefined} 
      disabled={isDisabled}
    >
      {buttonContent}
    </button>
  );

  return to ? <Link to={to} className="btn-link">{button}</Link> : button;
};

export default Button;
