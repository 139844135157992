// SearchModal.jsx
import React, { useEffect, useRef } from 'react';
import './SearchModal.css';
import InputBox from '../InputBox/InputBox';
import Button from '../button/Button';
import CloseIcon from '../../assets/icons/close.svg';

const SearchModal = ({ isOpen, onClose, onSearch, searchValue, setSearchValue }) => {

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
          }
        };
    
        if (isOpen) {
          document.addEventListener("mousedown", handleClickOutside);
        }
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [isOpen, onClose]);


    const modalRef = useRef();
    const handleSubmit = (e) => {
        e.preventDefault();
        onSearch(searchValue);
      };

  if (!isOpen) return null;

  return (
    <div className="search-modal">
      <div className="search-modal-content"  ref={modalRef}>
        <img src={CloseIcon} alt="Close" className="close-icon" onClick={onClose} />
        <div className='search-modal-title'>
            <h1>Search</h1>
            <p>Search by any keyword, country,city or even a specific landmark to find related activities.</p>
        </div>
        <div className='form-search'>
        <form onSubmit={handleSubmit}>
          <InputBox
            type="text"
            helpText="Input Keyword or location"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            size='large'
            title='Search'
          />
          <Button
            type="submit"
            label="Search"
            variant="primary"
            size="medium"
          />
        </form>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
