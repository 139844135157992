import React from 'react';
import './HeroSection.css';
import SearchBar from '../searchBar/SearchBar';
import heroImage from '../../assets/images/nature.jpg';
import dashboardImage from '../../assets/images/mountain.jpg';

const HeroSection = ({ variant }) => {
    const heroSectionClass = `hero-section ${variant ? variant : ""}`;

    const renderContent = () => {
        if (variant === 'userDashboard') {
            return (
                <div className='hero-content'>
                    <div className="hero-text-section">
                        <div className="hero-text-content">
                            <h1>Browse Through Activities</h1>
                            <h4>Explore a collection of activities that interest you and add them to your bucketlist to keep track.</h4>
                            <SearchBar variant='hero'/>
                        </div>
                    </div>
                    <div className="hero-image-section">
                        <img src={dashboardImage} alt="User Dashboard" className="hero-image" />
                    </div>
                </div>
            );
        } else {
            return (
                <div className='hero-content'>
                    <div className="hero-text-section">
                        <div className="hero-text-content">
                            <h1>Your Personalized Leisure Activity Planner</h1>
                            <h4>Create your bucketlist for your next trip.</h4>
                            <SearchBar variant='hero'/>
                        </div>
                    </div>
                    <div className="hero-image-section">
                        <img src={heroImage} alt="Nature" className="hero-image" />
                    </div>
                </div>
            );
        }
    };

    return (
        <div className={heroSectionClass}>
            {renderContent()}
        </div>
    );
};

export default HeroSection;
