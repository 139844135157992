import React, { useState, useEffect } from 'react';
import axios from 'axios';

function TopCitiesWidget() {
const [cities, setCities] = useState([]);
const [duration, setDuration] = useState('today');

useEffect(() => {
async function fetchTopCities() {
try {
const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/topCities`, {
params: { duration }
});
setCities(response.data);
} catch (error) {
console.error("Failed to fetch top cities:", error);
}
}

fetchTopCities();
}, [duration]);

return (
<div>
<select value={duration} onChange={(e) => setDuration(e.target.value)}>
<option value="today">Today</option>
<option value="week">This Week</option>
<option value="month">This Month</option>
<option value="quarter">This Quarter</option>
<option value="half-year">This Half-Year</option>
<option value="this-year">This Year</option>
<option value="inception">Since Inception</option>
</select>

<ul>
{Array(10).fill(null).map((_, index) => {
const city = cities[index];
const cityName = city?._id === "undefined" ? "City unknown" : city?._id;
const count = city?.count || '-';
return (
<li key={index + 1}>
{index + 1}. {city ? `${cityName}: ${count}` : '-'}
</li>
);
})}
</ul>
</div>
);
}

export default TopCitiesWidget;
