import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './MyBucketlist.css';
import ActivityCard from '../components/activityCard/ActivityCard';
import Dropdown from '../components/dropdown/Dropdown';
import Button from '../components/button/Button';
import Pagination from '../components/pagination/Pagination';
import UserWrapper from '../components/userwrapper/UserWrapper';
import CategoryCapsules from '../components/categoryCapsules/CategoryCapsules';
import Accessibility from '../components/tags/accessibilityOptions.json';
import Country from '../components/tags/country.json';
import IdealSeason from '../components/tags/seasonOptions.json';
import Mood from '../components/tags/moodOptions.json';
import AgeLimit from '../components/tags/ageLimitOptions.json';
import FitnessLevel from '../components/tags/fitnessLevelOptions.json';
import jwtDecode from 'jwt-decode';
import Category from '../components/tags/subCategory.json';
import FilterIcon from '../assets/icons/filter-black.svg';
import FilterIconActive from '../assets/icons/filter-active.svg';

const MyBucketlist = ({ userId }) => {
const navigate = useNavigate();
const [currentPage, setCurrentPage] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [loadingProgress, setLoadingProgress] = useState(0);
const itemsPerPage = 20;
const [totalItems, setTotalItems] = useState(0);
const [activities, setActivities] = useState([]);
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const [showFilters, setShowFilters] = useState(false);
const [filters, setFilters] = useState({
accessibility: null,
country: null,
season: null,
mood: null,
ageLimit: null,
fitnessLevel: null,
activityType: null,
activityOrPlace: null,
isActivityFree: null, 
});

const toggleFilters = () => {
setShowFilters((prevShowFilters) => !prevShowFilters);
};

useEffect(() => {
const handleResize = () => {
setWindowWidth(window.innerWidth);
};

window.addEventListener("resize", handleResize);
return () => window.removeEventListener("resize", handleResize);
}, []);

useEffect(() => {
const token = localStorage.getItem('token');
if (!token) {
navigate('/login');
return;
}

const decodedToken = jwtDecode(token);
if (decodedToken.exp < Date.now() / 1000) {
navigate('/login');
return;
}
}, [navigate]);

const fetchMyBucketlistedActivities = useCallback(async () => {
  setIsLoading(true);
  let interval = setInterval(() => {
      setLoadingProgress(prevProgress => Math.min(prevProgress + Math.floor(Math.random() * 10), 95));
  }, 500);
try {
const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/user/${userId}/myBucketlisted-activities`, {
headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
params: { limit: itemsPerPage, skip: itemsPerPage * currentPage, ...filters }
});

setActivities(response.data.activities);
setTotalItems(response.data.totalItems);
} catch (error) {
  console.error('Error fetching myBucketlisted activities:', error);
} finally {
  clearInterval(interval);
  setLoadingProgress(100);
  setIsLoading(false);
}
}, [userId, currentPage, filters]);

useEffect(() => {
fetchMyBucketlistedActivities();
}, [fetchMyBucketlistedActivities]);

const handleFilterChange = useCallback((name, selectedOption) => {
setFilters(prev => ({
...prev,
[name]: selectedOption ? selectedOption.value : null
}));
setCurrentPage(0);
}, []);

const NoActivitiesMessage = () => (
    <div className="no-activities-message">
      <p>No activities retrieved. Please add activities to your bucketlist for easy access and tracking of future interests.</p>
    </div>
  );

  const LoadingAnimation = () => {
    return (
        <div className="loading-container">
            <div className="loading-line-bg"></div>
            <div className="loading-line" style={{ width: `${loadingProgress}%` }}></div>
            <p className="loading-text">Loading activities... {loadingProgress}%</p>
        </div>
    );
};


const renderFilterDropdowns = () => {
return (
<>
<Dropdown
size="small"
title="Sub-Category"
options={Category}
value={filters.subCategory !== null ? { value: filters.subCategory, label: Category.find(option => option.value === filters.subCategory)?.label } : null}
onChange={(option) => handleFilterChange("subCategory", option)}
/>
<Dropdown
                size="small"
                title="Activities/Places"
                options={[{ value: 'activity', label: 'Activity' }, { value: 'place', label: 'Place' }]}
                value={filters.activityOrPlace ? 
                    { value: filters.activityOrPlace, label: filters.activityOrPlace.charAt(0).toUpperCase() + filters.activityOrPlace.slice(1) } : null}
                onChange={(option) => handleFilterChange("activityOrPlace", option)}
            />
            <Dropdown
                size="small"
                title="Free or Paid"
                options={[{ value: 'true', label: 'Free' }, { value: 'false', label: 'Paid' }]}
                value={filters.isActivityFree !== null && filters.isActivityFree !== undefined ? 
                    { value: filters.isActivityFree.toString(), label: filters.isActivityFree ? 'Free' : 'Paid' } : null}
                onChange={(option) => handleFilterChange("isActivityFree", option)}
            />
<Dropdown
size="small"
title="Accessibility"
options={Accessibility}
value={filters.accessibility !== null ? { value: filters.accessibility, label: Accessibility.find(option => option.value === filters.accessibility)?.label } : null}
onChange={(option) => handleFilterChange("accessibility", option)}
/>
<Dropdown
size="small"
title="Country"
options={Country}
value={filters.country !== null ? { value: filters.country, label: Country.find(option => option.value === filters.country)?.label } : null}
onChange={(option) => handleFilterChange("country", option)}
/>
<Dropdown
size="small"
title="Season"
options={IdealSeason}
value={filters.idealSeason !== null ? { value: filters.idealSeason, label: IdealSeason.find(option => option.value === filters.idealSeason)?.label } : null}
onChange={(option) => handleFilterChange("idealSeason", option)}
/>
<Dropdown
size="small"
title="Mood"
options={Mood}
value={filters.mood !== null ? { value: filters.mood, label: Mood.find(option => option.value === filters.mood)?.label } : null}
onChange={(option) => handleFilterChange("mood", option)}
/>
<Dropdown
size="small"
title="Age Limit"
options={AgeLimit}
value={filters.ageLimit !== null ? { value: filters.ageLimit, label: AgeLimit.find(option => option.value === filters.ageLimit)?.label } : null}
onChange={(option) => handleFilterChange("ageLimit", option)}
/>
<Dropdown
size="small"
title="Fitness Level"
options={FitnessLevel}
value={filters.fitnessLevel !== null ? { value: filters.fitnessLevel, label: FitnessLevel.find(option => option.value === filters.fitnessLevel)?.label } : null}
onChange={(option) => handleFilterChange("fitnessLevel", option)}
/>
</>
);
};

return (
<UserWrapper>
<div className="myBucketlist">
<Helmet>
<link rel="canonical" href="https://www.rebrid.co/myBucketlist" />
</Helmet>
<div className="bucketlist-title">
<h1>My Bucketlist</h1>
<div className="category-line">
<CategoryCapsules onCategorySelect={(selectedCategory) => handleFilterChange('activityType', selectedCategory)} />
<Button label={windowWidth < 500 ? '' : 'Filter'} variant={showFilters ? 'active-filter' : 'tertiary'} handleClick={toggleFilters} icon={showFilters ? FilterIconActive : FilterIcon} size="small" withIcon className={showFilters ? 'filter-button-active' : ''} />
</div>
<div className={`filters-container ${!showFilters ? "hidden-filters" : ""}`}>
{renderFilterDropdowns()}
</div>
<div className="activities-cards">
                {isLoading ? (
                    <LoadingAnimation />
                ) : activities.length > 0 ? (
            activities.map(activity => (
              <ActivityCard key={activity._id} activity={activity} />
            ))
          ) : (
            <NoActivitiesMessage />
          )}
        </div>

<div className="pagination">
<Pagination currentPage={currentPage} itemsPerPage={itemsPerPage} totalItems={totalItems} onPageChange={setCurrentPage} />
</div>
</div>
</div>
</UserWrapper>
);
};

export default MyBucketlist;
