import React, { useState, useRef ,useEffect, lazy, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./CountryCities.css";
import { Helmet } from 'react-helmet';
import Category from "../components/tags/subCategory.json";
import CategoryCapsules from "../components/categoryCapsules/CategoryCapsules";
import Dropdown from "../components/dropdown/Dropdown";
import Button from "../components/button/Button";
import FilterIcon from "../assets/icons/filter-black.svg";
import FilterIconActive from "../assets/icons/filter-active.svg";
import FeaturedPromotion from "../components/promotions/FeaturedPromotion";
import Accessibility from "../components/tags/accessibilityOptions.json";
import Mood from "../components/tags/moodOptions.json";
import FitnessLevel from "../components/tags/fitnessLevelOptions.json";

const ActivityCard = lazy(() =>
import("../components/activityCard/ActivityCard")
);

const CountryCities = () => {
const { countryName } = useParams();
const [activities, setActivities] = useState([]);
const [totalItems, setTotalItems] = useState(0);
const [isLoading, setIsLoading] = useState(true);
const [selectedCity, setSelectedCity] = useState(null);
const [loadingProgress, setLoadingProgress] = useState(0);
const [showFilters, setShowFilters] = useState(false);
const [isFullscreen, setIsFullscreen] = useState(false); // New state
const [filters, setFilters] = useState({
activityType: null,
accessibility: null,
season: null,
mood: null,
ageLimit: null,
fitnessLevel: null,
activityOrPlace: null,
isActivityFree: null,
});

const [originalActivities, setOriginalActivities] = useState([]); // New state for all activities

const [windowWidth, setWindowWidth] = useState(window.innerWidth);

useEffect(() => {
const handleResize = () => {
setWindowWidth(window.innerWidth);
};

window.addEventListener("resize", handleResize);
return () => window.removeEventListener("resize", handleResize);
}, []);

const fetchActivities = useCallback(async () => {
setIsLoading(true);
let interval = setInterval(() => {
setLoadingProgress((prevProgress) =>
Math.min(prevProgress + Math.floor(Math.random() * 10), 95)
);
}, 500);

try {
const params = {
...filters,
country: countryName,
activityOrPlace: filters.activityOrPlace,
isActivityFree: filters.isActivityFree,
};

const response = await axios.get(
`${process.env.REACT_APP_SERVER_BASE_URL}/activity`,
{ params }
);

setOriginalActivities(response.data.data);
setActivities(response.data.data);
setTotalItems(response.data.totalItems);
} catch (error) {
console.error("Error fetching activities:", error);
} finally {
clearInterval(interval);
setLoadingProgress(100);
setIsLoading(false);
}
}, [filters, countryName]);

useEffect(() => {
fetchActivities();
}, [fetchActivities]);


const handleFilterChange = (name, selectedOption) => {
  setFilters((prev) => ({
    ...prev,
    [name]: selectedOption ? selectedOption.value : null,
  }));
};

const toggleFilters = () => {
setShowFilters((prev) => !prev);
};

const renderFilterDropdowns = () => {
return (
<>
<Dropdown
size="medium"
title="Sub-Category"
options={Category}
value={
filters.subCategory !== null
? {
value: filters.subCategory,
label: Category.find(
(option) => option.value === filters.subCategory
)?.label,
}
: null
}
onChange={(option) => handleFilterChange("subCategory", option)}
/>
<Dropdown
        size="small"
        title="Activities/Places"
        options={[{ value: 'activity', label: 'Activity' }, { value: 'place', label: 'Place' }]}
        value={filters.activityOrPlace ? { value: filters.activityOrPlace, label: filters.activityOrPlace.charAt(0).toUpperCase() + filters.activityOrPlace.slice(1) } : null}
        onChange={(option) => handleFilterChange("activityOrPlace", option)}
      />
      <Dropdown
        size="small"
        title="Free or Paid"
        options={[{ value: 'true', label: 'Free' }, { value: 'false', label: 'Paid' }]}
        value={filters.isActivityFree !== null ? { value: filters.isActivityFree.toString(), label: filters.isActivityFree ? 'Free' : 'Paid' } : null}
        onChange={(option) => handleFilterChange("isActivityFree", option)}
      />
<Dropdown
size="small"
title="Mood"
options={Mood}
value={
filters.mood !== null
? {
value: filters.mood,
label: Mood.find((option) => option.value === filters.mood)
?.label,
}
: null
}
onChange={(option) => handleFilterChange("mood", option)}
/>
<Dropdown
size="medium"
title="Accessibility"
options={Accessibility}
value={
filters.accessibility !== null
? {
value: filters.accessibility,
label: Accessibility.find(
(option) => option.value === filters.accessibility
)?.label,
}
: null
}
onChange={(option) => handleFilterChange("accessibility", option)}
/>
{/* <Dropdown
size="small"
title="Season"
options={Season}
value={
filters.season !== null
? {
value: filters.season,
label: Season.find(
(option) => option.value === filters.season
)?.label,
}
: null
}
onChange={(option) => handleFilterChange("season", option)}
/> */}
{/* <Dropdown
size="small"
title="Age Limit"
options={AgeLimit}
value={
filters.ageLimit !== null
? {
value: filters.ageLimit,
label: AgeLimit.find(
(option) => option.value === filters.ageLimit
)?.label,
}
: null
}
onChange={(option) => handleFilterChange("ageLimit", option)}
/> */}
<Dropdown
size="small"
title="Fitness Level"
options={FitnessLevel}
value={
filters.fitnessLevel !== null
? {
value: filters.fitnessLevel,
label: FitnessLevel.find(
(option) => option.value === filters.fitnessLevel
)?.label,
}
: null
}
onChange={(option) => handleFilterChange("fitnessLevel", option)}
/>
</>
);
};

return (
<div className="country-cities-container">
<Helmet>
<link rel="canonical" href={`https://www.rebrid.co/location/${countryName}`} />
</Helmet>
<div className="country-city-heading">
<h1>All Activities in {countryName}</h1>
<p>Select a city, dive into vibrant leisure activities, and craft everlasting memories that will echo through time.</p>
</div>
<hr className="separator-line" />

<div className="category-line">
<CategoryCapsules onCategorySelect={(selectedCategory) => handleFilterChange('activityType', selectedCategory)} />
<Button 
label={windowWidth < 500 ? '' : 'Filter'} 
variant={showFilters ? 'active-filter' : 'tertiary'} 
handleClick={toggleFilters} 
icon={showFilters ? FilterIconActive : FilterIcon} 
size="small"
withIcon
className={showFilters ? 'filter-button-active' : ''}
/>
</div>

<div className={`filters-container ${!showFilters ? 'hidden-filters' : ''}`}>
    {renderFilterDropdowns()}
</div>

<div className="activities-wrapper">
{!isFullscreen && (
<div className="filtered-section">
{isLoading ? (
<div className="loading-container">
<div className="loading-line-bg"></div>
<div className="loading-line" style={{ width: `${loadingProgress}%` }}></div>
<p className="loading-text">Loading activities... {loadingProgress}%</p>
</div>

) : activities.length > 0 ? (
<div className="activity-cards-section-country">
<div className="filtered-cards">
{activities.filter(activity => !selectedCity || activity.city === selectedCity).map((activity, index) => (
<React.Fragment key={activity._id}>
<ActivityCard activity={activity} />
{(index + 1) % 8 === 0 && <FeaturedPromotion key={`promo-${index}`} />}
</React.Fragment>
))}
</div>
</div>
) : (
<div className="no-activities">
<p>No Activities Found</p>
</div>
)}
</div>
)}
</div>
</div>
);

};

export default CountryCities;
