import React, { useState, useEffect } from "react";
import InputBox from "../components/InputBox/InputBox";
import { Helmet } from 'react-helmet';
import PasswordBox from "../components/PasswordBox/PasswordBox";
import Button from "../components/button/Button";
import "./Register.css";
import axios from "axios";
import VisitorWrapper from "../components/visitorwrapper/VisitorWrapper";
import GoogleIcon from '../assets/icons/Google.svg';
import Modal from '../components/modal/modal';

const Register = () => {
const [formData, setFormData] = useState({ email: "", password: "", confirmPassword: "" });
const [passwordError, setPasswordError] = useState('');
const [isRegistering, setIsRegistering] = useState(false);
const [showModal, setShowModal] = useState(false);
const [modalMessage, setModalMessage] = useState('');
const [showSuccessModal, setShowSuccessModal] = useState(false);

const validatePassword = () => {
  const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,}$/;
  return regex.test(formData.password);
};


const checkPasswordsMatch = () => {
return formData.password === formData.confirmPassword;
};

const validateEmail = (email) => {
return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
};

useEffect(() => {
  const hostname = window.location.hostname;
  if (hostname.startsWith("admin.")) {
    window.location.href = "https://www.rebrid.co/register";
  }
}, []);

const isFormValid = () => {
return validatePassword() && checkPasswordsMatch() && validateEmail(formData.email);
};

const handleInputChange = (e, field) => {
setFormData({ ...formData, [field]: e.target.value });
};

const handleRegister = async (event) => {
event.preventDefault();
setIsRegistering(true);
setPasswordError('');

if (!validateEmail(formData.email)) {
setPasswordError('Please enter a valid email address.');
setIsRegistering(false);
return;
}

if (!validatePassword()) {
setPasswordError("Password must be at least 8 characters long and include both letters and numbers.");
setIsRegistering(false);
return;
}

if (!checkPasswordsMatch()) {
setPasswordError("Passwords do not match.");
setIsRegistering(false);
return;
}

const dataToSend = {
email: formData.email,
password: formData.password,
};


try {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_BASE_URL}/user/register`,
    dataToSend
  );

  // Delay before showing success modal
  setTimeout(() => {
    setShowSuccessModal(true);
    setIsRegistering(false);
  }, 3000); // 3 seconds delay

} catch (error) {
  console.error("Registration error:", error);
  setModalMessage("Registration failed: " + error.message);
  setTimeout(() => {
    setShowModal(true);
    setIsRegistering(false);
  }, 3000); // 3 seconds delay for consistency
}
};

const handleGoogleLogin = () => {
window.location.href = `${process.env.REACT_APP_SERVER_BASE_URL}/user/google`;
};

const modalActions = [
{
label: 'Close',
onClick: () => setShowModal(false),
variant: 'secondary',
}
];

useEffect(() => {
if (isFormValid()) {
setPasswordError('');
}
}, [formData, isFormValid]);

return (
<VisitorWrapper>
<Helmet>
<link rel="canonical" href="https://www.rebrid.co/register" />
</Helmet>
<div className="register-page">
<div className="register-form-container">
<h1>Register</h1>
<form onSubmit={handleRegister}>
<div className="email">
<InputBox
title="Email"
helpText="Enter your email"
type="email"
size="large"
value={formData.email}
onChange={(e) => handleInputChange(e, "email")}
/>
</div>

<PasswordBox
size="large"
onPasswordChange={(passwordValue) => handleInputChange({ target: { value: passwordValue } }, "password")}
onConfirmPasswordChange={(confirmPasswordValue) => handleInputChange({ target: { value: confirmPasswordValue } }, "confirmPassword")}
/>
<div className={`password-instructions ${passwordError ? 'error' : ''}`}>
<p>{passwordError || "Password must be at least 8 characters long and include both letters and numbers."}</p>
</div>
<div className="register-btn">
<Button 
              size='large' 
              label={isRegistering ? "Submitting" : "Register"} 
              type="submit" 
              variant={isRegistering ? "submit" : "primary"}
              disabled={isRegistering}
            />
</div>
</form>

<div className="quick-register-section">
<hr />
<div className="quick-register-text"><h4>Quick Register</h4>
<Button
type="submit"
withIcon={true}
icon={GoogleIcon}
variant="secondary"
label="Register with Google"
handleClick={handleGoogleLogin}
size="large"
className="google-btn"
/>
</div>
</div>
</div>
</div>
<Modal
        title="Registration Successful"
        message="A verification email has been sent, valid for 1 hour. Please click 'Verify' in the email to proceed to login."
        handleClose={() => setShowSuccessModal(false)}
        show={showSuccessModal}
        actions={[{ label: 'Close', onClick: () => setShowSuccessModal(false), variant: 'secondary' }]}
      />
      <Modal
        title="Registration Failed"
        message={modalMessage}
        handleClose={() => setShowModal(false)}
        show={showModal}
        actions={[{ label: 'Close', onClick: () => setShowModal(false), variant: 'secondary' }]}
      />
    </VisitorWrapper>
  );
};

export default Register;