import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';

const VisitorWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { userType } = useContext(UserContext);
  
  const token = localStorage.getItem('token');
  
  useEffect(() => {
    // If user is logged in as either 'admin' or 'user', navigate them to home
    if (token && (userType === 'admin' || userType === 'user')) {
      navigate('/');
    }
  }, [userType, navigate, token]);

  // Render children (Login, Register components) if user is not logged in
  return (!token || (userType !== 'admin' && userType !== 'user')) ? children : null;
};

export default VisitorWrapper;
