import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className='policy-container'>
      <h1>Privacy Policy</h1>

      <h3>1. Introduction</h3>
      <p>Rebrid Company, known to many through its domain rebrid.co, is a dedicated platform that bridges the gap between activity providers and seekers. At the very heart of our operations is the trust that users bestow upon us. At Rebrid ("we", "our", or "us"), your privacy is paramount. We are committed to safeguarding the personal data of our users ("you"). This Privacy Policy outlines how we collect, use, and protect your personal data. By using our website, https://www.rebrid.co, and our services, you consent to the practices described in this policy.
<br></br>
Please read this Privacy Policy carefully, and ensure that you understand our practices regarding your personal data. If you do not agree with our policies and practices, your choice is not to use our website. By accessing or using this website, you agree to this Privacy Policy.</p>

<h3>2. Information We Collect</h3>
<p>We collect several types of information from and about users of our website. Specifically, we track:

Personal Information: This includes your First Name, Last Name, and Email address. This information is crucial for creating your account and ensuring a personalized experience on our platform.

Location Data: Through your IP address, we collect your general location data. This helps us personalize your experience based on where you're accessing our site from, ensuring relevant content and services are presented to you.

User Activity Data: This encompasses activities you've added to your bucket list, reviews you post and like, and the pages you've visited on Rebrid. This data assists us in understanding your preferences, improving our content, and enhancing our services to better suit your needs.</p>

<h3>3. Age Restriction</h3>
<p>Rebrid is not intended for children under 18 years of age. We do not knowingly collect personal information from children under 18. If you are under 18, please do not use or provide any information on this website. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information.</p>

<h3>4. Use of Your Information</h3>
<p>We use the information we collect about you or that you provide to us:

Personalization: Your information helps us offer tailored content, especially the location data obtained from your IP, ensuring the relevance of the activities and content shown to you.

Account Management: Your First Name, Last Name, and Email are vital for setting up and managing your account.

Service Improvement: By understanding the pages you visit, the activities you show interest in, and the reviews you provide, we can enhance our services, ensuring our platform evolves in line with user preferences.</p>

<h3>5. Data Protection</h3>
<p>Your privacy is paramount. We never sell your data to any third parties, including partners or investors. All data is stored with the utmost care, using advanced security measures. As our platform evolves, we continually upgrade our security systems, reinforcing our commitment to safeguarding your data.casual chat about data - we're always here. Reach out at hello@rebrid.co. For us, every user isn’t just a statistic; they're stakeholders who've chosen to journey with us.</p>
<h3>6. Data Retention and Access</h3>
<p>Duration of Storage: Your personal data is retained only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.

Your Access Rights: As a user, you have the right to access, correct, or delete your personal data. You can review and change your personal information by logging into the website and visiting your account profile page.

Deletion: If you wish to have your data deleted from our servers, please contact us directly. Note that some information might remain in backup copies and logs for a certain period due to technical constraints.</p>

<h3>7. Third-Party Services</h3>
<p>From time to time, we may integrate third-party services to enhance the functionalities of Rebrid. While we ensure that these third parties maintain privacy standards in line with ours, we strongly recommend that users review the privacy policies of these third-party services before granting them access.</p>

<h3>8. Cookies and Other Tracking Technologies</h3>
<p>We utilize cookies and other tracking technologies to analyze website traffic and understand where our audience is coming from. While we primarily use this data to improve your user experience, it's essential to understand that:

Cookies: Small files transferred to your device that help us recognize your device, store your preferences, and overall enhance your browsing experience.

Opt-Out: You have the option to decline cookies. By modifying your browser settings, you can reject cookies or be prompted before accepting one. Note, however, that refusing cookies might affect the usability of the website.</p>

<h3>9. Data Security</h3>
<p>The security of your personal data is of paramount importance to us. We have implemented measures designed to secure your personal information from accidental loss and unauthorized access, alteration, or disclosure. Despite our efforts, please acknowledge that no security system is impenetrable, and we cannot guarantee the absolute security of your data.</p>

<h3>10. International Data Transfers</h3>
<p>Given the global nature of the Internet, your data might be transferred to — and maintained on — computers located outside of your country or other governmental jurisdiction. Rest assured, we take steps to ensure that your privacy rights are respected, even in such cases.</p>

<h3>11. Changes to Our Privacy Policy</h3>
<p>It is our policy to post any changes we make to our Privacy Policy on this page. If we make material changes regarding how we treat our users' personal information, we will notify you through a notice on the website's home page. We encourage users to periodically review this page for the latest information on our privacy practices.</p>

<h3>12. Contact Information</h3>
<p>For any questions, concerns, or comments about this Privacy Policy or our privacy practices, please contact: <a href='/contactus'>hello@rebrid.co </a> 
</p>  

    </div>
  );
}

export default PrivacyPolicy;
