import React, { useEffect, useRef, useState } from 'react';
import attachment from '../../assets/icons/attachment.svg';
import close from '../../assets/icons/close.svg'; 
import './UploadBox.css';

const UploadBox = ({ size = {}, label = 'Select Image', onChange, initialImage = null }) => {
const fileInputRef = useRef(null);
const [selectedFile, setSelectedFile] = useState(null);
const [isDragging, setIsDragging] = useState(false);

const openFileDialog = () => {
if (fileInputRef.current) {
fileInputRef.current.click();
}
};

UploadBox.defaultProps = {
onChange: () => {}, // default to an empty function
};

const truncateString = (str, num) => {
if (str.length <= num) {
return str;
}
return str.slice(0, num) + '...';
};

const getDisplayText = () => {
if (typeof selectedFile === 'string') {
return truncateString(selectedFile, 30);
}
return selectedFile ? getFileName() : 'Attach a file';
};

const onFileChange = event => {
setSelectedFile(event.target.files[0]);
onChange(event); // pass event to the passed down function
};

const removeSelectedFile = () => {
setSelectedFile(null);
fileInputRef.current.value = null;
};

const formatFileName = (name, limit) => {
const nameParts = name.split('.');
const extension = nameParts.pop();
let baseName = nameParts.join('.');

if (baseName.length > limit) {
baseName = `${baseName.substring(0, limit)}..`;
}

return `${baseName}.${extension}`;
};

useEffect(() => {
setSelectedFile(initialImage);
}, [initialImage]);

const getFileName = () => {
if (!selectedFile) {
return null;
}

let limit;
switch (size) {
case 'small':
limit = 7;
break;
case 'medium':
limit = 15;
break;
case 'large':
limit = 30;
break;
default:
limit = 7;
}

return formatFileName(selectedFile.name, limit);
};

const onFileDrop = (event) => {
event.preventDefault();
setIsDragging(false);
if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
setSelectedFile(event.dataTransfer.items[0].getAsFile());
onChange(event); // pass event to the passed down function
event.dataTransfer.clearData();
}
};

const onDragOver = (event) => {
event.preventDefault();
setIsDragging(true);
};

const onDragLeave = (event) => {
event.preventDefault();
setIsDragging(false);
};

return (
<div className={`upload-box ${size}`}>
<label className="upload-box-label">{label}</label>
<div 
className={`upload-box-container ${isDragging ? 'dragging' : ''}`}
onClick={!selectedFile ? openFileDialog : null}
onDrop={onFileDrop}
onDragOver={onDragOver}
onDragLeave={onDragLeave}
>
<input 
id="file-upload"
ref={fileInputRef}
type="file"
className="file-upload-input"
accept="image/*"
onChange={onFileChange}
/>
<div className="upload-box-drag-drop">
<img src={attachment} alt="Attachment Icon" className="attachment-icon" />
{selectedFile ? (
<div className="upload-box-file-tab">
<span className='filename'>{getDisplayText()}</span>
<img className="close-icon" src={close} alt="Close Icon" onClick={removeSelectedFile} />
</div>
) : (
<span className='help-text'>Attach a file</span>
)}
</div>
</div>
</div>
);
};

export default UploadBox;
