import React, { useState } from 'react';
import InputContainer from '../inputContainer/InputContainer';
import './PasswordBox.css';
import eyeOpen from '../../assets/icons/eye-open.svg';
import eyeClose from '../../assets/icons/eye-close.svg';

const PasswordBox = ({ size = 'large', loginBox = false, onPasswordChange, onConfirmPasswordChange }) => {
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev);
    };

    const toggleConfirmedPasswordVisibility = () => {
        setShowConfirmedPassword(prev => !prev);
    };

    const handlePasswordChange = (e) => {
        const newValue = e.target.value;
        setPassword(newValue);
        if (onPasswordChange) {
            onPasswordChange(newValue);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const newValue = e.target.value;
        setConfirmedPassword(newValue);
        if (onConfirmPasswordChange) {
            onConfirmPasswordChange(newValue);
        }
    };

    return (
        <div className={`password-box-set ${size}`}>
            <div className="password-fields">
                <InputContainer title={loginBox ? "Password" : "Set Password"} size={size}>
                    <input
                        type={showPassword ? "text" : "password"}
                        className="form-control password-input"
                        placeholder="Enter Password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <img
                        src={showPassword ? eyeClose : eyeOpen}
                        alt="Toggle visibility"
                        className="eye-icon"
                        onClick={togglePasswordVisibility}
                    />
                </InputContainer>
                {!loginBox && (
                    <InputContainer title="Retype Password" size={size}>
                        <input
                            type={showConfirmedPassword ? "text" : "password"}
                            className="form-control password-input"
                            placeholder="Confirm Password"
                            value={confirmedPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        <img
                            src={showConfirmedPassword ? eyeClose : eyeOpen}
                            alt="Toggle visibility"
                            className="eye-icon"
                            onClick={toggleConfirmedPasswordVisibility}
                        />
                    </InputContainer>
                )}
            </div>
        </div>
    );
};

export default PasswordBox;
