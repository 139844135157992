import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import InputBox from '../components/InputBox/InputBox';
import Dropdown from '../components/dropdown/Dropdown';
import Button from '../components/button/Button';
import { useNavigate } from 'react-router-dom'; 
import './Planner.css';
import UserWrapper from '../components/userwrapper/UserWrapper';

const Planner = () => {
    const [latestPlans, setLatestPlans] = useState([]);
    const [createdPlans, setCreatedPlans] = useState([]);
    const [sharedPlans, setSharedPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showInput, setShowInput] = useState(false);
    const [newPlanName, setNewPlanName] = useState('');
    const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    const navigate = useNavigate(); 
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const decoded = token ? jwt_decode(token) : null;
    const [searchQuery, setSearchQuery] = useState('');
    const userId = decoded ? decoded.userId : null;
    const [sharedUserPhotos, setSharedUserPhotos] = useState({});
    const [sortOrderLatest, setSortOrderLatest] = useState('latest');
    const [sortOrderCreated, setSortOrderCreated] = useState('latest');
    const [sortOrderShared, setSortOrderShared] = useState('latest');
    const sortingOptions = [
        { value: 'latest', label: 'Latest to Oldest' },
        { value: 'a-z', label: 'A to Z' },
        { value: 'z-a', label: 'Z to A' }
    ];

    useEffect(() => {
        if (!userId) {
            console.error('No user ID found');
            return;
        }
        fetchPlans();
    }, [userId]);

    useEffect(() => {
        if (!isLoading) {
            document.querySelector('.planner').style.display = 'block'; // Show planner content after loading
        }
    }, [isLoading]);

    const fetchPlans = async () => {
        setIsLoading(true);
    
        try {
            // Fetch plan data
            const latestResponse = await axios.get(`${serverBaseUrl}/api/latest-plans/${userId}`);
            const createdResponse = await axios.get(`${serverBaseUrl}/api/users/${userId}/plans`);
            const sharedResponse = await axios.get(`${serverBaseUrl}/api/shared-plans/${userId}`);
    
            // Fetch shared user data
            const sharedUserEmails = new Set(); // Use a Set to avoid duplicate requests
            [...latestResponse.data, ...createdResponse.data, ...sharedResponse.data].forEach(plan => {
                plan.sharingWith.forEach(shared => sharedUserEmails.add(shared.email));
            });
    
            const sharedUsersData = await Promise.all(Array.from(sharedUserEmails).map(email => fetchUserDataByEmail(email)));
    
            // Map email to user data for easy access
            const sharedUserDataMap = sharedUsersData.reduce((acc, user) => {
                acc[user.email] = user;
                return acc;
            }, {});
    
            setLatestPlans(latestResponse.data);
            setCreatedPlans(createdResponse.data.filter(plan => plan.primaryUser === userId));
            setSharedPlans(sharedResponse.data.filter(plan => plan.primaryUser !== userId));
            setSharedUserPhotos(sharedUserDataMap);
        } catch (error) {
            console.error('Error fetching plans:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const fetchUserDataByEmail = async (email) => {
        try {
            const response = await axios.get(`${serverBaseUrl}/userByEmail?email=${email}`);
            return { ...response.data, email }; // Include email in the returned object
        } catch (error) {
            console.error('Error fetching user data:', error);
            return { email }; // Return an object with only the email in case of an error
        }
    };
    

    const handleCreatePlan = () => {
        setShowInput(true);
    };

    const handleSavePlan = async () => {
        if (!newPlanName.trim()) return;
        try {
            const newPlan = await axios.post(`${serverBaseUrl}/api/plans`, { 
                primaryUser: userId, 
                name: newPlanName,
                lastUpdated: new Date()
            });

            setLatestPlans([newPlan.data, ...latestPlans]);
            setCreatedPlans([newPlan.data, ...createdPlans]);

            setShowInput(false);
            setNewPlanName('');
        } catch (error) {
            console.error('Error creating plan:', error);
        }
    };

    const renderPlanCreation = () => {
        return showInput ? (
            <div className='render-plan-creation'>
                <InputBox 
                    className="size-medium" 
                    title="Plan Name" 
                    initialValue={newPlanName}
                    onChange={(e) => setNewPlanName(e.target.value)}
                />
                <Button label="Save" variant="primary" handleClick={handleSavePlan} size="small" />
            </div>
        ) : (
            <Button label="Create New Plan" variant="primary" handleClick={handleCreatePlan} size="small" />
        );
    };

    const renderLoadingAnimation = () => (
        <div className="loading-animation">
            <span>Loading Planner...</span>
        </div>
    );

    const renderPlans = (plans, sortOrder) => {
        const sortedPlans = sortPlans(plans, sortOrder);
        const filteredPlans = filterPlansBySearchQuery(sortedPlans);

        return (
            <div className="plan-cards-container">
                {filteredPlans.length > 0 ? filteredPlans.map(plan => (
                    <div className="plan-card" key={plan._id} onClick={() => handlePlanClick(plan.slug)}>
                        <div className="plan-card-image-container">
                            {plan.planImage && (
                                <>
                                    <img src={plan.planImage} alt={plan.name} />
                                    <div className="plan-name-overlay-top">{plan.name}</div>
                                    <div className="plan-shared-users-overlay"></div>
                                </>
                            )}
                            {!plan.planImage && (
                                <div className="plan-card-no-image">{plan.name}</div>
                            )}
                        </div>
                        {plan.planImage && (
                            <div className="plan-shared-users">
                                {plan.sharingWith.slice(0, 3).map(shared => {
                                    const userData = sharedUserPhotos[shared.email] || {};
                                    const tooltipText = userData.userName || 
                                    (userData.firstName || userData.lastName ? `${userData.firstName} ${userData.lastName}`.trim() : shared.email);
                                    const initials = userData.firstName ? userData.firstName[0] : shared.email[0];
                                    return userData.profilePhoto ? (
                                        <div className="shared-user-photo" title={tooltipText}>
                                            <img src={userData.profilePhoto} alt={tooltipText} />
                                        </div>
                                    ) : (
                                        <div className="shared-user-no-photo" title={tooltipText}>{initials.toUpperCase()}</div>
                                    );
                                })}
                                {plan.sharingWith.length > 3 && (
                                    <div className="more-shared-users" title={`+${plan.sharingWith.length - 3} more`}>+{plan.sharingWith.length - 3}</div>
                                )}
                            </div>

                        )}
                    </div>
                )) : <div className="no-plans">No plans found</div>}
            </div>
        );
    };
    
    

    const handlePlanClick = (slug) => {
        navigate(`/planner/${slug}`);
    };

    const sortPlans = (plans, sortOrder) => {
        switch (sortOrder) {
            case 'a-z':
                return [...plans].sort((a, b) => a.name.localeCompare(b.name));
            case 'z-a':
                return [...plans].sort((a, b) => b.name.localeCompare(a.name));
            default:
                return plans;
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };
    

    const filterPlansBySearchQuery = (plans) => {
        return plans.filter(plan => plan.name.toLowerCase().includes(searchQuery));
    };

    const renderSectionHeading = (title, sortOrder, setSortOrder, searchPlaceholder) => (
        <div className="section-heading">
            <h2>{title}</h2>
            <div className="search-sort-container">
                <InputBox 
                    className="search-box" 
                    label="Search Plan Name" 
                    helpText='Type to search'
                    onChange={e => handleSearch(e, sortOrder, setSortOrder)}
                    isSearch={true}
                    size='medium'
                />
                <Dropdown 
                    label="Sort by" 
                    options={sortingOptions} 
                    value={{ value: sortOrder, label: sortOrder.replace('-', ' to ') }} 
                    onChange={option => setSortOrder(option.value)}
                    size='medium'
                />
            </div>
        </div>
    );

    return (
        <UserWrapper>
        <div className="planner">
            {isLoading && renderLoadingAnimation()}
            {!isLoading && (
                <>
                    <div className="planner-header">
                        <h1>Your Planner</h1>
                        {renderPlanCreation()}
                    </div>

                    <div className="planner-section latest-plans">
                        {renderSectionHeading("Recently Updated", sortOrderLatest, setSortOrderLatest, "Search in Recently Updated...")}
                        {renderPlans(latestPlans, sortOrderLatest)}
                    </div>

                    <div className="planner-section created-plans">
                        {renderSectionHeading("Plans You Created", sortOrderCreated, setSortOrderCreated, "Search in Plans You Created...")}
                        {renderPlans(createdPlans, sortOrderCreated)}
                    </div>

                    <div className="planner-section shared-plans">
                        {renderSectionHeading("Plans Shared With You", sortOrderShared, setSortOrderShared, "Search in Plans Shared With You...")}
                        {renderPlans(sharedPlans, sortOrderShared)}
                    </div>
                </>
            )}
        </div>
        </UserWrapper>
    );
};

export default Planner;