import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputBox from '../components/InputBox/InputBox';
import UploadBox from '../components/uploadbox/UploadBox';
import Button from '../components/button/Button';
import DescriptionBox from '../components/descriptionbox/DescriptionBox';
import './NewEditBlog.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminWrapper from '../components/adminwrapper/AdminWrapper';
import activityTypeKeywords from '../components/tags/activityType.json';
import MultiDropdown from '../components/dropdown/MultiDropdown';
import seasonOptions from '../components/tags/seasonOptions.json';
import moodOptions from '../components/tags/moodOptions.json';
import accessibilityOptions from '../components/tags/accessibilityOptions.json';

const NewEditBlog = ({ isEdit }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [headerImage, setHeaderImage] = useState(null);
  const [headerImageUrl, setHeaderImageUrl] = useState(null);
  const [content, setContent] = useState('');
  const [readDuration, setReadDuration] = useState(''); 
  const [activityType, setActivityType] = useState([]);
  const [idealSeason, setIdealSeason] = useState([]);
  const [mood, setMood] = useState([]);
  const [accessibility, setAccessibility] = useState([]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setHeaderImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setHeaderImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = () => {
    const handleBlogDataSubmit = (uploadedImageUrl) => {
      const blogData = {
        title: title,
        subtitle: subtitle,
        headerImage: uploadedImageUrl,
        content: content,
        readDuration: readDuration,
        activityType: Array.isArray(activityType) ? activityType.map(item => item.value) : [],
        idealSeason: Array.isArray(idealSeason) ? idealSeason.map(item => item.value) : [],
        mood: Array.isArray(mood) ? mood.map(item => item.value) : [],
        accessibility: Array.isArray(accessibility) ? accessibility.map(item => item.value) : [],
      };
      
      let request;
      if (isEdit) {
          request = axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/blogs/${id}`, blogData);
      } else {
         request = axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/blogs`, blogData);
      }
      
      request.then((response) => {
        console.log(response.data);
        toast.success(isEdit ? "Blog updated successfully!" : "Blog published successfully!", {
          style: {
            border: "1px solid #00a875",
            padding: "16px",
            color: "#000000",
            background: "#b3f8ca"
          }
        });
        navigate(`/blogs/${response.data._id}`);
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.", {
          style: {
            border: "1px solid #f85454",
            padding: "16px",
            color: "#000000",
            background: "#ffbaba"
          }
        });
        console.error('Error:', error);
      });
    }
    
    if (headerImage) {
      const imageFormData = new FormData();
      imageFormData.append('image', headerImage);
      
      axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/upload`, imageFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        const uploadedImageUrl = response.data.Location;
        handleBlogDataSubmit(uploadedImageUrl);
      })
      .catch((error) => {
        console.error('Image Upload Error:', error);
      });
    } else {
      handleBlogDataSubmit(headerImageUrl);
    }
  };
  

  useEffect(() => {
    if (isEdit) {
      axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/blogs/${id}`)
        .then(response => {
          const blog = response.data;
          console.log('Fetched Blog Data:', blog);
          setTitle(blog.title ?? '');
          setSubtitle(blog.subtitle ?? '');
          setHeaderImageUrl(blog.headerImage ?? null);
          setContent(blog.content ?? '');
          setReadDuration(blog.readDuration ?? '');
          setIdealSeason(Array.isArray(blog.idealSeason) ? blog.idealSeason.map(season => ({ value: season, label: season })) : []);
          setMood(Array.isArray(blog.mood) ? blog.mood.map(m => ({ value: m, label: m })) : []);
          setAccessibility(Array.isArray(blog.accessibility) ? blog.accessibility.map(a => ({ value: a, label: a })) : []);
          setActivityType(Array.isArray(blog.activityType) ? blog.activityType.map(type => ({ value: type, label: type })) : []);
        })
        .catch(error => {
          console.error('Error fetching blog data:', error);
        });
    }
  }, [id, isEdit]);
  

  const handleChange = (field, selectedValues) => {
    switch (field) {
    case 'idealSeason':
    setIdealSeason(selectedValues);
    break;
    case 'mood':
    setMood(selectedValues);
    break;
    case 'accessibility':
    setAccessibility(selectedValues);
    break;
    case 'activityType':
    setActivityType(selectedValues);
    break;
    default:
    break;
    }
    };

  const handleDelete = () => {
    axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/api/blogs/${id}`)
      .then(response => {
        toast.success("Blog deleted successfully!", {
          style: {
            border: "1px solid #00a875",
            padding: "16px",
            color: "#000000",
            background: "#b3f8ca"
          }
        });
        navigate('/blogs');
      })
      .catch(error => {
        toast.error("An error occurred while deleting the blog. Please try again.", {
          style: {
            border: "1px solid #f85454",
            padding: "16px",
            color: "#000000",
            background: "#ffbaba"
          }
        });
        console.error('Error deleting blog:', error);
      });
  };

  return (
    <AdminWrapper>
    <div className="NewEditBlog">
      <ToastContainer position="bottom-right" autoClose={2000} />
      <div className="content-width">
        <div className="titleContainer">
          <h2>{isEdit ? 'Edit Blog Post' : 'Create New Blog'}</h2>
          <div className='button-set'>
  
            <Button 
              label={isEdit ? 'Update Post' : 'Publish'} 
              type="button" 
              variant="primary" 
              handleClick={handleSubmit} 
              disabled={false} 
              withIcon={false} 
              icon={""} 
              size="small" 
            />
  
            {isEdit && 
              <Button 
                className="delete-btn" 
                label="Delete Blog" 
                type="button" 
                variant="danger" 
                handleClick={handleDelete}
                disabled={false} 
                withIcon={false} 
                size="small" 
              />
            }
          </div>
        </div>
        <InputBox
          title="Title of the blog post"
          helpText="Enter Title"
          type="text"
          size="large"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
  
        <InputBox
          title="Subtitle"
          helpText="Enter subtitle"
          type="text"
          size="large"
          value={subtitle}
          onChange={e => setSubtitle(e.target.value)}
        />

        <InputBox
          title="Read duration"
          helpText="Enter read duration in minutes"
          type="text"
          size="large"
          value={readDuration}
          onChange={e => setReadDuration(e.target.value)}
        />
  
        <UploadBox 
        size="large" 
        label='Select Header Image' 
        file={headerImage} 
        onChange={handleImageUpload}
        initialImage={headerImageUrl}
      />

  
        <DescriptionBox 
          label='Description' 
          value={content} 
          onChange={value => setContent(value)} 
        />

        <MultiDropdown title="Activity Type" options={activityTypeKeywords} onChange={selected => handleChange('activityType', selected)} variant="multi" size="large" isMulti={true} value={activityType} />
        <MultiDropdown title="Ideal Season" options={seasonOptions} onChange={selected => handleChange('idealSeason', selected)} variant="multi" size="large" isMulti={true} value={idealSeason}/>
        <MultiDropdown title="Mood" options={moodOptions} onChange={selected => handleChange('mood', selected)} variant="multi" size="large" isMulti={true} value={mood}/>
        <MultiDropdown title="Accessibility" options={accessibilityOptions} onChange={selected => handleChange('accessibility', selected)} variant="multi" size="large" isMulti={true} value={accessibility}/>
                 </div>
    </div>
    </AdminWrapper>
  );
};

export default NewEditBlog;
