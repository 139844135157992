import React from 'react';
import './ActivityCardSkeleton.css';

const ActivityCardSkeleton = () => {
  return (
    <div className="ActivityCardSkeleton">
      <div className="header-section">
        <div className="skeleton-image"></div>
        <div className="header-icons">
          <div className="skeleton-icon"></div>
          <div className="skeleton-icon"></div>
        </div>
      </div>
      <div className="content">
        <div className="title-and-like">
          <div className="skeleton-text wide"></div>
          <div className="skeleton-icon small"></div>
        </div>
        <div className="skeleton-text"></div>
      </div>
      <div className="action-area">
        <div className="skeleton-icon small"></div>
        <div className="skeleton-button small"></div>
      </div>
    </div>
  );
};

export default ActivityCardSkeleton;
