import React from 'react';
import './ProviderContactSection.css';
import Button from '../button/Button';

function ProviderContactSection() {
  return (
    <div className="provider-contact-section">
      <div className="provider-message-container">
        <h1>Let's Amplify Your Business!</h1>
        <p>Connect with us to showcase your activities on our esteemed platform and enjoy a wider reach through our social media channels.</p>
        <div className='button-provider'>
        <Button size='medium' label='Express Interest' variant="primary" link="/contactUs" />
      </div>
      </div>
    </div>
  );
}

export default ProviderContactSection;
