import React, { useState, useEffect } from 'react';
import InputBox from '../components/InputBox/InputBox';
import Button from '../components/button/Button.jsx';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './ResetPassword.css';
import {sanitizeInput} from "../components/utils/sanitizeInput";

const ResetPassword = () => {
const [newPassword, setNewPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');
const [message, setMessage] = useState('');
const [redirectCountdown, setRedirectCountdown] = useState(null);
const { token } = useParams();
const navigate = useNavigate();

useEffect(() => {
let interval;
if (redirectCountdown > 0) {
interval = setInterval(() => {
setRedirectCountdown((prevCountdown) => prevCountdown - 1);
}, 1000);
} else if (redirectCountdown === 0) {
navigate('/login');
}

return () => clearInterval(interval);
}, [redirectCountdown, navigate]);

const handleResetPassword = async () => {
  // Sanitize new password and confirm password inputs
  const sanitizedNewPassword = sanitizeInput(newPassword);
  const sanitizedConfirmPassword = sanitizeInput(confirmPassword);

  if (sanitizedNewPassword !== sanitizedConfirmPassword) {
      setMessage('Passwords do not match.');
      return;
  }

  try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/reset-password`, {
          token,
          newPassword: sanitizedNewPassword
      });
      setMessage('Password has been reset successfully. Navigating you to the login page in...');
      setRedirectCountdown(5);
  } catch (error) {
      if (error.response && error.response.data) {
          setMessage(error.response.data.message);
      } else {
          setMessage('An error occurred. Please try again.');
      }
  }
};

return (
<div className='reset-password-container'>
<h1>Reset Password</h1>

{redirectCountdown === null && (
<>
<InputBox
className='input-box'
size='large'
title="New Password"
variant="password"
value={newPassword}
onChange={(e) => setNewPassword(e.target.value)}
/>
<InputBox
className='input-box'
size='large'
title="Confirm Password"
variant="password"
value={confirmPassword}
onChange={(e) => setConfirmPassword(e.target.value)}
/>
<Button size='small' variant='primary' label='Reset Password' handleClick={handleResetPassword}>Reset Password</Button>
<div className='reset-password-message'>
{message && <p>{message} {redirectCountdown !== null && redirectCountdown}</p>}
</div>
</>
)}
</div>
);
};

export default ResetPassword;
