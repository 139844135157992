import React from 'react';
import CategoryCard from '../components/categoryCards/CategoryCard';
import './Category.css';
import { Helmet } from 'react-helmet';
import Nature from '../assets/images/categories/Nature.svg';
import Relaxation from '../assets/images/categories/relaxation.svg';
import City from '../assets/images/categories/city.svg';
import Workshop from '../assets/images/categories/workshop.svg';
import Volunteer from '../assets/images/categories/volunteer.svg';
import Active from '../assets/images/categories/active.svg';
import Games from '../assets/images/categories/Games.svg';
import Unique from '../assets/images/categories/unique.svg';
import Romantic from '../assets/images/categories/romantic.svg';
import Family from '../assets/images/categories/family.svg';
import Elderly from '../assets/images/categories/elderly.svg';
import Eco from '../assets/images/categories/eco.svg';

export const categories = [
        { title: "Nature", image: Nature, keyword: "nature" },
        { title: "Relaxation", image: Relaxation, keyword: "relaxation" },
        { title: "City Tour & Culture", image: City, keyword: "city" },
        { title: "Workshops & Certifications", image: Workshop, keyword: "workshop" },
        { title: "Volunteer", image: Volunteer, keyword: "volunteer" },
        { title: "Sports & Active", image: Active, keyword: "active" },
        { title: "Games", image: Games, keyword: "games" },
        { title: "Unique", image: Unique, keyword: "unique" },
        { title: "Romantic", image: Romantic, keyword: "romantic" },
        { title: "Family & Kids", image: Family, keyword: "family" },
        { title: "Elderly Friendly", image: Elderly, keyword: "elderly" },
        { title: "Eco-friendly", image: Eco, keyword: "eco" }
];

const Category = () => {

    return (
        <div className="category-container">
<Helmet>
<link rel="canonical" href="https://www.rebrid.co/category" />
</Helmet>
            <h1>Categories</h1>
            <p>Click on any card to view all activities in the category.</p>
            <div className="category-cards-wrapper">
                {categories.map((category, index) => (
                    <div className='category-card-container' key={index}>
                        <CategoryCard {...category} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Category;
