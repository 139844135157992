import React, { useState, useEffect } from 'react';
import './FeedBackSection.css';
import Button from '../button/Button';
import closeIcon from '../../assets/icons/close-white.svg';

function FeedBackSection() {
    const [isOpen, setIsOpen] = useState(false);
    const [isRendered, setIsRendered] = useState(true);  // Initially set to true to auto-render

    // Auto-show the modal with animation after 1 second
    useEffect(() => {
        setTimeout(() => {
            setIsOpen(true);
        }, 1000);
    }, []);

    const handleClose = () => {
        setIsOpen(false);
        setTimeout(() => {
            setIsRendered(false);
        }, 1300);  // Wait for the animation to complete before removing from DOM
    }

    if (!isRendered) return null;

    return (
        <div className={`feedback-section ${isOpen ? 'open' : ''}`}>
            <div className="text-container">
                <button className="close-btn" onClick={handleClose}>
                    <img src={closeIcon} alt="Close" />
                </button>
    
                <div className="shape-container"></div>
                <h2>Your Feedback Matters</h2>
                <p>Let us know what features you'd like to see and we'll work on implementing them.</p>
                <Button size='large' label='Provide Feedback' variant="secondary" to="/contactUs">Contact Us</Button>
            </div>
        </div>
    );
    
}

export default FeedBackSection;
