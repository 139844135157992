import React, { useEffect, useState, useCallback } from 'react';
import './UserDashboard.css';
import jwtDecode from 'jwt-decode';
import Close from '../assets/icons/close.svg';
import Alert from '../assets/icons/alert.svg';
import Button from '../components/button/Button';
import ActivityCardSection from '../components/activityCardSection/ActivityCardSection';
import UserWrapper from '../components/userwrapper/UserWrapper';
import HeroSection from '../components/heroSection/HeroSection';
import CategorySection from '../components/categorySection/CategorySection';

const UserDashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [userData, setUserData] = useState({
        user: null,
        isProfileComplete: true,
    });

    const LoadingAnimation = () => {
        return (
            <div className="loading-container">
                <div className="loading-line-bg"></div>
                <div className="loading-line" style={{ width: `${loadingProgress}%` }}></div>
                <p className="loading-text">Loading Your Dashboard... {loadingProgress}%</p>
            </div>
        );
    };

    const fetchUserProfile = useCallback(async (userId) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/user/profile?userId=${userId}`);
            const userProfile = await response.json();
            setUserData({
                user: userProfile,
                isProfileComplete: checkProfileCompleteness(userProfile),
            });
            setLoadingProgress(100);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        let interval;
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            interval = setInterval(() => {
                setLoadingProgress((prevProgress) => Math.min(prevProgress + Math.floor(Math.random() * 10), 95));
            }, 500);
            fetchUserProfile(decoded.userId);
        } else {
            console.error('Token is not available');
        }

        return () => clearInterval(interval);
    }, [fetchUserProfile]);

    const checkProfileCompleteness = (userProfile) => {
        return userProfile.firstName && userProfile.lastName && userProfile.country && userProfile.dateOfBirth;
    };

    const capitalizeFirstLetter = (string) => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    };

return (
    <UserWrapper>
        {isLoading ? (
            <div className="full-page-loading">
                <LoadingAnimation />
            </div>
        ) : (
            <div className='userDashboard-page'>
                <HeroSection variant="userDashboard" />
                <CategorySection/>
                <div className='greet-line'>
                    <h1>Hello {userData.user?.firstName && capitalizeFirstLetter(userData.user.firstName)},</h1>
                </div>

                <div className={userData.isProfileComplete ? 'alert-container hidden' : 'alert-container'}>
                    <img src={Close} alt="Close" className='close-icon' onClick={() => setUserData({ ...userData, isProfileComplete: true })} />
                    <div className='alert-text'>
                        <img src={Alert} alt="Alert" className='alert-icon' />
                        <p>We noticed that you have not completed your profile. Click on the button to navigate to your Profile page.</p>
                    </div>
                    <Button variant='primary' size='small' label='View Profile' to='/userProfile'></Button>
                </div>

                <div className='userDashboard-activities'>
                    <ActivityCardSection category="yourPreferences" sectionTitle="Recommended For You" variant="default" />
                    <ActivityCardSection category="ip" sectionTitle="Activities Based On Your IP" variant="default" />
                    <ActivityCardSection category="local" sectionTitle="Activities In Your City" variant="default" />
                    <ActivityCardSection category="premium" sectionTitle="Premium Activities" variant="default" />
                    <ActivityCardSection category="country" sectionTitle="Activities In Your Country" variant="default" />
                    <ActivityCardSection category="interests" sectionTitle="You Might Also Like" variant="default" />
                    <ActivityCardSection category="watchHistory" sectionTitle="Your Watch History" variant="default" />
                </div>
            </div>
        )}
    </UserWrapper>
);

}
export default UserDashboard;
