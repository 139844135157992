import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import countriesAndCities from '../keywords/cities.json';
import categories from '../tags/subCategory.json';
import seasonOptions from '../tags/seasonOptions.json';
import accessibilityOptions from '../tags/accessibilityOptions.json';
import activityTypeOptions from '../tags/activityType.json';
import moodOptions from '../tags/moodOptions.json';
import ageLimitOptions from '../tags/ageLimitOptions.json';
import fitnessOptions from '../tags/fitnessLevelOptions.json';
import './Footer.css';

const Footer = () => {
const [showLinks, setShowLinks] = useState(false);
const toggleLinks = () => setShowLinks(!showLinks);

return (
    <footer className="footer">
        <hr />
        <button className="toggle-links-btn" onClick={toggleLinks}>
            <p>{showLinks ? 'Compress' : 'Expand'} Footer</p>
        </button>
        {showLinks && (
<div className="footer-container">
<div className='footer-category-section'>
<div className='by-category'>
<h5>Activities By Country</h5>
<div className="footer-section">
{Object.keys(countriesAndCities).map(country => (
<p key={country}>
<Link to={`/location/${country}`}>Things to do in {country}   | </Link>
</p>
))}
</div>
</div>
<div className='by-category'>
<h5>Activities By City</h5>
<div className="footer-section"> 
{Object.entries(countriesAndCities).flatMap(([country, cities]) => 
cities.map(city => (
<p key={city}>
<Link to={`/searchResult?search=${city}`}>Things to do in {city}  |</Link>
</p>
)))}
</div>
</div>

{/* By Category */}
<div className='by-category'>
<h5>By Categories</h5>
<div className="footer-section">
{categories.map(({ label, value }) => (
<p key={value}>
<Link to={`/category/${value}`}>{label} |</Link>
</p>
))}
</div>
</div>

{/* By Accessibility */}
<div className='by-category'>
<h5>Accessibility</h5>
<div className="footer-section">
{accessibilityOptions.map(({ label, value }) => (
<p key={value}>
<Link to={`/searchResult?search=${value}`}>{label} activities  |</Link>
</p>
))}
</div>
</div>
{/* By Season */}
<div className='by-category'>
<h5>By Season</h5>
<div className="footer-section">
{seasonOptions.map(({ label, value }) => (
<p key={value}>
<Link to={`/searchResult?search=${value}`}>Things to do during the {label} season  |</Link>
</p>
))}
</div>
</div>

{/* By Activity Type */}
<div className='by-category'>
<h5>By Type</h5>
<div className="footer-section">
{activityTypeOptions.map(({ label, value }) => (
<p key={value}>
<Link to={`/searchResult?search=${value}`}>{label} |</Link>
</p>
))}
</div>
</div>

{/* By Mood */}
<div className='by-category'>
<h5>By Mood</h5>
<div className="footer-section">
{moodOptions.map(({ label, value }) => (
<p key={value}>
<Link to={`/searchResult?search=${value}`}>{label} activities |</Link>
</p>
))}
</div>
</div>

{/* By Fitness Level */}
<div className='by-category'>
<h5>By Fitness Level</h5>
<div className="footer-section">
{fitnessOptions.map(({ label, value }) => (
<p key={value}>
<Link to={`/searchResult?search=${value}`}>{label} friendly activities |</Link>
</p>
))}
</div>
</div>

{/* By Age */}
<div className='by-category'>
<h5>By Age</h5>
<div className="footer-section">
{ageLimitOptions.map(({ label, value }) => (
<p key={value}>
<Link to={`/searchResult?search=${value}`}>{label} friendly activities |</Link>
</p>

))}
<hr />
</div>
</div>
</div>
</div>
)}
<div className="footer-main">
<div className='base-footer'>
<p><Link to="/">Home</Link></p>
<p><Link to="/discover">Discover</Link></p>
<p> <Link to="/sitemap">Sitemap</Link></p>
<p> <Link to="/category">Category</Link></p>
<p> <Link to="/mybucketlist">My Bucketlist</Link></p>
<p> <Link to="/contactus">Contact Us</Link></p>
<p> <Link to="/tnc">Terms & Conditions</Link></p>
<p> <Link to="/privacyPolicy">Privacy Policy</Link></p>
</div>
<div className="footer-bottom">
<p>© 1997-2023 Rebrid 2023</p>
</div>
</div>
</footer>
);
};

export default Footer;
