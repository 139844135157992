import React from 'react';
import './Pagination.css';

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
const totalPages = Math.ceil(totalItems / itemsPerPage);
const maxButtonsToShow = 5; // Adjust this value as per your design
const startPage = Math.max(0, currentPage - Math.floor(maxButtonsToShow / 2));
const endPage = Math.min(totalPages, startPage + maxButtonsToShow);

if (totalPages <= 1) {
return null; 
}

return (
<div className="pagination">
<button
className={`pagination-button pagination-button-prev ${currentPage === 0 ? 'disabled' : ''}`}
onClick={() => currentPage > 0 && onPageChange(currentPage - 1)}
>
Previous
</button>

{Array.from({ length: endPage - startPage }, (_, index) => {
const pageNumber = startPage + index;
return (
<button
key={pageNumber}
className={`pagination-button ${pageNumber === currentPage ? 'active' : ''}`}
onClick={() => {
console.log("Button clicked:", pageNumber);
onPageChange(pageNumber);
}}
>
{pageNumber + 1}
</button>

);
})}

<button
className={`pagination-button pagination-button-next ${currentPage === totalPages - 1 ? 'disabled' : ''}`}
onClick={() => currentPage < totalPages - 1 && onPageChange(currentPage + 1)}
>
Next
</button>
</div>
);

};

export default Pagination;
