import React, { useState, useEffect, useContext } from "react";
import StarOutline from "../../assets/icons/star-outline.svg";
import StarFill from "../../assets/icons/star-fill.svg";
import StarHalf from "../../assets/icons/star-half.svg";
import Bin from "../../assets/icons/delete.svg";
import InputBox from "../InputBox/InputBox";
import Button from "../button/Button";
import "./ReviewsandRatings.css";
import UserContext from "../UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Modal from "../modal/modal";

function ReviewsAndRatings({
activityId,
variant,
averageRating,
totalReviews,
}) {
const { userType } = useContext(UserContext);
const navigate = useNavigate();
const [reviewText, setReviewText] = useState("");
const [reviewError, setReviewError] = useState("");
const [userRating, setUserRating] = useState("");
const [reviews, setReviews] = useState([]);
const [showModal, setShowModal] = useState(false);
const [reviewToDelete, setReviewToDelete] = useState(null);
const token = localStorage.getItem("token");
let currentUserId = null;
if (token) {
try {
const decodedToken = jwtDecode(token);
currentUserId = decodedToken.userId;
} catch (err) {
console.error("Error decoding token:", err);
}
}

const renderStars = (rating, clickable = false, forceWholeStars = false) => {
let stars = [];
let fullStars = Math.floor(rating);
let halfStar =
!forceWholeStars && variant === "card" && rating - fullStars >= 0.5;

for (let i = 1; i <= 5; i++) {
// If it's clickable (user leaving a review), fill the stars based on userRating
if (clickable) {
if (i <= userRating) {
stars.push(
<img
src={StarFill}
alt="star"
key={i}
onClick={() => handleStarClick(i)}
/>
);
} else {
stars.push(
<img
src={StarOutline}
alt="star-outline"
key={i}
onClick={() => handleStarClick(i)}
/>
);
}
} else {
// Existing logic for displaying stars (used for average ratings)
if (i <= fullStars) {
stars.push(<img src={StarFill} alt="star" key={i} />);
} else if (halfStar && i === fullStars + 1) {
stars.push(<img src={StarHalf} alt="half-star" key={"half"} />);
} else {
stars.push(<img src={StarOutline} alt="star-outline" key={i} />);
}
}
}
return stars;
};

const renderSingleStar = (rating) => {
if (rating >= 0.5) {
return <img src={StarFill} alt="star" />;
} else {
return <img src={StarOutline} alt="star-outline" />;
}
};

const handleStarClick = (rating) => {
setUserRating(rating);
};

const handleDeleteReview = (reviewId) => {
setReviewToDelete(reviewId);
setShowModal(true);
};

const confirmDelete = async () => {
if (reviewToDelete) {
try {
const headers = token
? {
Authorization: `Bearer ${token}`,
"Content-Type": "application/json",
}
: undefined;
await axios.delete(
`${process.env.REACT_APP_SERVER_BASE_URL}/api/review/${reviewToDelete}`,
headers ? { headers } : undefined
);

setReviews((prevReviews) =>
prevReviews.filter((review) => review._id !== reviewToDelete)
);

setReviewToDelete(null);
setShowModal(false);
} catch (error) {
console.error("Error deleting review:", error);
}
}
};

useEffect(() => {
    const fetchReviews = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/${activityId}/review`
            );
  
            if (Array.isArray(response.data)) {
                const reviewsWithUserData = response.data.map(review => {
                    // Determine the display name and initial
                    let displayName = review.userId?.userName || review.userId?.firstName;
                    let initial = "";
  
                    if (!displayName) {
                        displayName = "Anonymous"; // Set to Anonymous if no name is available
                        initial = "A"; // Default initial for Anonymous
                    } else {
                        initial = displayName.charAt(0).toUpperCase(); // Get the first character of the name
                    }
  
                    return {
                        ...review,
                        user: {
                            firstName: displayName,
                            initial: initial,
                            photoLink: review.userId?.profilePhoto || "" // Use existing photo or an empty string
                        }
                    };
                });
  
                setReviews(reviewsWithUserData);
            } else {
                console.error("Received unexpected data format:", response.data);
            }
        } catch (error) {
            console.error("Error fetching reviews:", error);
        }
    };
  
    fetchReviews();
  }, [activityId]);
  

const sanitizeInput = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.body.textContent || "";
  };  


const handleSubmit = async () => {
if (userType !== "user" && userType !== "admin") {
navigate("/login");
return;
}

if (!reviewText.trim()) {
setReviewError("Review cannot be empty!");
return;
}

const sanitizedReviewText = sanitizeInput(reviewText);

if (!token) {
    setReviewError("You are not authenticated. Please login again.");
    return;
}

const decodedToken = jwtDecode(token);

if (!decodedToken || !decodedToken.userId) {
setReviewError(
"There's a problem with your session. Please login again."
);
return;
}

const newReview = {
reviewText: reviewText,
userRating: userRating,
activityId: activityId,
userId: decodedToken.userId,
};

const headers = token
? { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }
: undefined;

try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/review`,
      newReview,
      headers ? { headers } : undefined
    );
    const data = response.data;
    
    if (data.updatedAverageRating && data.updatedTotalReviews) {
      const userProfileResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/profile`,
        {
          params: {
            userId: jwtDecode(token).userId,
          },
          headers: headers,
        }
      );

      let userData = userProfileResponse.data;
      let displayName = userData.userName || userData.firstName || "Anonymous";
      let photoLink = userData.profilePhoto || "";
      let initial = displayName ? displayName.charAt(0).toUpperCase() : "A";

      const reviewWithUserData = {
        ...newReview,
        createdAt: new Date().toISOString(), // Set the current date and time for the new review
        user: {
          firstName: displayName,
          initial: initial,
          photoLink: photoLink,
        },
      };

      setReviews((prevReviews) => [...prevReviews, reviewWithUserData]);
      setReviewText("");
      setUserRating("");
    } else {
      setReviewError(data.message);
    }
  } catch (error) {
    setReviewError("Error submitting review. Please try again later.");
  }
};

const handleRedirectIfNotAuthorized = () => {
if (userType !== "user" && userType !== "admin") {
navigate("/login");
}
};

const formatAverageRating = (rating) => {
return rating % 1 === 0 ? rating.toFixed(0) : rating.toFixed(1);
};

const calculatedAverageRating = reviews.length
? reviews.reduce((sum, review) => sum + parseFloat(review.userRating), 0) /
reviews.length
: averageRating;

if (variant === "card") {
return (
<div className="ratings-card">
{renderSingleStar(calculatedAverageRating)}
<span>{formatAverageRating(calculatedAverageRating)}</span>
</div>
);
}

if (variant === "page") {
return (
<div className="ratings-page">
<div className="ratings-title">
<h1>Leave A Review</h1>
</div>
<div className="user-rating">
<p>Your rating (out of 5stars)</p>
{renderStars(0, true, true)}
</div>
<InputBox
type="paragraph"
title="Your Review"
value={reviewText}
onChange={(e) => setReviewText(e.target.value)}
placeholder="Write your review here..."
errorText={reviewError}
onFocus={handleRedirectIfNotAuthorized}
/>
<Button
className="review-btn"
label="Submit"
type="submit"
variant="primary"
size="large"
handleClick={handleSubmit}
/>
<div className="reviews-container">
          {reviews.map((review, index) => (
            <div key={index} className="review-wrapper">
              <div className="review-avatar-wrapper">
                {review.user.photoLink ? (
                  <img className="user-photo" src={review.user.photoLink} alt={review.user.firstName} />
                ) : (
                  <div className="default-user-icon">
                    <p>{review.user.initial}</p> {/* Use the initial if no photo */}
                  </div>
                )}
                <div className="review-content">
                    <div className="review-header">
                        <div className="name-rating">
                            <h6>{review.user.firstName}</h6>
                            <div className="user-review-stars">
                                {renderStars(review.userRating)}
                            </div>
                        </div>
                        {currentUserId && review.userId && review.userId._id === currentUserId && (
                            <img className="delete-icon" src={Bin} alt="delete" onClick={() => handleDeleteReview(review._id)} />
                        )}
                    </div>
                    <p>{review.reviewText}</p>
                    <div className="review-timestamp">
                        <p>Posted on: {new Date(review.createdAt).toLocaleDateString()}</p>
                    </div>
                </div>
            </div>
        </div>
    ))}
</div>

<Modal
title="Confirm Deletion"
message="Are you sure you want to delete this review?"
handleClose={() => setShowModal(false)}
show={showModal}
actions={[
{
label: "Cancel",
variant: "tertiary",
onClick: () => setShowModal(false),
},
{
label: "Delete",
variant: "danger",
onClick: confirmDelete,
},
]}
/>
</div>
);
}

return null;
}

export default ReviewsAndRatings;
