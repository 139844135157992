import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from "react";
import { Helmet } from 'react-helmet';
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import "./Activity.css";
import Button from "../components/button/Button";
import UserContext from "../components/UserContext";
import MyBucketlistButton from "../components/myBucketlistbutton/MyBucketlistButton";
import ShareButton from "../components/sharebutton/ShareButton";
import facebookLogo from "../assets/icons/facebook.svg";
import instagramLogo from "../assets/icons/instagram.svg";
import ActivityCardSection from "../components/activityCardSection/ActivityCardSection";
import ReviewsAndRatings from "../components/reviewsAndRatings/ReviewsAndRatings";
import BlogSection from "../components/blogSection/BlogSection";
import LocationPin from "../assets/icons/location-place.svg";
import PlanModal from '../components/Planner/PlanModal';
import ActivityIcon from '../assets/icons/activityIcon.svg';
import PlaceIcon from '../assets/icons/placeIcon.svg';

// Example array of hardcoded keywords for testing
const testKeywords = [
"Summer",
"Adventurous",
"Energetic",
"Nature and Outdoors",
];

const Activity = (token,) => {
const [activity, setActivity] = useState(null);
const [activeImage, setActiveImage] = useState(0);
const { id } = useParams();
const { userType } = useContext(UserContext);
const [viewCount, setViewCount] = useState(0);
const [isModalOpen, setIsModalOpen] = useState(false);
const [, setIsActivityFree] = useState(false);
const [, setError] = useState(null);
const lastRequestTime = useRef(0);
const [keywordOptions, setKeywordOptions] = useState("");
const [routes, setRoutes] = useState([]);
const [activeTab, setActiveTab] = useState("Images");
const imageGalleryRef = useRef(null);
const activityDetailsRef = useRef(null);
const activityProviderRef = useRef(null);
const reviewsRef = useRef(null);
const modalButtonRef = useRef(null);
const navigate = useNavigate();
const shouldShowProviderTab =
activity && activity.provider && activity.provider.trim().length > 0;
// Scroll to section function
const scrollToSection = (ref) => {
if (ref.current) {
// Get the position of the element relative to the top of the document
const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;

// Define the offset value
const offset = 200; // Adjust this value as needed

// Calculate the final position (element position - offset)
const finalPosition = elementPosition - offset;

// Smoothly scroll to the final position
window.scrollTo({
top: finalPosition,
behavior: 'smooth'
});
}
};

const handleOpenModal = (e) => {
  e.stopPropagation();
  if (!userType) {
    return navigate('/login');
  } 
  setIsModalOpen(true);
};


// Function to handle tab click
const handleTabClick = (event) => {
const tabName = event.target.innerText;
setActiveTab(tabName);
switch (tabName) {
case "Images":
scrollToSection(imageGalleryRef);
break;
case "Activity Details":
scrollToSection(activityDetailsRef);
break;
case "Activity Provider":
scrollToSection(activityProviderRef);
break;
case "Reviews":
scrollToSection(reviewsRef);
break;
default:
break;
}
};


// Function to update tab based on scroll position
const handleScroll = useCallback(() => {
  const sections = [
    { name: "Images", ref: imageGalleryRef },
    { name: "Activity Details", ref: activityDetailsRef },
    { name: "Activity Provider", ref: activityProviderRef },
    { name: "Reviews", ref: reviewsRef }
  ];

  // Find the section that is currently in view
  const currentSection = sections.find(section => {
    if (section.ref.current) {
      const rect = section.ref.current.getBoundingClientRect();
      // Check if the top of the section is within the upper half of the viewport
      return rect.top >= -rect.height / 2 && rect.top <= window.innerHeight / 2;
    }
    return false;
  });

  if (currentSection && currentSection.name !== activeTab) {
    setActiveTab(currentSection.name);
  }
}, [activeTab]);




useEffect(() => {
window.addEventListener('scroll', handleScroll);
return () => window.removeEventListener('scroll', handleScroll);
}, [handleScroll]);

useEffect(() => {
const fetchData = async () => {
const currentTime = Date.now();

if (currentTime - lastRequestTime < 10000) {
return;
}
lastRequestTime.current = currentTime;

try {
const token = localStorage.getItem("token");
const headers = token
? { Authorization: `Bearer ${token}` }
: undefined;

const response = await axios.get(
`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/${id}`,
headers ? { headers } : undefined
);

const keywords = [
...(response.data.idealSeason || []),
...(response.data.mood || []),
...(response.data.tags
? response.data.tags.split(",").map((tag) => tag.trim())
: []),
...(response.data.activityType || []),
...(response.data.subCategory || []),
...(response.data.city ? [response.data.city] : []),
...(response.data.country ? [response.data.country] : []),
...(response.data.category || []),
...(response.data.accessibility || []),
].join(" ");

setKeywordOptions(keywords);
setViewCount(response.data.views);
setActivity(response.data);
setIsActivityFree(response.data.price && response.data.price.toLowerCase() === "free");

if (userType !== "admin") {
await axios.post(
`${process.env.REACT_APP_SERVER_BASE_URL}/activity/${id}/incrementView`,
{ userType },
headers ? { headers } : undefined
);
}
} catch (error) {
console.error("Error fetching activity data:", error.message || error);
setError("Failed to load activity data.");
}
};

const updateWatchHistory = async () => {
try {
const token = localStorage.getItem("token");
const headers = token
? { Authorization: `Bearer ${token}` }
: undefined;

await axios.post(
`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/${id}/watch`,
{},
headers ? { headers } : undefined
);
} catch (error) {
console.error("Error updating watch history:", error.message || error);
}
};

if (userType !== "admin") {
updateWatchHistory();
}

fetchData();
}, [id, userType]);

if (!activity) {
return <div>Loading...</div>;
}

const stopPropagation = (e) => {
e.stopPropagation();
};

  // Function to format the price display
  const formatPrice = () => {
    if (activity.isActivityFree) {
      return 'Free';
    }

    return activity.price.map((priceTier, index) => (
      <div key={index} className="cost-of-activity">
        {priceTier.pricingTierName && <h3>{priceTier.pricingTierName}</h3>}
        {priceTier.amount && (
          <h3>
            {activity.selectedCurrency} {priceTier.amount}
          </h3>
        )}
      </div>
    ));
  };

return (
<div className="activitypage">
<Helmet>
<link rel="canonical" href="https://www.rebrid.co/category" />
</Helmet>
{/* Header Image */}
<div className="activity-header">
<img
className="activity-header-image"
src={activity.headerImage}
alt="header image"
/>
</div>
      {/* Activity or Place Icon and Label */}
      <div className="place-activity-type-area">
        {activity.activityOrPlace === 'activity' ? (
          <div className="activity-type">
            <img src={ActivityIcon} alt="Activity Icon" />
            <p>Activity</p>
          </div>
        ) : (
          <div className="place-type">
            <img src={PlaceIcon} alt="Place Icon" />
            <p>Place</p>
          </div>
        )}
      </div>
{/* Title and Rating */}
<div className="activity-title-rating">
<h1>{activity.title}</h1>
<ReviewsAndRatings
variant="card"
activityId={activity._id}
averageRating={activity.averageRating || 0}
totalReviews={activity.totalReviews || 0}
/>
</div>

{/* Location and Price */}
<div className="activity-location-price">
<div className="activity-location">
<img src={LocationPin} alt="location icon" />
<p>
<strong>{activity.location}</strong>, {activity.city},{" "}
{activity.country}
</p>
</div>
<div className="activityCost">
          {formatPrice()}
        </div>
</div>

{/* Bucketlist and Share Buttons */}
<div className="activity-buttons">
  <div className="action-buttons-activity">
<MyBucketlistButton
activityId={activity._id}
userType={userType}
token={token}
size="medium"
onClick={stopPropagation}
/>
<button className='add-to-plan-activity' ref={modalButtonRef} onClick={handleOpenModal}>Add to Plan</button>
</div>
<div className="btnShare" onClick={(e) => e.stopPropagation()}>
<ShareButton
size="large"
variant="share"
label="Share"
activityId={activity._id}
/>
</div>
</div>
<hr />

{/* Tab Buttons */}
<div className="tab-buttons sticky-tabs">
  {[
    "Images",
    "Activity Details",
    ...(shouldShowProviderTab ? ["Activity Provider"] : []),
    "Reviews",
  ].map((tab) => (
    <button
      key={tab}
      onClick={handleTabClick}
      className={`tab-button ${activeTab === tab ? "selected" : ""}`}
    >
      {tab}
    </button>
  ))}
</div>


{/* // Image Gallery Tab */}
<div ref={imageGalleryRef} className="section">
<div className="activity-images-section">
<h2>Image Gallery</h2>
<div className="image-gallery">
{activity.images.map((imageObj, index) => (
<img
key={index}
className={`image-thumbnail ${
activeImage === index ? "selected-thumbnail" : ""
}`}
src={imageObj.url}
alt={`activity-${index}`}
onClick={() => setActiveImage(index)}
/>
))}
</div>

{/* Display selected image with metadata */}
{activity.images.length > 0 && (
<div className="selected-image-container">
<img
className="selected-image"
src={activity.images[activeImage].url}
alt={`Selected activity image`}
/>
<div className="image-metadata">
<h6><strong>Source:</strong></h6> <p>{activity.images[activeImage].creator || "Unknown"},</p> 
<p>{activity.images[activeImage].sourceWebsite || "Unknown"}</p>

</div>
</div>
)}
</div>
</div>

{/* Activity Details Tab */}
<div ref={activityDetailsRef} className="section">
<div className="activity-info">
<h2>Activity Details</h2>
<div className="activity-information">
{activity?.idealSeason?.length > 0 && (
<div className="label-content">
<h6>Ideal Season</h6>
<h4>{activity.idealSeason.join(", ")}</h4>
</div>
)}
{activity?.ageLimit?.length > 0 && (
<div className="label-content">
<h6>Age Limit</h6>
<h4>{activity.ageLimit.join(", ")}</h4>
</div>
)}
{activity?.fitnessLevel?.length > 0 && (
<div className="label-content">
<h6>Fitness Level Requirement</h6>
<h4>{activity.fitnessLevel.join(", ")}</h4>
</div>
)}
{activity?.capacity?.length > 0 && (
<div className="label-content">
<h6>Capacity per Batch</h6>
<h4>{activity.capacity.join(", ")}</h4>
</div>
)}
{activity?.duration && (
<div className="label-content">
<h6>Duration</h6>
<h4>{activity.duration}</h4>
</div>
)}
{activity?.languageAvailable?.length > 0 && (
<div className="label-content">
<h6>Language Support</h6>
<h4>{activity.languageAvailable.join(", ")}</h4>
</div>
)}
{activity?.accessibility?.length > 0 && (
<div className="label-content">
<h6>Accessibility</h6>
<h4>{activity.accessibility.join(", ")}</h4>
</div>
)}
</div>
</div>
<div
className="activity-description"
dangerouslySetInnerHTML={{ __html: activity?.description }}
/>
</div>

{/* Activity Provider Tab */}
{shouldShowProviderTab && (
<div ref={activityProviderRef} className="section">
<div className="provider-info">
<h2> Provider Details</h2>
<div className="provider-name-address">
<div className="logo-name-container">
<img
className="logo-icon"
src={activity.logo}
alt={activity.provider}
/>
<div className="provider-name">
<h6> Activity Provider Name</h6>
<h4>{activity.provider}</h4>
</div>
</div>
<div className="activity-socialmedia-container">
<div className="activity-socialmedia">
<img
src={facebookLogo}
alt="Facebook Logo"
className="socialmedia-icon"
/>
<a
href={activity.facebook}
target="_blank"
rel="noopener noreferrer"
></a>
</div>
<div className="activity-socialmedia">
<img
src={instagramLogo}
alt="Instagram Logo"
className="socialmedia-icon"
/>
<a
href={activity.instagram}
target="_blank"
rel="noopener noreferrer"
></a>
</div>
</div>
</div>
<div className="website-button">
{userType === "user" ? (
<Button
className="button-medium"
label="Provider Website"
type="button"
size="large"
variant="primary"
animation="water"
to={activity.website}
/>
) : userType === "admin" ? (
<div className="admin-buttons">
<Link to={`/editActivity/${id}`}>
<Button label="Edit" variant="cta" size="large" />
</Link>
</div>
) : (
<div className="blur-overlay">
<p> Register or login to view provider details </p>
<Button
label="Register"
variant="cta"
size="large"
to="/register"
/>
</div>
)}
</div>
</div>
</div>
)}

{/* Reviews Tab */}
<div ref={reviewsRef} className="section">
<div className="reviews-section">
<h2> Reviews</h2>
<ReviewsAndRatings
variant="page"
activityId={activity._id}
averageRating={activity.averageRating || 0}
totalReviews={activity.totalReviews || 0}
/>
</div>
</div>

<hr />

<div className="ActivityCardSection">
<ActivityCardSection
category="keywordsMatch"
keyword={keywordOptions}
sectionTitle="Related Activities"
currentActivityId={id}
/>
</div>
<BlogSection />
<PlanModal 
        activityId={activity._id} 
        isModalOpen={isModalOpen} 
        setIsModalOpen={setIsModalOpen} 
        buttonRef={modalButtonRef}
      />
</div>
);
};
export default Activity;
