import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import './PlanModal.css';
import CloseIcon from '../../assets/icons/close.svg';

const PlanModal = ({ activityId, isModalOpen, setIsModalOpen, buttonRef}) => {
  const [plans, setPlans] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreatingNewPlan, setIsCreatingNewPlan] = useState(false);
  const [newPlanName, setNewPlanName] = useState('');
  const { userType } = useContext(UserContext);
  const navigate = useNavigate();
  const modalRef = useRef();
  const [loadingStates, setLoadingStates] = useState({});

  useEffect(() => {
    if (userType !== 'user') {
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }

    const decodedToken = jwtDecode(token);
    const userId = decodedToken.userId;
    const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;

    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${serverBaseUrl}/api/users/${userId}/plans`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setPlans(response.data);
      } catch (error) {
        console.error('Error fetching plans:', error);
        setPlans([]);
      }
    };

    fetchPlans();
  }, [userType, navigate]);

  const handleModalClick = (e) => {
    e.stopPropagation(); // Prevent click inside the modal from closing it
  };

  const handleCreateNewPlan = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsCreatingNewPlan(true);
  };

  const handleSaveNewPlan = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
    
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.userId;
    const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  
    try {
      let planData = { primaryUser: userId, name: newPlanName };
  
      // Check if activityId is truthy and add it to activities array
      if (activityId) {
        planData = { ...planData, activities: [activityId] };
      }
  
      const response = await axios.post(`${serverBaseUrl}/api/plans`, planData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
  
      setPlans([...plans, response.data]);
      setIsCreatingNewPlan(false);
      setNewPlanName('');
    } catch (error) {
      console.error('Error creating new plan:', error);
    }
  };
  
  
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredPlans = plans.filter(plan => 
    plan.name.toLowerCase().includes(searchTerm)
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isModalOpen && !modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen, modalRef, buttonRef]);
  

  const handlePlanChange = async (planId, activityIdForPlan) => {
    setLoadingStates(prevStates => ({ ...prevStates, [activityIdForPlan]: true }));
  
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
  
    const decodedToken = jwtDecode(token);
    const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  
    try {
      const plan = plans.find(plan => plan._id === planId);
      if (!plan) {
        console.error('Plan not found');
        return;
      }
  
      if (!activityId) {
        console.error('No activityId provided');
        return;
      }
  
      const isActivityIncluded = plan.activities.some(activity => String(activity._id) === String(activityId));
      const method = isActivityIncluded ? 'delete' : 'put';
      const url = `${serverBaseUrl}/api/plans/${planId}/activities/${isActivityIncluded ? '?activityId=' + activityId : ''}`;
      const headers = { 'Authorization': `Bearer ${token}` };
      const data = { activityId };
  
      const response = await axios[method](url, method === 'put' ? { headers, activityId } : { headers });
      
      // Refresh the list of plans to reflect the changes
      const updatedPlansResponse = await axios.get(`${serverBaseUrl}/api/users/${decodedToken.userId}/plans`, { headers });
      setPlans(updatedPlansResponse.data);
    } catch (error) {
      console.error('Error updating plan:', error);
    } finally {
      setLoadingStates(prevStates => ({ ...prevStates, [activityIdForPlan]: false }));
  }
};
  

  const planOptions = plans.map(plan => ({
    value: plan._id,
    label: plan.name,
    isChecked: Array.isArray(plan.activities) && plan.activities.includes(activityId)
  }));

  const renderButton = (plan) => {
    // Use the specific activity ID from the plan
    const isActivityIncluded = plan.activities.some(activity => String(activity._id) === String(activityId));
    const isActivityLoading = loadingStates[String(activityId)];
  
    const buttonText = isActivityLoading ? (isActivityIncluded ? 'Removing...' : 'Saving...') : (isActivityIncluded ? 'Remove' : 'Save');
    const buttonClass = isActivityIncluded ? 'remove-button' : 'save-button';
  
    return (
      <button 
        className={buttonClass} 
        onClick={() => handlePlanChange(plan._id, String(activityId))}
        disabled={isActivityLoading}
      >
        {buttonText}
      </button>
    );
  };

  const getModalStyle = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      const modalWidth = 350;
      const modalHeight = 250;
      let top = rect.bottom + window.scrollY;
      let left = rect.right + window.scrollX - modalWidth; // Adjust left position
  
      // Ensure the modal is not off the left side of the screen
      if (left < 0) {
        left = rect.left + window.scrollX;
      }
  
      // Ensure the modal is not off the bottom of the screen
      if (top + modalHeight > windowHeight) {
        top = rect.top + window.scrollY - modalHeight;
      }
  
      return {
        position: 'absolute',
        top: `${top}px`,
        left: `${left}px`,
        width: `${modalWidth}px`,
        zIndex: 1000,
      };
    }
    return {};
  };
  
  

  const modalContent = (
    <div className={`plan-modal-backdrop ${isModalOpen ? 'open' : ''}`}>
      <div style={getModalStyle()} ref={modalRef} className="plan-modal" onClick={handleModalClick}>
      <div ref={modalRef} className="plan-modal" onClick={handleModalClick}>
        {window.innerWidth < 600 && (
          <div className="close-modal-button" onClick={() => setIsModalOpen(false)}>
            <img src={CloseIcon} alt="Close" />
          </div>
        )}
          <div className='title-add-remove-plan'><h4>Add/Remove from Plan</h4></div>
      <div className="modal-search-container">
        <input
          className="search-plans"
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search plans"
        />
      </div>
      <div className="modal-plans-list">
      {filteredPlans.length === 0 ? (
        <p className="no-plans-message">No Plans Found</p>
      ) : (
        filteredPlans.map((plan) => (
          <div key={plan._id} className="plan-item">
            <span>{plan.name}</span>
            {renderButton(plan)}
          </div>
        ))
      )}
    </div>
      {!isCreatingNewPlan ? (
        <div className="modal-new-plan">
          <button onClick={() => setIsCreatingNewPlan(true)}>Create New Plan</button>
        </div>
      ) : (
        <div className="modal-create-plan">
          <input
            type="text"
            value={newPlanName}
            onChange={(e) => setNewPlanName(e.target.value)}
            placeholder="Enter plan name"
          />
          <button onClick={handleSaveNewPlan}>Save</button>
        </div>
      )}
    </div>
    </div>
    </div>
  );

  if (!isModalOpen) return null;

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById('modal-root')
  );
};

export default PlanModal;