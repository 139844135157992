import React, { useEffect, useState, useContext } from "react";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InputBox from "../components/InputBox/InputBox";
import PasswordBox from "../components/PasswordBox/PasswordBox";
import Button from "../components/button/Button";
import "./LoginAdmin.css";
import Modal from "../components/modal/modal";
import UserContext from "../components/UserContext";
import VisitorWrapper from "../components/visitorwrapper/VisitorWrapper";
import { useMyBucketlist } from "../components/MyBucketlistContext";
import jwtDecode from "jwt-decode";
import GoogleIcon from "../assets/icons/Google.svg";
import {sanitizeInput} from "../components/utils/sanitizeInput";

const LoginAdmin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();
  const { setUserType } = useContext(UserContext);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const getUserIdFromToken = () => {
    const token = localStorage.getItem("token");
    return token ? jwtDecode(token).userId : null;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const userType = urlParams.get('userType');
    
    if (token) {
      localStorage.setItem("token", token);
      setUserType(userType);
      
      if (userType === 'admin') {
        setTimeout(() => { navigate('/AdminDashboard'); }, 5000);
      } else {
        const redirectURL = userType === 'user' ? 
          process.env.REACT_APP_BASE_URL + '/login' :
          `https://provider.${process.env.REACT_APP_BASE_URL}/login`;
        setTimeout(() => { window.location.href = redirectURL; }, 5000);
      }
    }
  }, [navigate, setUserType]);

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_SERVER_BASE_URL}/user/google`;
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setIsLoggingIn(true);
    setValidationTriggered(true);
  
    if (!email || !password) {
      setIsLoggingIn(false);
      return;
    }
  
    const sanitizedEmail = sanitizeInput(email);
    const sanitizedPassword = sanitizeInput(password);
  
    const dataToSend = {
      email: sanitizedEmail,
      password: sanitizedPassword,
    };
  
    axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/admin/login`, dataToSend)
      .then(async (response) => {
        if (response.data && response.data.token) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userType", response.data.userType);
          setUserType(response.data.userType);
          navigate('/AdminDashboard');
        }
      })
      .catch((error) => {
        setIsLoggingIn(false);
        if (error.response && error.response.data && error.response.data.message) {
          setShowModal(true);
          setModalTitle("Login Failed");
          setModalMessage(error.response.data.message);
        } else {
          console.error("Error during login:", error);
          setShowModal(true);
          setModalTitle("Login Failed");
          setModalMessage("Something went wrong. Please try again later.");
        }
      });
  };
  

  const handleModalClose = () => {
    setShowModal(false);
    setIsLoggingIn(false);
    navigate("/login");
  };

  const modalActions = [
    { label: "Try Again", onClick: handleModalClose, variant: "secondary" },
  ];

  return (
    <VisitorWrapper>
      <Helmet>
        <link rel="canonical" href="https://www.rebrid.co/login" />
      </Helmet>
      <div className="login-page">
        <div className="login-form-container">
          <form onSubmit={handleLogin}>
            <h1>Admin Login</h1>
            <div className="email">
              <InputBox
                title="Email"
                helpText="Enter your email"
                type="email"
                size="large"
                isRequired={true}
                forceValidation={validationTriggered}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="login-password">
              <PasswordBox
                size="large"
                isRequired={true}
                title="Password"
                loginBox={true}
                forceValidation={validationTriggered}
                onPasswordChange={(passwordValue) => setPassword(passwordValue)}
              />
            </div>
            <a href="/forgotPassword" className="forgot-password"><p>Forgot password</p></a>
            <div className="login-button">
              <Button
                label={isLoggingIn ? "Logging In" : "Login"}
                type="submit"
                variant={isLoggingIn ? "submit" : "primary"}
                withIcon={false}
                size="large"
              />
            </div>
          </form>
          <div className="quick-login-section">
            <hr className="quick-login-line" />
            <div className="quick-login-text"><h4>Quick Login</h4></div>
            <div className="google-login">
              <Button
                type="submit"
                withIcon={true}
                icon={GoogleIcon}
                variant="secondary"
                label="Login with Google"
                handleClick={handleGoogleLogin}
                size="large"
                className="google-btn"
              />
            </div>
          </div>
        </div>
        <Modal
          title={modalTitle}
          message={modalMessage}
          handleClose={handleModalClose}
          show={showModal}
          actions={modalActions}
        />
      </div>
    </VisitorWrapper>
  );
};

export default LoginAdmin;
