import React from 'react';
import './InputContainer.css';
import '../../index.css';

const InputContainer = ({ 
  title, 
  error, 
  children, 
  size,
  ...props 
}) => {
  let className = `input-container ${size}`; 
  if (error) {
    className += ' error';
  }

  return (
    <div className={className}>
      {title && <label className='input-label'>{title}</label>}
      <div className="input-icon-container">
        {children}
      </div>
      {error && <div className='error-text text-h6'>{error}</div>}
    </div>
  );
};

export default InputContainer;
