import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';

const UserWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { userType } = useContext(UserContext);
  const token = localStorage.getItem('token');
  useEffect(() => {

    if (!token || userType !== 'user') {
      navigate('/login');
    }
  }, [userType, navigate, token]);

  return (token && userType === 'user') ? children : null;
};

export default UserWrapper;
