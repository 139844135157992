import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import axios from 'axios';
import InputBox from '../InputBox/InputBox';
import Button from '../button/Button';
import './ShareModal.css';
import CloseIcon from '../../assets/icons/close.svg';
import Delete from '../../assets/icons/delete.svg';
import addIcon from '../../assets/icons/add.svg';

const SharePlanModal = ({ planId, isOpen, onClose, currentUser, planDetails }) => {
  const [emailInput, setEmailInput] = useState('');
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => setEmailInput(e.target.value);

  const handleAddMore = () => {
    if (emails.length < 3 && emailInput && !emails.includes(emailInput)) {
      setEmails([...emails, emailInput]);
      setEmailInput('');
    }
  };

  const handleDeleteEmail = (index) => {
    setEmails(prev => prev.filter((_, i) => i !== index));
  };

  const createEmailContent = (email, planId, planSlug, planTitle, planDescription) => {
    const senderName = currentUser.userName || currentUser.firstName || 'A user';
    const emailStyle = { fontSize: '16px', color: '#333448', fontFamily: "'Inter', 'Roboto', sans-serif" };

    // Construct the URL to accept the shared plan
    const planUrl = `https://www.rebrid.co/planner/${planSlug}`;
    
    // Handle potential undefined or non-string planDescription
    const safePlanDescription = typeof planDescription === 'string' ? planDescription : '';
    const truncatedDescription = safePlanDescription.length > 100 ? `${safePlanDescription.substring(0, 100)}...` : safePlanDescription;

    return ReactDOMServer.renderToString(
      <div className='share-email' style={{ backgroundColor: '#f5f5f5', padding: '48px', boxSizing: 'border-box' }}>
        <div className='share-email-content' style={{ backgroundColor: 'white', borderRadius: '16px', padding: '32px', margin: 'auto', width: '80%' }}>
          <h1 style={{ fontSize: '32px', textAlign: 'center', color: '#333448', fontWeight: '800', fontFamily: "'Inter', 'Roboto', sans-serif" }}>
            Check out this Plan on Rebrid
          </h1>
          <p style={emailStyle}>Hi there,</p>
          <p style={emailStyle}>
            {senderName} invited you to check out a plan on Rebrid! It's called <strong> {planTitle}</strong>.</p>
            <p style={emailStyle}>
          You can view and accept the plan by clicking the link below:
          <a href={planUrl} style={{ color: '#1a73e8', textDecoration: 'none' }}>View Plan</a>
        </p>
<p style={emailStyle}>Cheers, {senderName}</p>
<footer>
<p style={{ fontSize: '14px', color: '#646A76', fontFamily: "'Inter', 'Roboto', sans-serif" }}>
Visit <a href="https://www.rebrid.co" style={{ color: '#FF357E', textDecoration: 'none' }}>Rebrid.co</a> to explore more interesting plans.
</p>
</footer>
</div>
</div>
);
};

const handleShare = async (e) => {
e.preventDefault();
setIsLoading(true);
try {
  const emailContents = emails.map(email => 
    createEmailContent(email, planId, planDetails.slug, planDetails.name, planDetails.description)
  );
    await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/share-plan`, {
      emails,  // Send only email addresses
      emailContents,
      planId
    });
    setEmails([]);
    setIsLoading(false);
    
// Additional UI feedback can be added here
} catch (error) {
console.error("Error sharing plan:", error);
setIsLoading(false);
// Additional error handling can be added here
}
};

return ReactDOM.createPortal(
<div className={`ShareModal ${isOpen ? 'open' : ''}`}>
<div className="ShareModal-content">
<img src={CloseIcon} alt="Close" className="close-icon" onClick={onClose} />
<h2>Share Plan</h2>
<form onSubmit={handleShare}>
<div className="email-input-container">
<InputBox
           title="Email"
           helpText="Friend's email"
           type="email"
           size="large"
           value={emailInput}
           onChange={handleEmailChange}
         />
<Button variant='tertiary' withIcon={true} icon={addIcon} label='Add' className="add-button" type="button" handleClick={handleAddMore} />
</div>
<div className='email-to-share'>
<div className="email-list">
{emails.map((email, index) => (
<div className='key-index' key={index}>
<div className="email-tag">
{email}
<img className='delete-button' src={Delete} alt="delete-icon" onClick={() => handleDeleteEmail(index)} />
</div>
{index < emails.length - 1 && <hr />}
</div>
))}
</div>
</div>
<div className="modal-buttons">
<Button label="Cancel" variant="secondary" handleClick={onClose} size='medium' />
<Button label={isLoading ? "Sharing..." : "Share"} variant="primary" size='medium' type="submit" />
</div>
</form>
</div>
</div>,
document.body
);
};

export default SharePlanModal;