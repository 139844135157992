import React, { useState, useRef, useEffect } from 'react';
import ActivityList from '../tags/activityType.json';
import './CategoryCapsules.css';
import Previous from '../../assets/icons/left-active.svg';
import Next from '../../assets/icons/right-active.svg';
import PreviousDisabled from '../../assets/icons/left-disabled.svg';
import NextDisabled from '../../assets/icons/right-disabled.svg';

const CategoryCapsules = ({ onCategorySelect }) => {
const [isAtStart, setIsAtStart] = useState(true);
const [isAtEnd, setIsAtEnd] = useState(false);
const capsuleRef = useRef(null);
const [selectedCategory, setSelectedCategory] = useState(null);

const checkScrollPosition = () => {
if (capsuleRef.current) {
const { scrollLeft, scrollWidth, clientWidth } = capsuleRef.current;
setIsAtStart(scrollLeft === 0);
setIsAtEnd(scrollLeft >= scrollWidth - clientWidth);
}
};

const handleCategorySelect = (activityType) => {
setSelectedCategory(activityType.value);
onCategorySelect(activityType);
};

const scrollCapsules = (direction) => {
if (capsuleRef.current) {
const scrollAmount = capsuleRef.current.offsetWidth / 2;
const newScrollPosition = direction === 'next' ? 
capsuleRef.current.scrollLeft + scrollAmount : 
capsuleRef.current.scrollLeft - scrollAmount;

capsuleRef.current.scrollTo({
left: newScrollPosition,
behavior: 'smooth'
});
}
};

useEffect(() => {
checkScrollPosition();
const capsuleContainer = capsuleRef.current;
if (capsuleContainer) {
capsuleContainer.addEventListener('scroll', checkScrollPosition);
}
return () => {
if (capsuleContainer) {
capsuleContainer.removeEventListener('scroll', checkScrollPosition);
}
};
}, []);

return (
<div className="category-capsules-wrapper">
<img 
src={isAtStart ? PreviousDisabled : Previous} 
alt="Previous" 
onClick={() => !isAtStart && scrollCapsules('prev')}
className={'scroll-button' + (isAtStart ? ' disabled' : '')}
/>
<div className="category-capsules" ref={capsuleRef}>
{ActivityList.map((activityType) => (
<button 
key={activityType.value} 
onClick={() => handleCategorySelect(activityType)}
className={selectedCategory === activityType.value ? 'selected' : ''}
>
{activityType.label}
</button>
))}
</div>
<img 
src={isAtEnd ? NextDisabled : Next} 
alt="Next" 
onClick={() => !isAtEnd && scrollCapsules('next')}
className={'scroll-button' + (isAtEnd ? ' disabled' : '')}
/>
{!isAtStart && <div className="category-capsules-gradient-left"></div>}
{!isAtEnd && <div className="category-capsules-gradient-right"></div>}
</div>
);
};

export default CategoryCapsules;
