import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import Registration from './pages/Register';
import TnC from './pages/TnC';
import './App.css';
import Login from './pages/Login';
import ContactUs from './pages/ContactUs';
import NewEditBlog from './pages/NewEditBlog';
import Blog from './pages/Blog'; 
import Blogs from './pages/Blogs';
import Activity from './pages/Activity';
import AdminDashboard from './pages/AdminDashboard';
import Discover from './pages/Discover';
import Error404 from './pages/Error404';
import Homepage from './pages/Homepage';
import ManageActivities from './pages/ManageActivities';
import ManageAdmin from './pages/ManageAdmin';
import ManagePromotions from './pages/ManagePromotions';
import ManageBlogs from './pages/ManageBlogs';
import Location from './pages/Location';
import Category from './pages/Category';
import CategoryActivity from './pages/CategoryActivity';
import CountryCities from './pages/CountryCities';
import NewEditActivity from './pages/NewEditActivity';
import Planner from './pages/Planner';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SetNewPassword from './pages/SetNewPassword';
import Stats from './pages/Stats';
import UserDashboard from './pages/UserDashboard';
import MyBucketlist from './pages/MyBucketlist';
import UserContext from './components/UserContext';
import Navbar from './components/navbar/Navbar';
import { MyBucketlistProvider } from './components/MyBucketlistContext';
import SeeAll from './pages/SeeAll';
import UserProfile from './pages/UserProfile';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Bowser from 'bowser';
import AuthCallback from './components/AuthCallback';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';
import useTracker from './hooks/useTracker';
import SearchResult from './pages/SearchResult';
import SiteMap from './pages/SiteMap';
import Footer from './components/footer/Footer';
import ScrollToTop from './components/utils/ScrollToTop';
import LoginAdmin from './pages/LoginAdmin';
import CreateEditPlan from './pages/CreateEditPlan';

const TRACKING_ID = "G-CVTZK1BW7N";
const isAdminSubdomain = window.location.hostname.startsWith("admin.");
const isProviderSubdomain = window.location.hostname.startsWith("provider.");


function PageViewTracker() {
const location = useLocation();

useEffect(() => {
ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
}, [location]);

return null;
}

function App() {
const [userType, setUserType] = useState(null);
const [loading, setLoading] = useState(true);
const [user, setUser] = useState(null);

useEffect(() => {
const loggedInUserType = localStorage.getItem('userType');
const token = localStorage.getItem('jwtToken');
const userTypeDetected = token ? 'user' : 'visitor';

if (loggedInUserType) {
setUserType(loggedInUserType);
} else {
setUserType(userTypeDetected);
}

const loggedInUser = localStorage.getItem('user');
if (loggedInUser) {
const foundUser = JSON.parse(loggedInUser);
setUser(foundUser);
}

setLoading(false);
}, []);

useEffect(() => {
const handleBackButton = (event) => {
window.location.reload();
};

window.addEventListener('popstate', handleBackButton);

return () => {
window.removeEventListener('popstate', handleBackButton);
};
}, []);

useEffect(() => {
const browserInfo = navigator.userAgent;
const location = window.location.pathname;
const { width, height } = window.screen;
const language = navigator.language;
const token = localStorage.getItem('jwtToken');
const userType = token ? 'user' : 'visitor';

const browser = Bowser.getParser(window.navigator.userAgent);
const deviceInformation = {
browserName: browser.getBrowserName(),
browserVersion: browser.getBrowserVersion(),
os: browser.getOS(),
engine: browser.getEngine(),
deviceType: browser.getPlatformType(),
};

const startTime = new Date();

const handleBeforeUnload = () => {
const endTime = new Date();
const sessionDuration = endTime - startTime;
axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/userAnalytics/session`, { sessionDuration, token });
};

window.addEventListener('beforeunload', handleBeforeUnload);

const data = {
browserInfo,
location,
screenDimensions: { width, height },
language,
userType,
date: new Date(),
deviceInformation,
};

axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/userAnalytics`, data)
.catch(error => {
console.error("Error posting user analytics:", error);
});

return () => {
window.removeEventListener('beforeunload', handleBeforeUnload);
};
}, []);

useTracker(userType);

if (loading) {
return null;
}


const renderAdminRoutes = () => (
    <>
      <Route path="/" element={<AdminDashboard />} />
      <Route path="/login" element={<LoginAdmin />} />
      <Route path="/AdminDashboard" element={<AdminDashboard />} />
      <Route path="/manageAdmin" element={<ManageAdmin />} />
      <Route path="/createBlog" element={<NewEditBlog />} />
      <Route path="/editblog/:id" element={<NewEditBlog isEdit={true} />} />
      <Route path="/manageActivities" element={<ManageActivities />} />
      <Route path="/managePromotions" element={<ManagePromotions />} />
      <Route path="/createActivity" element={<NewEditActivity />} />
      <Route path="/editActivity/:id" element={<NewEditActivity isEdit={true} />} />
      <Route path="/manageblogs" element={<ManageBlogs />} />
      <Route path="*" element={<Navigate to="/" />} />
    </>
  );

const renderProviderRoutes = () => (
    <>
      <Route path="*" element={<Navigate to="/" />} />
    </>
  );

const renderMainRoutes = () => (
    <>
<Route path="/" element={<Homepage/>} />
<Route path="/register" element={<Registration />} />
<Route path="/login" element={<Login />} />
<Route path="/contactus" element={<ContactUs />} />
<Route path="/tnc" element={<TnC />} />
<Route path="/blogs/:id" element={<Blog />} />
<Route path="/blogs" element={<Blogs />} />
<Route path="/activity/:id" element={<Activity />} />
<Route path="/discover" element={<Discover />} />
<Route path="/planner" element={<Planner />} />
<Route path="/planner/:slug" element={<CreateEditPlan />} />
<Route path="/privacyPolicy" element={<PrivacyPolicy />} />
<Route path="/changePassword" element={<SetNewPassword />} />
<Route path="/stats" element={<Stats />} />
<Route path="/userDashboard" element={<UserDashboard />} />
<Route path="/MyBucketlist" element={<MyBucketlist />} />
<Route path="see-all" element={<SeeAll />} />
<Route path='/userprofile' element={<UserProfile />} />
<Route path='/forgotPassword' element={<ForgotPassword />} />
<Route path="/reset-password/:token" element={<ResetPassword/>} />
<Route path="/auth/callback" element={<AuthCallback />} />
<Route path="/category" element={<Category />} />
<Route path="/category/:keyword" element={<CategoryActivity />} />
<Route path="/location" element={<Location />} />
<Route path="/location/:countryName" element={<CountryCities />} />
<Route path="/searchResult/:searchTerm" element={<SearchResult />} />
<Route path="/searchResult/" element={<SearchResult />} />
<Route path="/rebridSiteMap/" element={<SiteMap />} />
<Route path="*" element={<Error404 />} />
    </>
  );

return (
<MyBucketlistProvider>
<UserContext.Provider value={{ userType, setUserType, user, setUser }}>
<>
<Router basename={process.env.PUBLIC_URL}>
<ScrollToTop />
<PageViewTracker />
<div className='navbar'>
<Navbar/>
</div>
<Routes>
            {isAdminSubdomain && renderAdminRoutes()}
            {isProviderSubdomain && renderProviderRoutes()}
            {!isAdminSubdomain && !isProviderSubdomain && renderMainRoutes()}
</Routes>
<div className='navbar'>
<Footer/>
</div>
</Router>
</>
<div id="modal-root"></div>
</UserContext.Provider>
</MyBucketlistProvider>
);
}
export default App;