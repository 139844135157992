import React from 'react';
import './Modal.css';
import Button from '../button/Button';

const Modal = ({ title, message, handleClose, show, actions }) => {
if (!show) {
return null;
}

return (
<div className="modal display-block">
<section className="modal-main">
<h2>{title}</h2>
<div>{message}</div>
<div className="modal-button-container">
{actions.map((action, index) => (
<Button
key={index}
label={action.label}
type="button"
variant={action.variant}
handleClick={action.onClick}
disabled={false}
withIcon={false}
size="medium"
/>
))}
</div>
</section>
</div>
);
};

export default Modal;
