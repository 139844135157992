import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './BlogSection.css';
import BlogCard from '../blogcard/BlogCard';
import Button from '../button/Button';

const BlogSection = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/blogs?random=true&limit=5`);
                setBlogs(response.data.data);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="blog-section">
            <div className="blog-section-header">
                <h2>Blogs You Might Be Interested In</h2>
                <Button label='See All' variant="secondary" size="small" to="/Blogs" />
            </div>
            <div className="blog-cards">
                {blogs.map(blog => (
                    <BlogCard key={blog._id} blog={blog} />
                ))}
            </div>
        </div>
    );
};

export default BlogSection;
