import React, { useState, useRef,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './ActivityCard.css';
import pinIcon from '../../assets/icons/location-pin.svg';
import durationIcon from '../../assets/icons/timer-color-outline.svg';
import ShareButton from '../sharebutton/ShareButton';
import MyBucketlistButton from '../myBucketlistbutton/MyBucketlistButton';
import ReviewsAndRatings from '../reviewsAndRatings/ReviewsAndRatings';
import ActivityIcon from '../../assets/icons/activityIcon.svg';
import PlaceIcon from '../../assets/icons/placeIcon.svg';
import PlanModal from '../Planner/PlanModal';
import UserContext from '../UserContext';

const ActivityCard = ({ activity, token }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const modalButtonRef = useRef(null);
  const { userType } = useContext(UserContext);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    if (!userType) {
      return navigate('/login');
    } 
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

if (!activity || activity.isDisabled) {
return null;
}

const { _id, title, headerImage, promotion, city, country, duration, selectedCurrency, price, averageRating, totalReviews, activityOrPlace, isActivityFree } = activity;
const truncatedTitle = title.length > 32 ? `${title.substr(0, 32)}...` : title;

const handleCardClick = () => {
navigate(`/activity/${_id}`);
};

const stopPropagation = (e) => {
e.stopPropagation();
};

// Function to determine price display
const getPriceDisplay = () => {
if (isActivityFree) {
return 'Free';
} else if (Array.isArray(price)) {
if (price.length === 1) {
return `${selectedCurrency} ${price[0].amount}`;
} else if (price.length > 1) {
const lowestPrice = price.reduce((min, p) => (p.amount < min ? p.amount : min), price[0].amount);
return <p style={{ fontSize: '0.8rem', color: 'var(--CTA-color)' }}>Starting from {selectedCurrency} {lowestPrice}</p>;
}
}

return 'Unknown';
};


return (
<div className="ActivityCard" onClick={handleCardClick}>
{promotion?.isSpotlighted && (
<div className='spotlighted-badge-container'>
<div className="spotlighted-badge">
<p>Spotlighted</p>
</div>
</div>
)}

<div className='header-section'>
<img className="activity-card-header-image" src={headerImage} alt={title} />
<div className="header-icons" onClick={stopPropagation}>
<ShareButton variant="share" label="Share" activityId={_id} />
</div>
</div>
<div className='activity-card-content'>
<div className="type-area">
{activityOrPlace === 'activity' ? (
<div className="activity-type">
<img src={ActivityIcon} alt="Activity Icon" />
<p>Activity</p>
</div>
) : (
<div className="place-type">
<img src={PlaceIcon} alt="Place Icon" />
<p>Place</p>
</div>
)}
</div>

<div className="heading-area">
<h3 className="title-card">{truncatedTitle}</h3>
<ReviewsAndRatings variant="card" activityId={_id} averageRating={averageRating || 0} totalReviews={totalReviews || 0} />
</div>

<div className="content">
<div className="location-pin">
<img src={pinIcon} alt="Location"/>
{city}, {country}
</div>

<div className="duration-icon-text">
<img src={durationIcon} alt="duration"/>
{duration}
</div>
</div>

<div className="amount" onClick={stopPropagation}>
          {getPriceDisplay()}
        </div>

        <div className='card-action-buttons'>
        <button className='add-to-plan' ref={modalButtonRef} onClick={handleOpenModal}>Add to Plan</button>
          <div className='mybucketlist-button-activityCard'>
          <MyBucketlistButton activityId={activity._id} userType={userType} token={token} size='medium' onClick={stopPropagation}/>
        </div>
        </div>
      <PlanModal 
        activityId={activity._id} 
        isModalOpen={isModalOpen} 
        setIsModalOpen={setIsModalOpen} 
        buttonRef={modalButtonRef}
      />
    </div>
    </div>
  );
};

export default ActivityCard;