import React from 'react';
import './Features.css';
import Button from '../button/Button';
import Image1 from '../../assets/icons/unique.svg';
import Image2 from '../../assets/icons/bucketlist.svg';
import Image3 from '../../assets/icons/plan.svg';

const Features = () => {
    return (
        <div className="features-container">
            <h1>Helping You Build Memories</h1>
            <p>Experience the best leisure activities curated specially for you. Dive into a world of unique opportunities, personal growth, and unforgettable memories.</p>

            <div className='feature-cards'>
                <div className="feature-card">
                    <div className="feature-image">
                        <img src={Image1} alt="Unique Activities" />
                    </div>
                    <div className="features-text">
                        <h2>Wide variety of unique Activities</h2>
                        <p>Discover an array of activities that stand out. From mountain climbing to pottery, there's something for everyone.</p>
                    </div>
                </div>

                <div className="feature-card">
                    <div className="feature-image">
                        <img src={Image2} alt="Bucketlist" />
                    </div>
                    <div className="features-text">
                        <h2>Create your bucketlist</h2>
                        <p>Choose your favorites, make a wish list, track your progress, and share with friends.</p>
                    </div>
                </div>

                <div className="feature-card">
                    <div className="feature-image">
                        <img src={Image3} alt="Planning" />
                    </div>
                    <div className="features-text">
                        <h2>Create plans with loved ones</h2>
                        <p>Coordinate to create memorable experiences. Plan trips, outings, or simple get-togethers.</p>
                    </div>
                </div>

            </div>
            <div className="feature-buttons">
                <Button variant="secondary" label="Discover Activities" to='/discover' />
                <Button variant="primary" label="Register with us" to='/register' />
            </div>
        </div>
    );
};

export default Features;
