import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";
import axios from "axios";
import ActivityCard from "../components/activityCard/ActivityCard";
import FeaturedPromotion from "../components/promotions/FeaturedPromotion";
import Dropdown from "../components/dropdown/Dropdown";
import CategoryCapsules from "../components/categoryCapsules/CategoryCapsules";
import Button from "../components/button/Button";
import FilterIcon from "../assets/icons/filter-black.svg";
import FilterIconActive from "../assets/icons/filter-active.svg";
import Category from "../components/tags/subCategory.json";
import Pagination from "../components/pagination/Pagination";
import SubCategory from "../components/tags/subCategory.json";
import Accessibility from "../components/tags/accessibilityOptions.json";
import Country from "../components/tags/country.json";
import Season from "../components/tags/seasonOptions.json";
import Mood from "../components/tags/moodOptions.json";
import AgeLimit from "../components/tags/ageLimitOptions.json";
import FitnessLevel from "../components/tags/fitnessLevelOptions.json";
import "./SearchResult.css";

const SearchResults = () => {
const [activities, setActivities] = useState([]);
const [currentPage, setCurrentPage] = useState(0);
const itemsPerPage = 20;
const [totalItems, setTotalItems] = useState(0);
const [searchTerm, setSearchTerm] = useState("");
const [isLoading, setIsLoading] = useState(false);
const [loadingProgress, setLoadingProgress] = useState(0);
const [filters, setFilters] = useState({
activityType: null,
accessibility: null,
country: null,
season: null,
mood: null,
ageLimit: null,
fitnessLevel: null,
category: null,
});

const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const [showFilters, setShowFilters] = useState(false);
const location = useLocation();

const toggleFilters = () => {
setShowFilters(prevShowFilters => !prevShowFilters);
};

const renderActivitiesWithPromotions = () => {
    const elements = [];
    activities.forEach((activity, index) => {
    elements.push(<ActivityCard key={activity._id} activity={activity} />);
    
    if ((index + 1) % 8 === 0) {
    // Wrap FeaturedPromotion in a div with a class to control its styling
    elements.push(
    <div className="featured-promotion-class" key={`promo-${index}`}>
    <FeaturedPromotion />
    </div>
    );
    }
    });
    return elements;
    };

useEffect(() => {
const query = new URLSearchParams(location.search);
const searchKeyword = query.get("search");
setSearchTerm(searchKeyword);
}, [location]);

useEffect(() => {
const handleResize = () => {
setWindowWidth(window.innerWidth);
};

window.addEventListener('resize', handleResize);
return () => window.removeEventListener('resize', handleResize);
}, []);


const handleFilterChange = (name, selectedOption) => {
setFilters(prev => {
// If the filter should be an array, join it into a comma-separated string
const updatedFilter = Array.isArray(selectedOption)
? selectedOption.map(option => option.value).join(',')
: selectedOption ? selectedOption.value : null;

return {
...prev,
[name]: updatedFilter,
};
});
setCurrentPage(0);
};

const fetchActivities = async () => {
    if (!searchTerm) return;

    setIsLoading(true);
    let interval = setInterval(() => {
        setLoadingProgress((prevProgress) => Math.min(prevProgress + Math.floor(Math.random() * 10), 95));
    }, 500);

    try {
        const filterParams = Object.entries(filters).reduce((params, [key, value]) => {
            if (value) {
                params[key] = value;
            }
            return params;
        }, {});

        const response = await axios.get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/search-activities`, {
                params: {
                    searchTerm: searchTerm.replace(/_/g, ' '),
                    ...filterParams,
                    skip: currentPage * itemsPerPage,
                    limit: itemsPerPage,
                },
            }
        );
        setActivities(response.data.data);
        setTotalItems(response.data.totalItems);
    } catch (error) {
        console.error("Error fetching activities:", error);
    } finally {
        clearInterval(interval);
        setLoadingProgress(100);
        setIsLoading(false);
    }
};

useEffect(() => {
    fetchActivities();
}, [searchTerm, filters, currentPage]);

const handlePageChange = (newPage) => {
setCurrentPage(newPage);
};

const renderFilterDropdowns = () => {
return (
<>
<Dropdown
size="small"
title="Sub-Category"
options={Category}
value={filters.subCategory !== null ? { value: filters.subCategory, label: Category.find(option => option.value === filters.subCategory)?.label } : null}
onChange={(option) => handleFilterChange("subCategory", option)}
/>
<Dropdown
size="small"
title="Accessibility"
options={Accessibility}
value={filters.accessibility !== null ? { value: filters.accessibility, label: Accessibility.find(option => option.value === filters.accessibility)?.label } : null}
onChange={(option) => handleFilterChange("accessibility", option)}
/>
<Dropdown
size="small"
title="Country"
options={Country}
value={filters.country !== null ? { value: filters.country, label: Country.find(option => option.value === filters.country)?.label } : null}
onChange={(option) => handleFilterChange("country", option)}
/>
<Dropdown
size="small"
title="Season"
options={Season}
value={filters.idealSeason !== null ? { value: filters.idealSeason, label: Season.find(option => option.value === filters.idealSeason)?.label } : null}
  onChange={(option) => handleFilterChange("idealSeason", option)}
/>
<Dropdown
size="small"
title="Mood"
options={Mood}
value={filters.mood !== null ? { value: filters.mood, label: Mood.find(option => option.value === filters.mood)?.label } : null}
onChange={(option) => handleFilterChange("mood", option)}
/>
<Dropdown
size="small"
title="Age Limit"
options={AgeLimit}
value={filters.ageLimit !== null ? { value: filters.ageLimit, label: AgeLimit.find(option => option.value === filters.ageLimit)?.label } : null}
onChange={(option) => handleFilterChange("ageLimit", option)}
/>
<Dropdown
size="small"
title="Fitness Level"
options={FitnessLevel}
value={filters.fitnessLevel !== null ? { value: filters.fitnessLevel, label: FitnessLevel.find(option => option.value === filters.fitnessLevel)?.label } : null}
onChange={(option) => handleFilterChange("fitnessLevel", option)}
/>
</>
);
};

return (
<div className="search-result-container">
<Helmet>
        <link rel="canonical" href={`https://www.rebrid.co/searchResults/${searchTerm}`} />
      </Helmet>
<h1>Search Results for {searchTerm}</h1>
<div className="category-line">
<CategoryCapsules onCategorySelect={(selectedCategory) => handleFilterChange('activityType', selectedCategory)} />
<Button label={windowWidth < 500 ? '' : 'Filter'} variant={showFilters ? 'active-filter' : 'tertiary'} handleClick={toggleFilters} icon={showFilters ? FilterIconActive : FilterIcon} size="small" withIcon className={showFilters ? 'filter-button-active' : ''} />
</div>
{showFilters && (
<div className="filters-container">{renderFilterDropdowns()}</div>
)}
<div className="filtered-section">
{isLoading ? (
<div className="loading-container">
<div className="loading-line-bg"></div>
<div className="loading-line" style={{ width: `${loadingProgress}%` }}></div>
<p className="loading-text">Loading activities... {loadingProgress}%</p>
</div>
) : activities.length > 0 ? (
<div className="activity-cards">
<div className="filtered-cards">
{renderActivitiesWithPromotions()}
</div>
</div>
) : (
<div className="no-activities-message">
<p>
Seems like we do not have any activity with the search word '
{searchTerm}'. Try searching for its synonym or a new word. You
could also manually search by <a href="/category">category</a> or
<a href="/location"> my location</a>.
</p>
</div>
)}
{totalItems > itemsPerPage && (
<div className="pagination">
<Pagination totalItems={totalItems} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={handlePageChange} />
</div>
)}
</div>
</div>
);
};

export default SearchResults;