import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useNavigate, useLocation  } from 'react-router-dom';
import './Blogs.css';
import BlogCard from '../components/blogcard/BlogCard';
import InputBox from '../components/InputBox/InputBox';
import Pagination from '../components/pagination/Pagination';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 12;
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const location = useLocation(); 

const fetchBlogs = useCallback(async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/blogs`, {
      params: {
        searchTerm,
        limit: itemsPerPage,
        skip: itemsPerPage * currentPage,
      },
    });
    setBlogs(response.data.data || []);
    setTotalItems(response.data.totalItems);
  } catch (error) {
    console.error('Error fetching blog data:', error);
    setBlogs([]);
  }
}, [searchTerm, currentPage, itemsPerPage]);

const handlePageChange = (newPage) => {
setCurrentPage(newPage);
};

useEffect(() => {
  const params = new URLSearchParams(location.search);
  const query = params.get('search');
  if (query !== null && query !== searchTerm) {
    setSearchTerm(query);
  }
  fetchBlogs();
}, [location.search, fetchBlogs]);

const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
  setCurrentPage(0); // Reset to the first page when search term changes
  navigate(`/blogs?search=${e.target.value}`);
};

return (
<div className="Blogs">
<Helmet>
<link rel="canonical" href="https://www.rebrid.co/blogs" />
</Helmet>
<div className="blog-cards-container">
<div className='title-blogs'>
<h1 className="title">All Blogs</h1>
<InputBox
title="Search"
helpText="Search blogs..."
onChange={handleSearchChange}
value={searchTerm}
isSearch={true}
size="large"
/>
</div>
<div className='all-blogcards'>
{blogs.map((blog) => (
            <BlogCard key={blog._id} blog={blog} />
          ))}
</div>
{totalItems > itemsPerPage && (
            <div className='pagination'>
              <Pagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          )}
</div>
</div>
);
};

export default Blogs;
