import React, { useState, useEffect } from "react";
import AdminWrapper from "../components/adminwrapper/AdminWrapper";
import InputBox from "../components/InputBox/InputBox";
import "./ManagePromotions.css";
import Button from "../components/button/Button";
import { Link } from "react-router-dom";

const fetchPromotions = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/promotion`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch promotions");
    }
    const data = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching promotions:", err);
    return [];
  }
};

const ManagePromotions = () => {
  const [promotions, setPromotions] = useState([]);
  const [filters, setFilters] = useState([
    "Premium-Active",
    "Featured-Active",
    "Premium-Inactive",
    "Featured-Inactive",
  ]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const allPromotions = await fetchPromotions();
      setPromotions(allPromotions);
    };
    fetchData();
  }, []);

  const toggleFilter = (filterType) => {
    if (filters.includes(filterType)) {
      setFilters((prevFilters) => prevFilters.filter((f) => f !== filterType));
    } else {
      setFilters((prevFilters) => [...prevFilters, filterType]);
    }
  };

  const promotionMatchesFilter = (promotion) => {
    const premiumActiveMatch =
      promotion.type === "Premium" &&
      promotion.condition === "Active" &&
      filters.includes("Premium-Active");
    const featuredActiveMatch =
      promotion.type === "Featured" &&
      promotion.condition === "Active" &&
      filters.includes("Featured-Active");
    const premiumInactiveMatch =
      promotion.type === "Premium" &&
      promotion.condition !== "Active" &&
      filters.includes("Premium-Inactive");
    const featuredInactiveMatch =
      promotion.type === "Featured" &&
      promotion.condition !== "Active" &&
      filters.includes("Featured-Inactive");

    return (
      premiumActiveMatch ||
      featuredActiveMatch ||
      premiumInactiveMatch ||
      featuredInactiveMatch
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredPromotions = promotions.filter((promotion) => {
    const titleMatch = promotion.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    return titleMatch && promotionMatchesFilter(promotion);
  });

  return (
    <AdminWrapper>
      <div className="manage-promotions-container">
        <div className="promotions-card">
          <div className="head-title">
            <h1>Manage Promotions</h1>
            <Button
              label="Create New Promotion"
              to="/Createpromotions"
              type="button"
              variant="primary"
            />
          </div>

          {/* Filters */}
          <div className="promotion-filters">
            <label>
              <input
                type="checkbox"
                checked={filters.includes("Premium-Active")}
                onChange={() => toggleFilter("Premium-Active")}
              />
              Premium-Active (
              {
                promotions.filter(
                  (promotion) =>
                    promotion.type === "Premium" &&
                    promotion.condition === "Active"
                ).length
              }
              )
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.includes("Featured-Active")}
                onChange={() => toggleFilter("Featured-Active")}
              />
              Featured-Active (
              {
                promotions.filter(
                  (promotion) =>
                    promotion.type === "Featured" &&
                    promotion.condition === "Active"
                ).length
              }
              )
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.includes("Premium-Inactive")}
                onChange={() => toggleFilter("Premium-Inactive")}
              />
              Premium-Inactive (
              {
                promotions.filter(
                  (promotion) =>
                    promotion.type === "Premium" &&
                    promotion.condition === "Inactive"
                ).length
              }
              )
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.includes("Featured-Inactive")}
                onChange={() => toggleFilter("Featured-Inactive")}
              />
              Featured-Inactive (
              {
                promotions.filter(
                  (promotion) =>
                    promotion.type === "Featured" &&
                    promotion.condition === "Inactive"
                ).length
              }
              )
            </label>
            <InputBox
              title="Search"
              helpText="Search promotions..."
              onChange={handleSearchChange}
              value={searchTerm}
              isSearch={true}
              size="medium"
            />
          </div>
          <hr className="divider" />

          {/* Featured-Active Promotions */}
          {filters.includes("Featured-Active") && (
            <>
              <h2>Featured-Active</h2>
              <div className="promotion-cards">
                {filteredPromotions
                  .filter(
                    (promotion) =>
                      promotion.type === "Featured" &&
                      promotion.condition === "Active"
                  )
                  .map((promotion) => renderPromotionCard(promotion))}
              </div>
              <hr className="divider" />
            </>
          )}

          {/* Premium-Active Promotions */}
          {filters.includes("Premium-Active") && (
            <>
              <h2>Premium-Active</h2>
              <div className="promotion-cards">
                {filteredPromotions
                  .filter(
                    (promotion) =>
                      promotion.type === "Premium" &&
                      promotion.condition === "Active"
                  )
                  .map((promotion) => renderPromotionCard(promotion))}
              </div>
              <hr className="divider" />
            </>
          )}

          {/* Premium-Inactive Promotions */}
          {filters.includes("Premium-Inactive") && (
            <>
              <h2>Premium-Inactive</h2>
              <div className="promotion-cards">
                {filteredPromotions
                  .filter(
                    (promotion) =>
                      promotion.type === "Premium" &&
                      promotion.condition === "Inactive"
                  )
                  .map((promotion) => renderPromotionCard(promotion))}
              </div>
              <hr className="divider" />
            </>
          )}

          {/* Featured-Inactive Promotions */}
          {filters.includes("Featured-Inactive") && (
            <>
              <h2>Featured-Inactive</h2>
              <div className="promotion-cards">
                {filteredPromotions
                  .filter(
                    (promotion) =>
                      promotion.type === "Featured" &&
                      promotion.condition === "Inactive"
                  )
                  .map((promotion) => renderPromotionCard(promotion))}
              </div>
              <hr className="divider" />
            </>
          )}
        </div>
      </div>
    </AdminWrapper>
  );
};

function renderPromotionCard(promotion) {
  return (
    <Link key={promotion._id} to={`/edit/promotion/${promotion._id}`}>
      <div
        className={`promotion-card ${
          promotion.condition === "Inactive" ? "inactive" : ""
        } ${promotion.type.toLowerCase()}`}
      >
        <div className="promotion-image">
          <img src={promotion.imageURL} alt={promotion.title} />
        </div>
        <div className="promotion-content">
          <h4>{promotion.title}</h4>
          <span className="promotion-type">{promotion.type}</span>
        </div>
      </div>
    </Link>
  );
}

export default ManagePromotions;
