import React, { useState, useEffect, useRef,useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputBox from '../components/InputBox/InputBox';
import UploadBox from '../components/uploadbox/UploadBox';
import Button from '../components/button/Button';
import DescriptionBox from '../components/descriptionbox/DescriptionBox';
import ImagesBox from '../components/imagesbox/ImagesBox';
import './NewEditActivity.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminWrapper from '../components/adminwrapper/AdminWrapper';
import activityTypeKeywords from '../components/tags/activityType.json';
import MultiDropdown from '../components/dropdown/MultiDropdown';
import seasonOptions from '../components/tags/seasonOptions.json';
import moodOptions from '../components/tags/moodOptions.json';
import accessibilityOptions from '../components/tags/accessibilityOptions.json';
import ageLimitOptions from '../components/tags/ageLimitOptions.json';
import capacityOptions from '../components/tags/capacityOptions.json';
import fitnessLevelOptions from '../components/tags/fitnessLevelOptions.json';
import subCategoryOptions from '../components/tags/subCategory.json';
import Selector from '../components/selector/Selector';
import currencies from '../components/tags/currencies.json';
import LoadingOverlay from '../components/loading/LoadingOverlay';
import DeleteIcon from '../../src/assets/icons/delete.svg';
import Dropdown from '../components/dropdown/Dropdown';

const NewEditActivity = ({ isEdit }) => {
const navigate = useNavigate();
const { id } = useParams();


const [title, setTitle] = useState('');
const [location, setLocation] = useState('');
const [city, setCity] = useState(''); 
const [country, setCountry] = useState(''); 
const [address, setAddress] = useState('');
const [activityOrPlace, setActivityOrPlace] = useState('place');
const [isActivityFree, setIsActivityFree] = useState(false);
const [selectedCurrency, setSelectedCurrency] = useState({ value: 'EUR' });
const [price, setPrice] = useState([  { pricingTierName: '', amount: '', packageContent: '' }]);
const [idealTime, setIdealTime] = useState(''); 
const [operationHours, setOperationHours] = useState([{ timeFrom: '', timeTo: '' }]);
const [closedOn, setClosedOn] = useState([{ holidayDate: '' }]);
const [idealSeason, setIdealSeason] = useState([]);
const [activityType, setActivityType] = useState([]);
const [description, setDescription] = useState('');
const [duration, setDuration] = useState(''); 
const [isPetAllowed, setIsPetAllowed] = useState(false);
const [tags, setTags] = useState('');
const [mood, setMood] = useState([]);
const [ageLimit, setAgeLimit] = useState([]);
const [subCategory, setSubCategory] = useState([]);
const [capacity, setCapacity] = useState([]);
const [fitnessLevel, setFitnessLevel] = useState([]);
const [headerImage, setHeaderImage] = useState(null);
const [, setHeaderImageUrl] = useState(null);
const [, setLogoUrl] = useState(null);
const [images, setImages] = useState([]);
const [website, setWebsite] = useState('');
const [initialHeaderImage, setInitialHeaderImage] = useState(null);
const [logo, setLogo] = useState(null);
const [initialLogo, setInitialLogo] = useState(null);
const [initialImages, setInitialImages] = useState([]);
const [deleteModalOpen, setDeleteModalOpen] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const [provider, setProvider] = useState('');
const [accessibility, setAccessibility] = useState([]);
const [lastUpdated, setLastUpdated] = useState(0);
const [removedImages, setRemovedImages] = useState([]);
const [isSubmitting, setIsSubmitting] = useState(false);
const [statusMessage, setStatusMessage] = useState({ title: '', detail: '' });

const openModal = () => {
setDeleteModalOpen(true);
};

const closeModal = () => {
setDeleteModalOpen(false);
};


const handleImageUpload = (e) => {
const file = e.target.files[0];
setHeaderImage(file);
const reader = new FileReader();
reader.onloadend = () => {
setHeaderImageUrl(reader.result);
};
reader.readAsDataURL(file);
};

const activityPlaceOptions = useMemo(() => [
{ value: 'activity', label: 'Activity' },
{ value: 'place', label: 'Place' }
], []);

const handleActivityFreeChange = (value) => {
setIsActivityFree(value.includes('free'));
};

const handleCurrencyChange = (selectedOption) => {
setSelectedCurrency(selectedOption ? selectedOption.value : '');
console.log("Currency selected:", selectedOption ? selectedOption.value : '');
};

const handleLogoUpload = (e) => {
const file = e.target.files[0];
setLogo(file);

const reader = new FileReader();
reader.onloadend = () => {
setLogoUrl(reader.result);
};
reader.readAsDataURL(file);
};

const handleImagesUpdate = (newFiles) => {
const updatedImages = newFiles.map(file => {
return {
originalFile: file.originalFile,
url: file.url,
creator: file.creator,
sourceWebsite: file.sourceWebsite
};
});
console.log('updated Images: ', updatedImages);
setImages(updatedImages);

// Determine which images have been removed
const currentUrls = updatedImages.map(file => file.url);
const removedUrls = images
.filter(img => !currentUrls.includes(img.url))
.map(img => img.url);

setRemovedImages(removedUrls);
};


// Function to add a new pricing tier
const addPricingTier = () => {
setPrice([...price, { pricingTierName: '', amount: '', packageContent: '' }]);
};

const removePricingTier = (index) => {
const updatedPrice = price.filter((_, idx) => idx !== index);
setPrice(updatedPrice);
};

const handlePriceChange = (index, key, value) => {
const updatedPrice = [...price];
updatedPrice[index][key] = value;
setPrice(updatedPrice);
};

const handleOperationHoursChange = (index, key, value) => {
const newOperationHours = [...operationHours];
newOperationHours[index][key] = value;
setOperationHours(newOperationHours);
};

const addOperationHour = () => {
setOperationHours([...operationHours, { timeFrom: '', timeTo: '' }]);
};

const removeOperationHour = (index) => {
const newOperationHours = [...operationHours];
newOperationHours.splice(index, 1);
setOperationHours(newOperationHours);
};

const handleClosedOnChange = (index, value) => {
const newClosedOn = [...closedOn];
newClosedOn[index].holidayDate = value;
setClosedOn(newClosedOn);
};



const addClosedOn = () => {
setClosedOn([...closedOn, { holidayDate: '' }]);
};


const removeClosedOn = (index) => {
const newClosedOn = [...closedOn];
newClosedOn.splice(index, 1);
setClosedOn(newClosedOn);
};

const handleSubmit = async () => {
setIsSubmitting(true);
setStatusMessage({ title: isEdit ? 'Updating Activity' : 'Creating New Activity', detail: 'Preparing data...' });

let uploadedHeaderImageUrl = initialHeaderImage;
if (headerImage && headerImage !== initialHeaderImage) {
setStatusMessage({ title: isEdit ? 'Updating Activity' : 'Creating New Activity', detail: 'Uploading header image...' });
const headerImageFormData = new FormData();
headerImageFormData.append('image', headerImage);
try {
const response = await axios.post(
`${process.env.REACT_APP_SERVER_BASE_URL}/api/upload`,
headerImageFormData,
{ headers: { 'Content-Type': 'multipart/form-data' } }
);
uploadedHeaderImageUrl = response.data.Location;
} catch (error) {
console.error('Error uploading header image:', error);
setIsSubmitting(false);
return;
}
}

let uploadedLogoUrl = initialLogo;
if (logo && logo !== initialLogo) {
setStatusMessage({ title: isEdit ? 'Updating Activity' : 'Creating New Activity', detail: 'Uploading logo...' });
const logoFormData = new FormData();
logoFormData.append('image', logo);
try {
const response = await axios.post(
`${process.env.REACT_APP_SERVER_BASE_URL}/api/upload`,
logoFormData,
{ headers: { 'Content-Type': 'multipart/form-data' } }
);
uploadedLogoUrl = response.data.Location;
} catch (error) {
console.error('Error uploading logo:', error);
setIsSubmitting(false);
return;
}
}

const finalImages = images.filter(img => !removedImages.includes(img.url));

const imageUploadData = finalImages.map(img => {
  // Access nested data correctly
  const { url, creator, sourceWebsite } = img.label || {}; // Destructure from img.label if exists

  return {
    url: url || img.url,
    creator: creator || img.creator, // Fallback to img.creator if direct access fails
    sourceWebsite: sourceWebsite || img.sourceWebsite, // Fallback to img.sourceWebsite if direct access fails
  };
});

console.log("Image Upload Data being submitted:", imageUploadData);

console.log("Final images being submitted:", finalImages); 

setStatusMessage({ title: isEdit ? 'Updating Activity' : 'Creating New Activity', detail: 'Finalizing details...' });

let finalPrice = [];
if (isActivityFree || !price.some(p => p.amount.trim() !== '')) {
  finalPrice.push({ pricingTierName: 'Free', amount: '0', packageContent: 'Free Access' });
} else {
  finalPrice = price.filter(p => p.amount.trim() !== '').map(p => ({
    pricingTierName: p.pricingTierName || 'Default',
    amount: p.amount || '0',
    packageContent: p.packageContent || 'Default Package'
  }));
}

const activityData = {
activityType: activityType.map(item => item.value),
title,
description,
website,
provider,
location,
city,
address,
country,
headerImage: uploadedHeaderImageUrl,
logo: uploadedLogoUrl,
images: imageUploadData,
operationHours: operationHours,
closedOn: closedOn.map(c => ({ holidayDate: c.holidayDate })),
duration,
tags,
idealSeason: idealSeason.map(item => item.value),
mood: mood.map(item => item.value),
accessibility: accessibility.map(item => item.value),
price: finalPrice,
ageLimit: Array.isArray(ageLimit) ? ageLimit.map(item => item.value) : [],
subCategory: subCategory.map(item => item.value),
capacity: capacity.map(item => item.value),
lastUpdated: new Date(),
fitnessLevel: fitnessLevel.map(item => item.value),
isActivityFree,
isPetAllowed,
activityOrPlace,
selectedCurrency: typeof selectedCurrency === 'string' ? selectedCurrency : selectedCurrency.value,

};

try {
if (isEdit) {
await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/${id}`, activityData);
} else {
console.log("Submitting closedOn data:", closedOn.map(c => ({ holidayDate: c.holidayDate })));

await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity`, activityData);
}
toast.success(isEdit ? "Activity updated successfully!" : "Activity created successfully!");
navigate(`/manageActivities`);
} catch (error) {
toast.error("An error occurred. Please try again.");
console.error('Error:', error);
setIsSubmitting(false);
}
};

useEffect(() => {
const fetchActivityData = async () => {
if (isEdit) {
try {
const activityResponse = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/${id}`);
const activity = activityResponse.data;
const mapActivityData = (field, stateSetter, isOptionType = false) => {
const data = activity[field];
if (Array.isArray(data)) {
stateSetter(data.map(item => isOptionType ? { value: item, label: item } : item));
} else {
stateSetter(data || (isOptionType ? [] : ''));
}
};

// Mapping each field to the state
mapActivityData('title', setTitle);
mapActivityData('website', setWebsite);
mapActivityData('headerImage', setHeaderImage);
mapActivityData('logo', setLogo);
mapActivityData('description', setDescription);
mapActivityData('duration', setDuration);
mapActivityData('images', setImages, true); // Ensure this maps correctly
mapActivityData('activityType', setActivityType, true);
mapActivityData('provider', setProvider);
mapActivityData('city', setCity);
mapActivityData('country', setCountry);
mapActivityData('idealSeason', setIdealSeason, true);
mapActivityData('mood', setMood, true);
mapActivityData('accessibility', setAccessibility, true);
const fetchedPrice = Array.isArray(activity.price) ? activity.price : [{ pricingTierName: '', amount: '', packageContent: '' }];
setPrice(fetchedPrice);
setOperationHours(Array.isArray(activity.operationHours) ? activity.operationHours : [{ timeFrom: '', timeTo: '' }]);
setClosedOn(Array.isArray(activity.closedOn) ? activity.closedOn : [{ holidayDate: '' }]);
mapActivityData('ageLimit', setAgeLimit, true);
mapActivityData('subCategory', setSubCategory, true);
mapActivityData('capacity', setCapacity, true);
mapActivityData('location', setLocation);
mapActivityData('address', setAddress);
mapActivityData('tags', setTags);
mapActivityData('fitnessLevel', setFitnessLevel, true);
mapActivityData('activityOrPlace', setActivityOrPlace);
setIsActivityFree(activity.isActivityFree);
setIsPetAllowed(typeof activity.isPetAllowed === 'boolean' ? activity.isPetAllowed : false);

const currencyOption = currencies.find(c => c.value === activity.selectedCurrency);
setSelectedCurrency(currencyOption || currencies[0]);
setLastUpdated(new Date(activity.lastUpdated));
setInitialHeaderImage(activity.headerImage);
setInitialLogo(activity.logo);

const fetchedInitialImages = activity.images ? activity.images.map(img => ({
  url: img.url,
  creator: img.creator,
  sourceWebsite: img.sourceWebsite,
  _id: img._id
})) : [];

setInitialImages(fetchedInitialImages);
console.log("Initial Images:", fetchedInitialImages);
} catch (error) {
console.error('Error fetching activity data:', error);
}
}
};

fetchActivityData();
}, [id, isEdit]);



const handleDropdownChange = (field, selectedValues) => {
switch (field) {
case 'idealSeason':
setIdealSeason(selectedValues);
break;
case 'mood':
setMood(selectedValues);
break;
case 'accessibility':
setAccessibility(selectedValues);
break;
case 'ageLimit':
setAgeLimit(selectedValues);
break;
case 'subCategory':
setSubCategory(selectedValues);
break;
case 'capacity':
setCapacity(selectedValues);
break;
case 'fitnessLevel':
setFitnessLevel(selectedValues);
break;
case 'activityType':
setActivityType(selectedValues);
break;
default:
break;
}
};

const handleDelete = () => {
axios
.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/${id}`)
.then((response) => {
if (response.status === 200) {
toast.success("Activity deleted successfully!", {
style: {
border: "1px solid #00a875",
padding: "16px",
color: "#000000",
background: "#b3f8ca",
},
});
} else {
toast.error("An unexpected error occurred. Please try again.", {
style: {
border: "1px solid #f85454",
padding: "16px",
color: "#000000",
background: "#ffbaba",
},
});
}
navigate("/manageActivities");
})
.catch((error) => {
toast.error("An error occurred while deleting the activity. Please try again.", {
style: {
border: "1px solid #f85454",
padding: "16px",
color: "#000000",
background: "#ffbaba",
},
});
console.error('Error deleting activity:', error);
});
};

return (
<AdminWrapper>
<div className="NewEditActivity">

<ToastContainer position="bottom-right" autoClose={2000} />
<div className="activity-newEdit-form">
<div className='top-line-activity'>
<h6>Last Updated: {lastUpdated.toLocaleString()}</h6>
</div>
<div className="title-container">
<h1>{isEdit ? 'Edit Activity' : 'Create New Activity'}</h1>
<div className='button-set'>  
<Button 
label={isEdit ? 'Update Activity' : 'Publish'} type="button" variant="primary" handleClick={handleSubmit} disabled={false} withIcon={false} icon={""} size="small"/>

{isEdit && <Button label="Delete Activity" type="button" variant="danger" handleClick={openModal} disabled={false} withIcon={false} icon={""} size="small" />}
</div>
</div>

<div className='activity-form-boxes'>
<div className='selection'>
<Selector type="radio" label="Select Type" options={activityPlaceOptions} checked={activityOrPlace} onChange={value => setActivityOrPlace(value)} />
<Selector type="checkbox" label="Mark as FREE activity" options={[{ value: 'free', label: 'Yes, it is free activity' }]} checked={isActivityFree ? ['free'] : []} onChange={handleActivityFreeChange} />
</div>
<div className='basic-discription-activity'>
<InputBox title="Title of the Activity" helpText="Create a catchy title" type="text" size="large" value={title} onChange={e => setTitle(e.target.value)} />
<div className='activity-location-data'>
<h2>Location Details</h2>
<div className='activity-location'>
<InputBox title="Location" helpText="Eg: British Museum" type="text" size="large" value={location} onChange={e => setLocation(e.target.value)} />
<InputBox title="City" helpText="Eg: Barcelona" type="text" size="medium" value={city} onChange={e => setCity(e.target.value)} />
<InputBox title="Country" helpText="Eg: Spain" type="text" size="medium" value={country} onChange={e => setCountry(e.target.value)} />
<InputBox title="Duration of Activity" helpText="Eg: 3-5 hours" type="text" size="medium" value={duration} onChange={e => setDuration(e.target.value)} />
</div>
<InputBox title="Full Address" helpText="Full Address" type="text" size="large" value={address} onChange={e => setAddress(e.target.value)} />
</div>

<div className='activity-images-upload'>
<h2>Images</h2>
<UploadBox size="large" label='Select Header Image' onChange={handleImageUpload} initialImage={headerImage}/>
<ImagesBox
size='large'
label='Activity Images'
initialImages={initialImages}
onFilesChange={handleImagesUpdate}
/>
</div>

<h2>Description</h2>
<DescriptionBox label='Description & Things to do' value={description} onChange={value => setDescription(value)} />
<hr/>
{!isActivityFree && (
<div className='hr'>
<div className='inputboxes-form'>
<h2>Pricing and Package Details</h2>
{price.map((tier, index) => (
<div className='inputboxes-form' key={index}>
<Dropdown
title="Currency" options={currencies} value={selectedCurrency} onChange={handleCurrencyChange} size='medium' />
<div className='price-boxes'>
<InputBox title="Pricing Tier Name" helpText='Package Name' type="text" size="large" value={tier.pricingTierName} onChange={(e) => handlePriceChange(index, 'pricingTierName', e.target.value)} />
<InputBox title="Amount" helpText='Price' type="text" size="large" value={tier.amount} onChange={(e) => handlePriceChange(index, 'amount', e.target.value)} />
</div>
<DescriptionBox label='Package Content' value={tier.packageContent} onChange={(value) => handlePriceChange(index, 'packageContent', value)} />
<div className='delete-button'>
<img src={DeleteIcon} alt="Delete" onClick={() => removePricingTier(index)} style={{ cursor: 'pointer' }} />
</div>
<Button size='medium' label='+ Add Pricing Tier' variant='secondary'  handleClick={addPricingTier}></Button>
</div>
))}
</div>
<hr/>
</div>
)}
<div className='operation-hours'>
<h2>Operation Hours</h2>
<div className='inputboxes-form'>
{operationHours.map((hour, index) => (
<div className='inputboxes-form' key={index}>
<InputBox title="Time From" type="text" size="small" value={hour.timeFrom} onChange={(e) => handleOperationHoursChange(index, 'timeFrom', e.target.value)} />
<InputBox title="Time To" type="text" size="small" value={hour.timeTo} onChange={(e) => handleOperationHoursChange(index, 'timeTo', e.target.value)} />
<div className='delete-button'>
<img src={DeleteIcon} alt="Delete" onClick={() => removeOperationHour(index)} style={{ cursor: 'pointer' }} />
</div>
</div>
))}
<Button size='medium' label='+ Add Operation Hour' variant='secondary' handleClick={addOperationHour}></Button>
</div>
<div className='inputboxes-form'>
{closedOn.map((date, index) => (
<div className='inputboxes-form' key={index}>
<InputBox
title="Closed on (Dates/Days)"
type="text"
size="large"
value={date.holidayDate}
onChange={(e) => handleClosedOnChange(index, e.target.value)}
/>
<div className='delete-button'>
<img src={DeleteIcon} alt="Delete" onClick={() => removeClosedOn(index)} style={{ cursor: 'pointer' }} />
</div>
</div>
))}
<Button size='medium' label='+ Add Closed On Date' variant='secondary' handleClick={addClosedOn} />
</div>

{/* Ideal Time Input */}
<div className='ideal-time'>
<InputBox 
title="Ideal Time for Activity" 
helpText="E.g., Morning, Afternoon, Evening" 
type="text" 
size="large" 
value={idealTime} 
onChange={e => setIdealTime(e.target.value)} 
/>
</div>
</div>
</div>
<hr/>
<div className='secondary-activity-details'>
<h2>Secondary Activity Details</h2>
<Selector
type="radio"
label="Are Pets Allowed"
options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
checked={isPetAllowed.toString()}
onChange={value => setIsPetAllowed(value === 'true')}
/>

<InputBox title="Tags" helpText="Type in the tags separated by ," type="text" size="large" value={tags} onChange={e => setTags(e.target.value)} />
<div className='filter-dropdown-selection'>
<MultiDropdown title="Activity Type" options={activityTypeKeywords} onChange={selected => handleDropdownChange('activityType', selected)} variant="multi" size="medium" isMulti={true} value={activityType} />
<MultiDropdown title="Sub-category" options={subCategoryOptions} onChange={selected => handleDropdownChange('subCategory', selected)} variant="multi" size="medium" isMulti={true} value={subCategory} />
<MultiDropdown title="Ideal Season" options={seasonOptions} onChange={selected => handleDropdownChange('idealSeason', selected)} variant="multi" size="medium" isMulti={true} value={idealSeason}/>
<MultiDropdown title="Mood" options={moodOptions} onChange={selected => handleDropdownChange('mood', selected)} variant="multi" size="medium" isMulti={true} value={mood}/>
<MultiDropdown title="Accessibility" options={accessibilityOptions} onChange={selected => handleDropdownChange('accessibility', selected)} variant="multi" size="medium" isMulti={true} value={accessibility}/>
<MultiDropdown title="Age Limit" options={ageLimitOptions} onChange={selected => handleDropdownChange('ageLimit', selected)} variant="multi" size="medium" isMulti={true} value={ageLimit} />
<MultiDropdown title="Fitness Level" options={fitnessLevelOptions} onChange={selected => handleDropdownChange('fitnessLevel', selected)} variant="multi" size="medium" isMulti={true} value={fitnessLevel}/>
<MultiDropdown title="Capacity" options={capacityOptions} onChange={selected => handleDropdownChange('capacity', selected)} variant="multi" size="medium" isMulti={false} value={capacity}/>
</div>
</div>
<hr/>
{!isActivityFree && (
<>
<div className='no-free-details'>
<h2>Activity Provider Details</h2>
<InputBox title="Activity Provider Name" helpText="Activity Provider Name" type="text" size="large" value={provider} onChange={e => setProvider(e.target.value)} />
<UploadBox size="large" helpText="Add provider logo" label='Select Provider logo (ideal size: 200px x200px)' onChange={handleLogoUpload} initialImage={logo}/>
<InputBox title="Activity Provider's Website" helpText="https://www.example.com" type="text" size="medium" value={website} onChange={e => setWebsite(e.target.value)} />
</div>
</>
)}
<hr/>
</div>

<div className='button-set'>  
<Button label={isEdit ? 'Update Activity' : 'Publish'} type="button" variant="primary" handleClick={handleSubmit} disabled={false} withIcon={false} icon={""} size="large"/>
</div>

{deleteModalOpen && (
<div className={`delete-modal ${deleteModalOpen ? 'open' : ''}`} onClick={closeModal}>
<div className="delete-modal-content" onClick={e => e.stopPropagation()}>
<div className='AreYouSure'>
<h2>Are you Sure</h2>
<p>Are you sure you want to delete this activity?</p>
</div>
<div className='confirm-modal-buttons'>
<Button size='small' variant='danger' label="Delete" handleClick={handleDelete}/>
<Button size='small' variant='secondary' label="Cancel" handleClick={closeModal} />
</div>
</div>
</div>
)}

</div>
</div>
<LoadingOverlay isVisible={isSubmitting} statusMessage={statusMessage} />
</AdminWrapper>
);
}
export default NewEditActivity;
