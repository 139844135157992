import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function AuthCallback() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const userType = queryParams.get('userType');
        if (token && userType) {
            localStorage.setItem('token', token);
            localStorage.setItem('userType', userType);

            if (userType === 'admin') {
                navigate('/adminDashboard');
            } else {
                navigate('/userDashboard');
            }
        } else {
            console.error('Invalid callback parameters');
        }
    }, [location, navigate]);

    return null;
}

export default AuthCallback;
