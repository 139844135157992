import { Helmet } from 'react-helmet';
import React, { useEffect, useState, useRef } from 'react';
import PremiumPromotion from '../components/promotions/PremiumPromotion';
import FeaturedPromotion from '../components/promotions/FeaturedPromotion';
import UserContext from '../components/UserContext';
import { useNavigate } from 'react-router-dom';
import ActivityCardSection from '../components/activityCardSection/ActivityCardSection';
import FeedBackSection from '../components/feedbackSection/FeedBackSection';
import ProviderContactSection from '../components/providerContactSection/ProviderContactSection';
import BlogSection from '../components/blogSection/BlogSection';
import './Homepage.css';
import Button from '../components/button/Button'; 
import CategorySection from '../components/categorySection/CategorySection';
import CountrySection from '../components/countrySection/CountrySection';
import Features from '../components/features/Features';
import HeroSection from '../components/heroSection/HeroSection';

const HomePage = () => {
const { userType } = React.useContext(UserContext);
const navigate = useNavigate();
const [isPromoInView, setIsPromoInView] = useState(false);
const promoRef = useRef(null);

useEffect(() => {
  const currentPromoRef = promoRef.current;
  const observer = new IntersectionObserver(
      (entries) => {
          if (entries[0].isIntersecting) {
              setIsPromoInView(true);
          } else {
              setIsPromoInView(false);
          }
      },
      {
          threshold: 0.1 
      }
  );

  if (currentPromoRef) {
      observer.observe(currentPromoRef);
  }

  return () => {
      if (currentPromoRef) {
          observer.unobserve(currentPromoRef);
      }
  };
}, []);


useEffect(() => {
if (userType === 'user') {
navigate('/UserDashboard');
}else if (userType === 'admin') {
  navigate('/AdminDashboard');
}
}, [userType, navigate]);

return (
<>
<Helmet>
        <link rel="canonical" href="https://www.rebrid.co/" />
      </Helmet>
<div className='hero-area'>
<HeroSection/>
</div>
<div className='search-section-carousell'>
<CategorySection/>
</div>

<ActivityCardSection category='premium'/>

<div className='country-section'>
<CountrySection/>
</div>
<div className='features-section'>
<Features />
</div>
<div className='activities-section'>
<h1>Popular Activities for you</h1>
<p>Below are some of the most popular activities on our platform. Click on the See All button to discover your next leisure activity for your holidays.</p>
</div>
<ActivityCardSection variant= "homepage" category='homepage'/>
<div className='section activity-section' ref={promoRef}>
  <div className='featured-promo-section'>
  <FeaturedPromotion />
  </div>
  <ActivityCardSection category='homepage'/>
  <ProviderContactSection />
  {isPromoInView && <FeedBackSection />} 
</div>
<BlogSection />

      
      </>
);
};

export default HomePage;
