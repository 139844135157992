import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios'; // Assuming you use axios for HTTP requests

const UserContext = React.createContext({
  userType: null, // this is the default value
  setUserType: () => {},
});

export const UserProvider = ({ children }) => {
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    // Try to get the JWT token from localStorage
    const token = localStorage.getItem('jwtToken');

    if (token) {
      try {
        const decoded = jwtDecode(token);
        const userId = decoded._id;

        axios.get(`/api/users/${userId}/type`)
          .then(response => {
            setUserType(response.data.userType);
          })
          .catch(error => {
            console.error('Error fetching user type:', error);
          });
      } catch (err) {
        console.error('Error decoding JWT token:', err);
      }
    } else {
      setUserType(null);
    }
  }, []);

  return (
    <UserContext.Provider value={{ userType, setUserType }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
