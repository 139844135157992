import React from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import './MultiDropdown.css';

const MultiDropdown = ({ title, options = [], value = [], isDisabled = false, isSearchable = false, isRequired = false, size = "medium", onChange, loadOptions }) => {  

  const Option = (props) => (
    <components.Option {...props}>
      <label className="option-label">{props.label}</label>
    </components.Option>
  );

  const handleChange = (selectedOptions) => {
    if (onChange) {
      onChange(selectedOptions || []);
    }
  };

  const preparedOptions = options && Array.isArray(options) 
    ? options.map((option, index) => ({ ...option, isLast: index === options.length - 1 })) 
    : [];

  const getValue = (option) => option && option.value;
  const selectedValue = Array.isArray(value) 
    ? preparedOptions.filter(option => value.map(getValue).includes(getValue(option))) 
    : [];


  return (
    <div className={`dropdown-container ${size}`}>
      <label className='dropdown-label'>{title}{isRequired && '*'}</label>
      {loadOptions ? (
        <AsyncSelect 
          components={{ Option }}
          isSearchable={isSearchable}
          closeMenuOnSelect={false}
          isDisabled={isDisabled}
          isMulti={true}
          value={selectedValue}
          onChange={handleChange}
          loadOptions={loadOptions}
          defaultOptions
        />
      ) : (
        <Select 
          components={{ Option }}
          isSearchable={isSearchable}
          closeMenuOnSelect={false}
          isDisabled={isDisabled}
          isMulti={true}
          value={selectedValue}
          onChange={handleChange}
          options={preparedOptions}
        />
      )}
    </div>
  );
};

export default MultiDropdown;
