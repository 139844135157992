// AdminWrapper.js
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';

const AdminWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { userType } = useContext(UserContext);
  const isAdmin = userType === 'admin';

  useEffect(() => {
    if (!isAdmin) {
      navigate('/login');
    }
  }, [isAdmin, navigate]);

  return isAdmin ? children : null;
};

export default AdminWrapper;
