import React from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import './Dropdown.css';

const Dropdown = ({ title, options = [], value, isDisabled = false, isSearchable = false, isRequired = false, size = "medium", onChange, loadOptions }) => {
const Option = (props) => (
<components.Option {...props}>
<label className="option-label">{props.label}</label>
</components.Option>
);

const handleChange = (selectedOption) => {
// Check if the option is being deselected
const isDeselection = selectedOption && selectedOption.value === (value ? value.value : null);
if (onChange) {
onChange(isDeselection ? null : selectedOption);
}
};

const preparedOptions = options.map(option => ({ ...option }));
const selectedValue = value ? preparedOptions.find(option => option.value === value.value) : null;

return (
<div className={`dropdown-container ${size}`}>
<label className='dropdown-label'>{title}{isRequired && '*'}</label>
{loadOptions ? (
<AsyncSelect
classNamePrefix="react-select"
components={{ Option }}
isSearchable={isSearchable}
isDisabled={isDisabled}
value={selectedValue}
onChange={handleChange}
loadOptions={loadOptions}
defaultOptions
/>
) : (
<Select
classNamePrefix="react-select"
components={{ Option }}
isSearchable={isSearchable}
isDisabled={isDisabled}
value={selectedValue}
onChange={handleChange}
options={preparedOptions}
/>
)}
</div>
);
};

export default Dropdown;
