import React, { useState } from "react";
import InputBox from "../components/InputBox/InputBox";
import Button from "../components/button/Button.jsx";
import axios from "axios";
import "./ForgotPassword.css";
import GoogleIcon from "../assets/icons/Google.svg";
import { sanitizeInput } from "../components/utils/sanitizeInput";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkGoogleRegistration = async () => {
    try {
      // Sanitize email input
      const sanitizedEmail = sanitizeInput(email);

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/userByEmail`,
        { params: { email: sanitizedEmail } }
      );
      if (response.data.googleRegistration) {
        setIsGoogleUser(true);
        setShowForm(false);
        setMessage(
          "This email is registered via Google. Please log in using Google."
        );
      } else {
        handleSendResetLink(sanitizedEmail);
      }
    } catch (error) {
      setMessage("Error checking user registration.");
    }
  };

  const handleSendResetLink = async (sanitizedEmail) => {
    try {
      setIsSubmitting(true);
      // Sanitize email input
      const sanitizedEmail = sanitizeInput(email);

      await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/forgot-password`,
        { email: sanitizedEmail }
      );
      setMessage(
        "Please check your email. You should receive the reset link in the email within 5 minutes. Check your spam email, in case you do not receive it."
      );
      setShowForm(false);
    } catch (error) {
      setMessage(
        `We could not find ${sanitizedEmail} in our database. Please check the spelling and try again or click the button below to sign up.`
      );
      setShowForm(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_SERVER_BASE_URL}/user/google`;
  };

  const handleResendEmail = () => {
    setShowForm(true);
    setMessage("");
    setIsGoogleUser(false);
    setIsSubmitting(false);
  };

  return (
    <div className="main-container">
      <div className="forgot-password-container">
        <h1>Forgot Password</h1>
        {showForm ? (
          <>
            <p>
              Please enter your email address to receive a password reset link.
            </p>
            <div className="input-password-section">
              <InputBox
                className="input-box"
                size="large"
                title="Email"
                variant="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                size="small"
                variant="primary"
                label={isSubmitting ? "Checking..." : "Send Reset Link"}
                handleClick={() => checkGoogleRegistration()}
                disabled={isSubmitting}
              />
            </div>
          </>
        ) : (
          <>
            {isGoogleUser ? (
              <div className="forgot-password-message-button">
                <h3>{message}</h3>
                <div className="google-login">
                  <Button
                    withIcon={true}
                    icon={GoogleIcon}
                    variant="secondary"
                    label="Login with Google"
                    handleClick={handleGoogleLogin}
                    size="medium"
                    className="google-btn"
                  />
                </div>
              </div>
            ) : (
              <div className="forgot-password-message-button">
                <p>{message}</p>
                {message.includes("could not find") ? (
                  <Button
                    variant="primary"
                    size="medium"
                    label="Register"
                    to="/register"
                  ></Button>
                ) : (
                  <>
                    <Button
                      variant="tertiary"
                      size="small"
                      label="Resend Email"
                      handleClick={() => setShowForm(true)}
                    >
                      Resend Email
                    </Button>
                    <Button
                      variant="secondary"
                      size="small"
                      label="Back to Login"
                      handleClick={() => (window.location.href = "/login")}
                    >
                      Back to Login
                    </Button>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
