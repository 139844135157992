import React from 'react';
import './Loading.css'; // You can style this component separately

const LoadingAnimation = () => {
    return (
        <div className="loading-animation">
            <span>Loading...</span>
        </div>
    );
};

export default LoadingAnimation;
