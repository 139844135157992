import React, { useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './DescriptionBox.css';

// Register custom font
const Font = Quill.import('formats/font');
Font.whitelist = ['inter']; // Add 'inter' to the font whitelist
Quill.register(Font, true);

// Custom link class for opening links in new tab
const Link = Quill.import('formats/link');
class MyLink extends Link {
    static create(value) {
        let node = super.create(value);
        value = this.sanitize(value);
        node.setAttribute('href', value);
        node.setAttribute('target', '_blank'); // Open in new tab
        return node;
    }
}
MyLink.blotName = 'link';
MyLink.tagName = 'A'; // Ensure it's uppercase
Quill.register(MyLink, true); // Register the custom link handler

const DescriptionBox = ({ label, value, onChange }) => {
    const quillRef = useRef();

    // Use the 'text' argument passed by ReactQuill as the new value
    const handleEditorChange = (text) => {
        onChange(text);
    };

    return (
        <div className="description-box">
            <label className="description-box-label">{label}</label>
            <ReactQuill 
                value={value || ''}
                onChange={handleEditorChange} // Use the new handler
                theme="snow"
                modules={DescriptionBox.modules}
                className="description-box-editor"
                ref={quillRef}
            />
        </div>
    );
};

DescriptionBox.modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'font': ['inter'] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link'],
        ['clean'],
    ],
    clipboard: {
        matchVisual: false,
    },
};

export default DescriptionBox;
