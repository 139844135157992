import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ActivityCard from '../activityCard/ActivityCard';
import Button from '../button/Button';
import './ActivityCardSection.css';
import ActivityCardSkeleton from '../loading/ActivityCardSkeleton';
import jwtDecode from 'jwt-decode';

const ActivityCardSection = ({ category, keyword, sectionTitle, variant, currentActivityId }) => {
const navigate = useNavigate();
const [activities, setActivities] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

useEffect(() => {
const fetchActivities = async () => {
setLoading(true);
try {
let response;
const cardLimit = category === 'premium' ? 10 : 5; 
const token = localStorage.getItem('token');
const headers = token ? { Authorization: `Bearer ${token}` } : {};
let params = { limit: cardLimit, category };

if (category === 'premium') {
  response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/premium`, { headers, params: { limit: cardLimit } });
  console.log("premium activities: ", response.data.data);
  setActivities(response.data.data);
}


else if (category === 'homepage') {
const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/activity`, { params: { category: 'homepage' } });

// Directly set the activities without slicing
setActivities(response.data.data);
} else if (category === 'keywordsMatch') {
const endpoint = `${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/keywordsMatch`;
const params = { 
keywords: keyword,
currentActivityId: currentActivityId
};
response = await axios.get(endpoint, { params });
setActivities(response.data.data.slice(0, cardLimit));
} else {

// Logic for other categories
if (!token) {
setLoading(false);
return;
}
const decoded = jwtDecode(token);

if (category === 'watchHistory') {
const endpoint = `${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/user/${decoded.userId}/watchHistory`;
response = await axios.get(endpoint, { headers, params: { limit: cardLimit } });
setActivities(response.data.data.slice(0, cardLimit));
} else if (category === 'ip') {
const endpoint = `${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/user/${decoded.userId}/ipActivities`;
response = await axios.get(endpoint, { headers, params: { limit: cardLimit } });
setActivities(response.data.data);
} else if (category === 'local') {
const endpoint = `${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/user/${decoded.userId}/localActivities`;
response = await axios.get(endpoint, { headers, params: { limit: cardLimit } });
setActivities(response.data.data);
} else if (category === 'country') {
  const endpoint = `${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/user/${decoded.userId}/countryActivities`;
  response = await axios.get(endpoint, { headers, params: { category, limit: cardLimit }});
  setActivities(response.data.data.slice(0, cardLimit));
} else if (category === 'interests') {
const endpoint = `${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/user/${decoded.userId}/interests`;
response = await axios.get(endpoint, { headers, params: { limit: cardLimit } });
setActivities(response.data.data);
} else if (category === 'yourPreferences') {
const endpoint = `${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/user/${decoded.userId}/preferences`;
response = await axios.get(endpoint, { headers, params: { limit: cardLimit } });
setActivities(response.data.data);
} else {
const params = { category, keyword, limit: cardLimit };
response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/activity`, { headers, params });

setActivities(response.data.data || []);
}
}
} catch (error) {
console.error('Error fetching activities:', error);
setError(error.message);
} finally {
setLoading(false);
}
};

fetchActivities();
}, [category, keyword, variant, currentActivityId]);


const requestLocationPermission = () => {
if (navigator.geolocation) {
navigator.geolocation.getCurrentPosition(
(position) => {
// Handle successful retrieval of position
},
(error) => {
// Handle location access denied
console.error("Error Code = " + error.code + " - " + error.message);
}
);
} else {
console.error("Geolocation is not supported by this browser.");
}
};

const handleSeeAll = () => {
  console.log("button clicked");
  const params = new URLSearchParams();

  if (category === 'premium') {
      console.log('Button Clicked');
      params.append('category', 'premium');
      navigate(`/see-all?${params.toString()}`);
  } else if (category === 'keywordsMatch') {
      params.append('category', 'keywordsMatch');
      params.append('keyword', keyword);
  } else if (keyword) {
      params.append('keyword', keyword);
  } else if (category) {
      params.append('category', category);
  }
  
  // This should be outside of the 'else if' blocks
  navigate(`/see-all?${params.toString()}`);
};



if (error) {
let errorMessage = <p>Error: {error}</p>; // Default error message

// Customize error message for city or country-based errors
if ((category === 'local' || category === 'country') && !activities.length) {
errorMessage = (
<div className="info-missing">
<p>
We couldn't find activities in your {category === 'local' ? 'city' : 'country'}. Please ensure your {category === 'local' ? 'city' : 'country'} is correctly set in your profile. If you've already done this, try checking the spelling or setting a nearby city.
</p>
<Button variant='secondary' label="Update Profile" to="/userProfile" />
</div>
);
} else if (category === 'ip' && !activities.length) {
// Customize error message for IP based errors
errorMessage = (
<div className="info-missing">
<p>
To discover activities near you, please enable location services in your browser.
</p>
<Button variant='secondary' label="Enable Location" handleClick={requestLocationPermission} />
</div>
);
}

return errorMessage;
}

return (
<div className="activity-card-component" style={{ display: activities.length === 0 && !loading ? 'none' : 'block' }}>
<div className="activity-card-container">
{category !== 'homepage' && (
<div className="header">
<h3>
{sectionTitle || (category ? category.charAt(0).toUpperCase() + category.slice(1)+ " Activities" : "Activities")}
</h3>

<div className='seeall-button'>
<Button variant='secondary' label='See All' handleClick={handleSeeAll} />
</div>
</div>
)}
{loading ? (
<div className="activity-cards">
<ActivityCardSkeleton />
<ActivityCardSkeleton />
<ActivityCardSkeleton />
<ActivityCardSkeleton />
<ActivityCardSkeleton />
</div>
) : (
<div className="activity-cards">
{activities.map((activity, index) => (
<ActivityCard key={`${activity._id}-${index}`} activity={activity} />
))}
</div>
)}
</div>
</div>
);
};

export default ActivityCardSection;
