import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BlogCard.css';
import Selector from '../selector/Selector';

const BlogCard = ({ blog, isSelected, onSelect, isAdmin }) => {
  const { _id, title, subtitle, headerImage, readDuration } = blog;
  const navigate = useNavigate();

  const handleSelect = (value) => {
    onSelect(_id, value.includes(_id));
  }

  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/blogs/${_id}`);
  }

  return (
    <div className="preview-card" onClick={handleClick}>
      {headerImage && <div className="card-header-image" style={{backgroundImage: `url(${headerImage})`}}></div>}
      {isAdmin && (
        <div className="admin-selector">
          <Selector
            type='checkbox'
            label=''
            options={[{value: _id, label: ''}]}
            checked={isSelected ? [_id] : []}
            onChange={handleSelect}
          />
        </div>
      )}
      <div className='card-contents'>
        <h3 className="card-title">{title.length > 50 ? `${title.substring(0, 50)}...` : title}</h3>
          <h5 className="blog-subtitle">{subtitle.length > 80 ? `${subtitle.substring(0, 80)}...` : subtitle}</h5>
        <div className="card-buttons">
          <div className="read-duration">{readDuration} min read</div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
