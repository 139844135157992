import React from 'react';
import './TnC.css';

const TnC = () => {
return (
<div className="tnc-page">
<div className="tnc-container">
<h1 className="text-h1">Terms and Conditions</h1>
<h3>1. Introduction</h3>
<p> Rebrid, accessible at https://www.rebrid.co, is a platform dedicated to providing users with an interactive space to share, explore, and engage with bucket list activities and adventures from around the world. By venturing into the digital realm of Rebrid, users can discover inspiring journeys, connect with like-minded individuals, and create memories that last a lifetime. By accessing, browsing, or using our platform, users agree to adhere to and be bound by the terms and conditions described herein and all terms incorporated by reference.
</p>
<h3>2. Registration</h3>
<p>To be a part of the Rebrid community, users may opt for registration via Google. By choosing this registration method, users implicitly permit Rebrid to access certain basic profile details stored in their Google account. Such details primarily include recognizable identifiers like name and email address. This amalgamation with Google aids in rendering an efficient onboarding process and a tailored user experience. Importantly, Rebrid stands firm on its commitment to data privacy, ensuring no unwarranted access or extraction of data beyond the initially granted permissions. Furthermore, users must ensure the information provided, either directly or through third-party platforms, remains accurate and up-to-date.
</p>
<h3>3. User Responsibilities</h3>
<p>
Being part of Rebrid's expansive network comes with its set of responsibilities. Each user serves as a pillar, upholding the platform's integrity and ethos. The following are essential duties and behavioral expectations:

Users must interact with respect, demonstrating understanding and patience.</p>
<ul>
<li>Engaging in spam, mass advertising, or any form of unsolicited content is strictly prohibited.</li>
<li>Misleading, deceiving, or engaging in malicious behaviors will not be tolerated.</li>
<li>Any form of data misuse, unauthorized access attempts, or security breaches must be avoided.</li>
<li>Users are also accountable for maintaining the security of their account credentials.</li>
</ul>
<h3>4. Content Provisions</h3>
<p>Rebrid thrives on user contributions. From insightful reviews to riveting bucket list experiences, user-generated content serves as the platform's backbone. While we advocate for free expression, there are guidelines to ensure the quality and safety of content:

Content must be authentic, free from plagiarism, and not violate any third-party rights, including copyright and trademark rights.
Hate speech, slander, explicit content, or any form of offensive material is strictly prohibited.
Spreading falsehoods, promoting misinformation, or creating potentially harmful narratives is not allowed.
</p>
<h3>5. Termination Rights</h3>
<p>To maintain the sanctity and safety of Rebrid, the platform reserves the right to take action against accounts that contradict our ethos and breach our guidelines. Termination or suspension can arise due to various reasons:</p>
<ul>
<li>Repetitive spamming or advertising.</li>
<li>Sharing inappropriate, misleading, or malicious content.</li>
<li>Misusing data or attempting unauthorized access to other user accounts or the platform's backend.</li>
<li>Engaging in behaviors that harm the platform's reputation or well-being of its users.</li>
</ul>
<p>Such decisions will be executed with discretion, ensuring fairness and transparency. While prior notice might be provided, Rebrid retains the right to act swiftly in extreme cases to preserve platform integrity.
</p>
<h3>6. Disclaimers</h3>
<p>While Rebrid constantly endeavors to provide accurate, up-to-date, and reliable content, there are inherent challenges and limitations associated with maintaining an expansive, user-driven platform. Users should consider:</p>
<ul>
<li>All content, especially user-generated content, might not always be accurate, complete, or timely.</li>
<li>External third-party links, which might be available for added context or reference, are not under Rebrid's control. We cannot guarantee the safety, authenticity, or accuracy of these external sources.</li>
<li>While we aim for uninterrupted service, Rebrid does not promise that the platform will always be available or free from technical issues.</li>
<li>Users are strongly advised to exercise their discretion, especially when making decisions based on content or third-party links provided on Rebrid.</li>
</ul>

<h3>7. Liabilities</h3>
<p>
Rebrid, as an interactive platform, provides tools, content, and a space for user-generated contributions. However:</p>
<ul>
<li>Decisions made based on the content found on Rebrid are at the user's discretion. Rebrid cannot be held liable for any negative outcomes, financial or otherwise, arising from such decisions.</li>
<li>Users accept that they, and not Rebrid, are solely responsible for all electronic communications and content sent from their devices to us.</li>
<li>Any claims of damage or harm resulting directly or indirectly from actions taken based on Rebrid's content will be the sole responsibility of the user.</li>
</ul>

<h3>8. User Content Contributions</h3>
<p>Rebrid's dynamic nature arises from user-generated content. By contributing:</p>
<ul>
<li>Users grant Rebrid a non-exclusive, royalty-free, and worldwide license to use, reproduce, modify, display, distribute, and create derivative works of such content.</li>
<li>This license persists even if a user discontinues the use of our platform.</li>
<li>Rebrid reserves the right, but is not obligated, to review, monitor, reject, or remove any content that does not adhere to our guidelines.</li>
</ul>

<h3>9. Amendments to Terms & Conditions</h3>
<p>Rebrid's expansive and evolving nature means we must frequently review and update our Terms & Conditions.</p>

<ul>
<li>Changes might be made without prior notice, and the most recent version will always supersede previous versions.</li>
<li>Continual use of Rebrid after changes signifies user's acceptance of the updated terms.</li>
<li>We encourage users to regularly review our Terms & Conditions for any updates.</li>
</ul>
<h3>10. Governing Law and Jurisdiction</h3>
<p>These Terms & Conditions, and any disputes arising from or in connection with them, are governed by and construed in accordance with the laws of the jurisdiction where Rebrid's operations are based. Users agree to the exclusive jurisdiction of these courts.</p>
<h3>11. Contact & Feedback</h3>
<p>Rebrid values its community's feedback and insights. For any clarifications, disputes, or feedback regarding these Terms & Conditions, users can reach out via our official contact channels available on the website.</p>
</div>
</div>
);
};

export default TnC;
