import { useEffect } from 'react';
import UAParser from 'ua-parser-js';

const useTracker = (userType) => {
useEffect(() => {
const parser = new UAParser();
parser.setUA(navigator.userAgent);
const result = parser.getResult();

const data = {
browserInfo: navigator.userAgent,
browserName: result.browser.name,
browserVersion: result.browser.version,
osName: result.os.name,
deviceType: result.device.type,
deviceModel: result.device.model,
screenDimensions: {
width: window.innerWidth,
height: window.innerHeight,
},
language: navigator.language,
userType: userType || 'visitor',
date: new Date(),
referrerURL: document.referrer,
pageURL: window.location.href,
};

fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/analytics/capture`, {
method: 'POST',
headers: {
'Content-Type': 'application/json',
},
body: JSON.stringify(data),
});
}, [userType]);
};

export default useTracker;
