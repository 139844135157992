import React, { useState, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import axios from 'axios';
import InputBox from '../InputBox/InputBox';
import Button from '../button/Button';
import './ShareModal.css';
import CloseIcon from '../../assets/icons/close.svg';
import Delete from '../../assets/icons/delete.svg';
import addIcon from '../../assets/icons/add.svg';
import jwtDecode from 'jwt-decode';

const ShareModal = ({ activityId, isOpen, onClose }) => {
const [emailInput, setEmailInput] = useState("");
const [emails, setEmails] = useState([]);
const [message, setMessage] = useState("");
const [isLoading, setIsLoading] = useState(false);
const [userDetails, setUserDetails] = useState({});
const [duplicateEmail, setDuplicateEmail] = useState("");
const [currentUser, setCurrentUser] = useState({});
const messageTimeoutRef = useRef(null);
const [activityDetails, setActivityDetails] = useState({ title: '', description: '' });

useEffect(() => {
const token = localStorage.getItem('token');
if (token) {
const decoded = jwtDecode(token);
fetchUserProfile(decoded.userId);
}

fetchActivityDetails(); // Fetch activity details
}, [activityId]);
const activityUrl = `https://www.rebrid.co/activity/${activityId}`;
const fetchActivityDetails = async () => {
try {
const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/${activityId}`);

setActivityDetails({
title: response.data.title,
description: response.data.description
});
} catch (error) {
console.error('Error fetching activity details:', error);
}
};

const fetchUserProfile = async (userId) => {
try {
const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/user/profile?userId=${userId}`);
setCurrentUser(response.data);
} catch (error) {
console.error('Error fetching user data:', error);
}
};

const handleEmailChange = (value) => {
setEmailInput(value);
setDuplicateEmail("");
};

const clearMessage = () => {
setMessage("");
if (messageTimeoutRef.current) {
clearTimeout(messageTimeoutRef.current);
messageTimeoutRef.current = null;
}
};

const handleAddMore = async () => {
const latestEmail = emailInput.trim();

if (!latestEmail || emails.includes(latestEmail)) {
setDuplicateEmail(latestEmail);
return;
;
}

try {
const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/userByEmail`, { params: { email: latestEmail } });
setUserDetails({ ...userDetails, [latestEmail]: response.data });
} catch (error) {
console.error("Error fetching user details:", error);
setUserDetails({ ...userDetails, [latestEmail]: { exists: false } });
}

setEmails([...emails, latestEmail]);
setEmailInput("");
};

const handleDeleteEmail = (index) => {
setEmails(prev => prev.filter((_, i) => i !== index));
};

const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}


const createEmailContent = (activityId, activityTitle, activityDescription) => {

  const senderName = capitalizeFirstLetter(currentUser.userName) || 
  capitalizeFirstLetter(currentUser.firstName) || 
  capitalizeFirstLetter(currentUser.email) || 
  'A user';
  const plainTextDescription = activityDescription.replace(/<[^>]*>?/gm, '');
  const truncatedDescription = plainTextDescription.split(' ').slice(0, 30).join(' ') + (plainTextDescription.split(' ').length > 30 ? '...' : '');

  const emailStyle = {
      fontSize: '16px',
      color: '#333448',
      fontFamily: "'Inter', 'Roboto', sans-serif"
  };

return ReactDOMServer.renderToString(
<div className='share-email' style={{ backgroundColor: '#f5f5f5', padding: '48px', boxSizing: 'border-box' }}>
<div className='share-email-content' style={{ backgroundColor: 'white', borderRadius: '16px', padding: '32px', margin: 'auto', width: '80%' }}>
<div className='share-email-heading'>
<h1 style={{ fontSize: '32px', textAlign: 'center', color: '#333448', fontWeight: '800', fontFamily: "'Inter', 'Roboto', sans-serif" }}>Check out this Activity</h1>
</div>
<div className='share-email-body'>
<p style={emailStyle}>Hi there,</p>
<p style={emailStyle}>{senderName} thought you might be interested in checking out this activity on <strong style={{ color: '#FF357E' }}>Rebrid </strong>! It's called <strong><a href={activityUrl} style={{ color: '#1a73e8', textDecoration: 'none' }}>{activityTitle}</a></strong> and it looks like a lot of fun.</p>
<p style={emailStyle}>Here's what the activity is about:</p>
<p style={emailStyle}>{truncatedDescription}</p>
<p style={emailStyle}><a href={activityUrl} style={{ backgroundColor: '#FF357E', color: 'white', textDecoration: 'none', padding: '10px 20px', borderRadius: '5px', display: 'inline-block' }}>Check out Activity</a></p>
<p style={emailStyle}>Let me know what you think!</p>
<p style={emailStyle}>Cheers, {senderName}</p>
<footer>
<p style={{ fontSize: '14px', color: '#646A76', fontFamily: "'Inter', 'Roboto', sans-serif" }}>Visit <a href="https://www.rebrid.co" style={{ color: '#FF357E', textDecoration: 'none' }}>Rebrid.co</a> to explore more interesting activities.</p>
</footer>
</div>
</div>
</div>
);
};


const handleShare = async (e) => {
e.preventDefault();
setIsLoading(true);

// Define senderDetails here
const senderDetails = {
userName: currentUser.userName || '',
firstName: currentUser.firstName || '',
email: currentUser.email || 'A user of Rebrid'
};

const emailContents = emails.map(email => {
return createEmailContent(
`https://rebrid.co/activity/${activityId}`,
activityDetails.title,
activityDetails.description,
email,
senderDetails
);
});
try {
await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/share-activity`, {
emails,
emailContents,
activityId,
senderDetails
});
setMessage("Activity shared successfully!");
setEmails([]);
setTimeout(() => setMessage(""), 5000);
} catch (error) {
setMessage("Failed to share the activity. Please try again later.");
console.error("Error sharing activity:", error);
setTimeout(() => setMessage(""), 5000);
} finally {
setIsLoading(false);
}
};

const handleKeyPress = (event) => {
if (event.key === 'Enter') {
event.preventDefault();
handleAddMore();
}
};



return ReactDOM.createPortal(
<div className="ShareModal">
<div className="ShareModal-content">
<img src={CloseIcon} alt="Close" className="close-icon" onClick={onClose} />
<h2>Share</h2>
<form onSubmit={handleShare}>
<div className="email-input-container">
<InputBox
title="Email"
helpText="Receiver's email"
type="email"
size="large"
value={emailInput}
onChange={(e) => handleEmailChange(e.target.value)}
onKeyPress={handleKeyPress}
/>
<Button variant='tertiary' withIcon={true} icon={addIcon} label='Add' className="add-button" type="button" handleClick={handleAddMore} />
</div>

<div className='email-to-share'>
<div className="email-list">
{emails.map((email, index) => (
<div className='key-index' key={index}>
<div className={`email-tag ${duplicateEmail === email ? 'duplicate-email' : ''}`}>
{renderEmailDetails(userDetails[email], email)}
<img className='delete-button' src={Delete} alt="delete-icon" onClick={() => handleDeleteEmail(index)} />
</div>
{index < emails.length - 1 && <hr />}
</div>
))}
</div>
{message && (
<div className="message-container">
<p className={isLoading ? 'loading' : ''}>{message}</p>
</div>
)}
</div>

<div className="modal-buttons">
<Button label="Cancel" variant="secondary" handleClick={onClose} size='medium' />
<Button label={isLoading ? "Sharing..." : "Send"} variant={isLoading ? "submit" : "primary"} size='medium' type="submit" />


</div>
</form>
</div>
</div>,
document.body
);
};

// Helper function to render user details or placeholders based on the email
function renderEmailDetails(userDetail, email) {
if (userDetail && userDetail.exists) {
const name = userDetail.userName || (userDetail.firstName || userDetail.lastName) ? `${userDetail.firstName} ${userDetail.lastName}` : email;
const profileImage = userDetail.profilePhoto ? (
<img src={userDetail.profilePhoto} alt="Profile" />
) : (
<div className="placeholder-circle">{email.charAt(0).toUpperCase()}</div>
);

return (
<div className="user-details">
{profileImage}
<div className='user-share-info'>
<h6>{name.trim()}</h6>
<p>{email}</p>
</div>
</div>
);
} else {
return (
<div className="user-placeholder">
<div className="placeholder-circle">
{email.charAt(0).toUpperCase()}
</div>
<p>{email}</p>
</div>
);
}
}

export default ShareModal;
