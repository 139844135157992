import React, { useState } from 'react';
import ShareIcon from '../../assets/icons/share.svg';
import ShareModal from '../sharemodal/ShareModal';
import './ShareButton.css';

const ShareButton = ({ label, type, activityId, size, animation }) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const handleShareClick = (e) => {
    e.stopPropagation();
    setShowShareModal(true);
  };

  const handleCloseModal = () => {
    setShowShareModal(false);
  };

  const buttonClass = `shareButton-primary btn-${size} ${animation}`;

  return (
    <>
      <button
        type={type}
        className={buttonClass}
        onClick={handleShareClick}
      >
        <div className="btn-icon-text">
          <img src={ShareIcon} alt="button icon" className="icon" />
          <p>{label}</p>
        </div>
      </button>
      {showShareModal && <ShareModal activityId={activityId} onClose={handleCloseModal} />}
    </>
  );
};

export default ShareButton;
