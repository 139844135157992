import React, { useEffect, useState, useContext } from "react";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InputBox from "../components/InputBox/InputBox";
import PasswordBox from "../components/PasswordBox/PasswordBox";
import Button from "../components/button/Button";
import "./Login.css";
import Modal from "../components/modal/modal";
import UserContext from "../components/UserContext";
import VisitorWrapper from "../components/visitorwrapper/VisitorWrapper";
import { useMyBucketlist } from "../components/MyBucketlistContext";
import jwtDecode from "jwt-decode";
import GoogleIcon from "../assets/icons/Google.svg";
import {sanitizeInput} from "../components/utils/sanitizeInput";

const Login = () => {
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [validationTriggered, setValidationTriggered] = useState(false);
const [showModal, setShowModal] = useState(false);
const [modalTitle, setModalTitle] = useState("");
const [modalMessage, setModalMessage] = useState("");
const navigate = useNavigate();
const { setUserType } = useContext(UserContext);
const { dispatch } = useMyBucketlist();
const [isLoggingIn, setIsLoggingIn] = useState(false);

const getUserIdFromToken = () => {
const token = localStorage.getItem("token");
return token ? jwtDecode(token).userId : null;
};

useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const userType = urlParams.get('userType');
  
  if (token) {
  localStorage.setItem("token", token);
  setUserType(userType);
  
  // Redirect after a delay
  setTimeout(() => {
  navigate('/userDashboard');
  }, 2000);
  }
  }, [navigate, setUserType]);

const recordAnalytics = () => {
// Mock functions to collect necessary data
const getBrowserInfo = () => {
return "Mock Browser Info";
};

const getDeviceInformation = () => {
return "Mock Device Info";
};

const getGeolocation = () => {
return "Mock Geolocation";
};

// Get the actual data
const browserInfo = getBrowserInfo();
const deviceInformation = getDeviceInformation();
const geolocation = getGeolocation();
const date = new Date().toISOString();

// Prepare the data to send
const analyticsData = {
browserInfo,
deviceInformation,
geolocation,
date,
};

// Send a POST request to your server endpoint to record these data
axios
.post(
`${process.env.REACT_APP_SERVER_BASE_URL}/api/userAnalytics`,
analyticsData
)
.then((response) => {})
.catch((error) => {
console.error("Error recording analytics", error);
});
};

const handleGoogleLogin = () => {
window.location.href = `${process.env.REACT_APP_SERVER_BASE_URL}/user/google`;
};

const handleLogin = (event) => {
  event.preventDefault();
  setIsLoggingIn(true);
  setValidationTriggered(true);

  if (!email || !password) {
    setIsLoggingIn(false);
    return;
  }

  const sanitizedEmail = sanitizeInput(email);
  const sanitizedPassword = sanitizeInput(password);

  const dataToSend = { email: sanitizedEmail, password: sanitizedPassword };

  axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/user/login`, dataToSend)
  .then((response) => {
    if (response.data && response.data.token) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userType", response.data.userType);
      setUserType(response.data.userType);

      // Redirect based on user type
      switch(response.data.userType) {
        case 'admin':
          navigate(`admin.rebrid.co/adminDashboard`);
          break;
        case 'business':
          navigate(`business.rebrid.co/businessDashboard`);
          break;
        case 'user':
          navigate(`/userDashboard`);
          break;
        default:
          navigate('/'); // Replace '/defaultRoute' with your fallback route
      }
    } else {
      // Handle scenario when there is no token in the response
      setIsLoggingIn(false);
      setShowModal(true);
      setModalTitle("Login Error");
      setModalMessage("Login failed, no token received. Please try again.");
    }
  })
  .catch((error) => {
    setIsLoggingIn(false);
    let errorMessage = "Something went wrong. Please try again later.";

    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    }

    setShowModal(true);
    setModalTitle("Login Failed");
    setModalMessage(errorMessage);
  });
}


const handleModalClose = () => {
setShowModal(false);
setIsLoggingIn(false);
};

const modalActions = [
  { label: "Try Again", onClick: handleModalClose, variant: "secondary" },
  { label: "Register", onClick: () => navigate("/register"), variant: "primary" },
];


const showModalForRedirection = (userType) => {
  if (userType === 'admin' || userType === 'provider') {
    setShowModal(true);
    setModalTitle("Unauthorized Access");
    setModalMessage(`You will be redirected to the ${userType} login page.`);
    setTimeout(() => {
      window.location.href = `https://${userType}.rebrid.co/login`;
    }, 5000);
  }
};

return (
<VisitorWrapper>
<Helmet>
                <link rel="canonical" href="https://www.rebrid.co/login" />
            </Helmet>
<div className="login-page">
  <div className="login-form-container">
<form onSubmit={handleLogin}>
<h1>Login</h1>
<div className="email">
<InputBox
title="Email"
helpText="Enter your email"
type="email"
size="large"
isRequired={true}
forceValidation={validationTriggered}
value={email}
onChange={(e) => setEmail(e.target.value)}
/>
</div>
<div className="login-password">
<PasswordBox
size="large"
isRequired={true}
title="Password"
loginBox={true}
forceValidation={validationTriggered}
onPasswordChange={(passwordValue) => setPassword(passwordValue)}
onPasswordsMatchChange={() => {}}
/>
</div>
<a href="/forgotPassword" className="forgot-password"><p>Forgot password</p></a>

<div className="login-button">
<Button
label={isLoggingIn ? "Logging In" : "Login"} // Change label based on isLoggingIn state
type="submit"
variant={isLoggingIn ? "submit" : "primary"} // Change variant based on isLoggingIn state
withIcon={false}
size="large"
/>
</div>
</form>
<div className="quick-login-section">
<hr className="quick-login-line" />
<div className="quick-login-text"><h4>Quick Login</h4></div>
<div className="google-login">
<Button
type="submit"
withIcon={true}
icon={GoogleIcon}
variant="secondary"
label="Login with Google"
handleClick={handleGoogleLogin}
size="large"
className="google-btn"
/>
</div>
</div>
</div>

<Modal
title={modalTitle}
message={modalMessage}
handleClose={handleModalClose}
show={showModal}
actions={modalActions}
/>
</div>
</VisitorWrapper>
);
};

export default Login;
