import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from 'react-helmet';
import InputBox from "../components/InputBox/InputBox";
import DatePicker from "../components/datepicker/DatePicker";
import Button from "../components/button/Button";
import "./UserProfile.css";
import jwtDecode from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import activityTypeKeywords from "../components/tags/activityType.json";
import moodOptions from "../components/tags/moodOptions.json";
import accessibilityOptions from "../components/tags/accessibilityOptions.json";
import MultiDropdown from "../components/dropdown/MultiDropdown";
import UserWrapper from "../components/userwrapper/UserWrapper";
import UploadBox from "../components/uploadbox/UploadBox";

function UserProfile() {
  const [userData, setUserData] = useState({ preferences: {} });
  const [profilePhotoFile, setProfilePhotoFile] = useState(null);
    const [headerImageFile, setHeaderImageFile] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [userId, setUserId] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [, setPreferencesSet] = useState(false);
  const [activityTypeKeywordsSelected, setActivityTypeKeywordsSelected] =
    useState([]);
  const [moodOptionsSelected, setMoodOptionsSelected] = useState([]);
  const [accessibilityOptionsSelected, setAccessibilityOptionsSelected] =
    useState([]);

    const handleFileSelect = (file, type) => {
      if (type === 'profile') {
          setProfilePhotoFile(file);
      } else {
          setHeaderImageFile(file);
      }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/upload`, {
            method: "POST",
            body: formData,
        });
        const data = await response.json();
        return data.Location; // Return the URL of the uploaded image
    } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
    }
};



  const fetchProfileData = useCallback((userId) => {
    fetch(
      `${process.env.REACT_APP_SERVER_BASE_URL}/user/profile?userId=${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
        setActivityTypeKeywordsSelected(
          formatPreferences(data.preferences.activityType, activityTypeKeywords)
        );
        setMoodOptionsSelected(
          formatPreferences(data.preferences.mood, moodOptions)
        );
        setAccessibilityOptionsSelected(
          formatPreferences(
            data.preferences.accessibility,
            accessibilityOptions
          )
        );
        setPreferencesSet(true);
      })
      .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token is not available");
      return;
    }
    try {
      const decoded = jwtDecode(token);
      setUserId(decoded.userId);
      fetchProfileData(decoded.userId);
    } catch (error) {
      console.error("Failed to decode the token:", error);
    }
  }, [fetchProfileData]);

  const toggleEdit = () => {
    setIsEditable((prevState) => !prevState);
  };

  const handleChange = (field, value) => {
    setHasChanges(true);

    setUserData((prevUserData) => {
      let updatedData = { ...prevUserData };
      if (field.startsWith("preferences.")) {
        const preferenceField = field.split(".")[1];
        updatedData.preferences = {
          ...updatedData.preferences,
          [preferenceField]: value,
        };
      } else {
        updatedData[field] = value;
      }
      return updatedData;
    });

    if (field === "dateOfBirth") {
      if (value && !isNaN(value.getTime())) {
        setUserData((prev) => ({
          ...prev,
          dateOfBirth: value.toISOString(),
        }));
      } else {
        setUserData((prev) => ({
          ...prev,
          dateOfBirth: null,
        }));
      }
    } else {
      setUserData({ ...userData, [field]: value });
    }
  };

  const formatPreferences = (preferences, options) => {
    return preferences.map((pref) => options.find((option) => option.value === pref)).filter(Boolean); // Filter out undefined items
  };

  const handleSave = async () => {
    if (!userId) {
        console.error("User ID is not available");
        return;
    }

    setIsSaving(true);

    try {
        // Initially, we're not changing the URLs in userData
        let updatedProfilePhoto = userData.profilePhoto;
        let updatedHeaderImage = userData.headerImage;

        // Upload profile photo if a new file is selected
        if (profilePhotoFile) {
            const profilePhotoUrl = await uploadImage(profilePhotoFile);
            updatedProfilePhoto = profilePhotoUrl;
        }

        // Upload header image if a new file is selected
        if (headerImageFile) {
            const headerImageUrl = await uploadImage(headerImageFile);
            updatedHeaderImage = headerImageUrl;
        }

        // Now send the updated profile data including new image URLs if they were uploaded
        const profileUpdateResponse = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/user/updateProfile`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userId,
                ...userData,
                profilePhoto: updatedProfilePhoto,
                headerImage: updatedHeaderImage,
                preferences: {
                    activityType: activityTypeKeywordsSelected.map((item) => item.value),
                    mood: moodOptionsSelected.map((item) => item.value),
                    accessibility: accessibilityOptionsSelected.map((item) => item.value),
                },
            }),
        });

        const updatedData = await profileUpdateResponse.json();
        fetchProfileData(userId);
        toast.success("Profile updated successfully", { autoClose: 3000 });

        setIsEditable(false);
        window.location.reload();
        setIsSaving(false);
    } catch (error) {
        console.error("Error updating profile:", error);
        toast.error("Error updating profile", { autoClose: 3000 });
        setIsSaving(false);
    }
};
  const removePhoto = () => {
    setUserData(
      (prevState) => {
        return {
          ...prevState,
          profilePhoto: null,
        };
      },
      () => {
        handleSave();
      }
    );
  };

  const removeHeaderPhoto = () => {
    setUserData(
      (prevState) => {
        return {
          ...prevState,
          headerImage: null,
        };
      },
      () => {
        handleSave();
      }
    );
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <UserWrapper>
      <Helmet>
        <link rel="canonical" href={`https://www.rebrid.co/userProfile`} />
      </Helmet>
      {userData.headerImage && (
        <div className="header-image-container-mobile">
          <img src={userData.headerImage} alt="Header" />
        </div>
      )}
      <div className="view-mode">
        <div className="user-profile">
          <div className="profile-hero">
            <div className="profile-photo-container">
              <div
                className={`profile-photo ${
                  !userData.profilePhoto ? "no-photo" : ""
                }`}
                style={
                  userData.profilePhoto
                    ? { backgroundImage: `url(${userData.profilePhoto})` }
                    : null
                }
              />
              <div className="profile-details">
                <div className="header">
                  <h2>
                    {`${userData.firstName} ${userData.lastName}`} /{" "}
                    {userData.userName}
                  </h2>
                </div>
                <div className="headLine-profile">
                  <h1>{userData.headline}</h1>
                </div>
                <div className="profile-activity-info">
                  <div className="profile-label">
                    <h6>Location</h6>
                    <p>
                      {userData.city}, {userData.country}
                    </p>
                  </div>
                  <div className="profile-label">
                    <h6>Member Since</h6>
                    <p>
                      {new Date(userData.registeredAt).toLocaleString(
                        "default",
                        {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }
                      )}
                    </p>
                  </div>
                  <div className="profile-label">
                    <h6>Activities BucketListed</h6>
                    <p>{userData.myBucketlistedActivities?.length || 0}</p>
                  </div>
                </div>
              </div>
            </div>
            {userData.headerImage && (
              <div className="header-image-container-wide">
                <img
                  src={userData.headerImage}
                  alt="Header"
                  className="header-image"
                />
              </div>
            )}
          </div>
          <Button
            label={isEditable ? (isSaving ? "Saving..." : "Save") : "Edit"}
            variant={isSaving ? "disabled" : "primary"}
            size="small"
            handleClick={isEditable && !isSaving ? handleSave : toggleEdit}
            isDisabled={isEditable && (!userId || !hasChanges || isSaving)}
          />
        </div>

        {/* Edit Mode */}
        {isEditable && (
          <div className="edit-mode">
            <hr />
            <h1> Your Profile Details </h1>
            <div className="profile-form">
              <div className="profile-headline-input">
                <h3>My Profile Headline</h3>
                <InputBox
                  title="Profile Headline"
                  value={userData.headline}
                  onChange={(e) => handleChange("headline", e.target.value)}
                  size="large"
                />
              </div>
              <h3>My Profile Images</h3>
              <div className="user-buttons">
                <div className="photo-buttons">
                  <UploadBox
                    size="large"
                    initialImage={userData.profilePhoto}
                    onChange={(e) => handleFileSelect(e.target.files[0], 'profile')}
                    label="Upload Profile Photo"
                  />
                  <Button
                    variant="tertiary"
                    size="small"
                    handleClick={removePhoto}
                    label="Remove Photo"
                  />
                </div>
                <div className="photo-buttons">
                  <UploadBox
                    size="large"
                    initialImage={userData.headerImage}
                    onChange={(e) => handleFileSelect(e.target.files[0], 'header')}
                    label="Upload Header Image"
                  />
                  <Button
                    variant="tertiary"
                    size="small"
                    handleClick={removeHeaderPhoto}
                    label="Remove Photo"
                  />
                </div>
              </div>
              <div className="profile-information">
                <h3> My Information</h3>
                <div className="profile-input-columns">
                  <div className="input-row-columns">
                    <InputBox
                      title="First Name"
                      value={userData.firstName}
                      onChange={(e) =>
                        handleChange("firstName", e.target.value)
                      }
                      size="large"
                    />
                    <InputBox
                      title="Last Name"
                      value={userData.lastName}
                      onChange={(e) => handleChange("lastName", e.target.value)}
                      size="large"
                    />
                    <InputBox
                      title="Username"
                      value={userData.userName}
                      onChange={(e) => handleChange("userName", e.target.value)}
                      size="large"
                    />
                  </div>
                  <div className="input-row-columns">
                    <InputBox
                      title="City"
                      value={userData.city}
                      onChange={(e) => handleChange("city", e.target.value)}
                      size="large"
                    />
                    <InputBox
                      title="Country"
                      value={userData.country}
                      onChange={(e) => handleChange("country", e.target.value)}
                      size="large"
                    />
                    <DatePicker
                      title="Date of Birth"
                      selectedDate={
                        userData.dateOfBirth
                          ? new Date(userData.dateOfBirth)
                          : null
                      }
                      size="large"
                      isRequired={false}
                      onDateChange={(date) => handleChange("dateOfBirth", date)}
                    />
                  </div>
                </div>
                <div className="password-change-section" >
                  <h3>Change My Existing Password</h3>
                  {!userData.googleRegistration && (
                    <div className="password-change-button">
                    <Button
                      variant="secondary"
                      size="medium"
                      to="/changepassword"
                      label="Change Password"
                    />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Personalization */}
        <div className="personalization-container">
          <hr />
          <div className="preferences-section-title">
            <h1>Your Preferences</h1>
            <p>
              Below are your selected preferences. To get a most accurate
              personalized experience, please click the Edit button and fill in
              as much detail as possible.
            </p>
          </div>
          {isEditable ? (
            <div className="personalization-dropdowns">
              <MultiDropdown
                title="Activity Type"
                options={activityTypeKeywords}
                size="large"
                value={activityTypeKeywordsSelected}
                onChange={setActivityTypeKeywordsSelected}
              />
              <MultiDropdown
                title="Mood Options"
                options={moodOptions}
                size="large"
                value={moodOptionsSelected}
                onChange={setMoodOptionsSelected}
              />
              <MultiDropdown
                title="Accessibility Options"
                options={accessibilityOptions}
                size="large"
                value={accessibilityOptionsSelected}
                onChange={setAccessibilityOptionsSelected}
              />
              <Button
                label={isEditable ? (isSaving ? "Saving..." : "Save") : "Edit"}
                variant={isSaving ? "disabled" : "primary"}
                size="small"
                handleClick={isEditable && !isSaving ? handleSave : toggleEdit}
                isDisabled={isEditable && (!userId || !hasChanges || isSaving)}
              />
            </div>
          ) : (
            <div>
              <div className="preferences-selection-category">
              {[
  {
    title: "Activity Type Preferences",
    preferences: activityTypeKeywordsSelected,
  },
  {
    title: "Mood Preferences",
    preferences: moodOptionsSelected,
  },
  {
    title: "Accessibility Preferences",
    preferences: accessibilityOptionsSelected,
  },
].map((category) => (
  <div className="preference-category" key={category.title}>
    <h4>{category.title}</h4>
    <div className="preference-details">
      {category.preferences.length > 0 ? (
        category.preferences.map((keyword) => keyword && (
          <span
            key={keyword.value}
            className="preference-capsule"
          >
            {keyword.label}
          </span>
        ))
      ) : (
        <p>No preferences selected for this section</p>
      )}
    </div>
  </div>
))}
              </div>
            </div>
          )}
        </div>
        <ToastContainer />
      </div>
    </UserWrapper>
  );
}
export default UserProfile;
