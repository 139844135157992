import React, { useState } from "react";
import "./PromotionModal.css";
import InputBox from "../InputBox/InputBox";
import Button from "../button/Button";
import Selector from "../selector/Selector";
import InfoIcon from "../../assets/icons/info.svg";

const PromotionModal = ({ selectedActivity, show, handleClose }) => {
const [promotionType, setPromotionType] = useState(null);
const [step, setStep] = useState(1);
const [duration, setDuration] = useState("");
const [costPerClick, setCostPerClick] = useState("");

const calculateExpiryDate = (days) => {
const expiryDate = new Date();
expiryDate.setDate(expiryDate.getDate() + parseInt(days, 10));
return `${expiryDate.getDate()}/${
expiryDate.getMonth() + 1
}/${expiryDate.getFullYear()} 00:00am`;
};

const calculateClicks = (euros) => {
return euros / 0.01;
};

const handleNext = () => {
if (step === 1) {
if (
!promotionType ||
(promotionType === "premium" && !duration) ||
(promotionType === "featured" && !costPerClick) ||
(promotionType === "spotlighted" && (!duration || !costPerClick))
) {
// TODO: Handle validation error, like showing a message to the user
return;
}
setStep(2);
} else if (step === 2) {
// Prepare the payload to send to the server
const expiryDate = new Date();
if (promotionType === "premium" || promotionType === "spotlighted") {
expiryDate.setDate(expiryDate.getDate() + parseInt(duration, 10));
}
const payload = {
activityId: selectedActivity._id,
promotionType: promotionType,
isPremiumPromotion: promotionType === "premium",
premiumDuration: promotionType === "premium" ? parseInt(duration, 10) : 0,
isFeaturedPromotion: promotionType === "featured",
featuredCostPerClick: promotionType === "featured" ? parseFloat(costPerClick) : 0,
isSpotlighted: promotionType === "spotlighted",
spotlightedDuration: promotionType === "spotlighted" ? parseInt(duration, 10) : 0,
spotlightedCostPerClick: promotionType === "spotlighted" ? parseFloat(costPerClick) : 0,
promotionCreationDate: new Date(),
expiryDate: expiryDate
};

// Send the payload to the server
fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/promotion`, {
method: "POST",
headers: {
"Content-Type": "application/json",
},
body: JSON.stringify(payload),
})
.then((response) => response.json())
.then((data) => {
})
.catch((error) => {
console.error("Error:", error);
});

// Continue to close the modal
handleClose();
}
};

const handleBack = () => {
if (step === 2) {
setStep(1);
}
};

return (
<>
{show && (
<div className="promotion-modal">
<div className="promotional-content">
{step === 1 && (
<>
<h1>Create Promotion</h1>
{selectedActivity && (
<>
<h6>Activity Title</h6>
<h3>{selectedActivity?.title}</h3>
<div className="provider-location-logo">
<img
src={selectedActivity?.logo}
alt="Provider Logo"
className="provider-logo"
/>
<div className="providers-location">
<h5>{selectedActivity?.provider}</h5>
<h6>
{selectedActivity?.city}, {selectedActivity?.country}
</h6>
</div>
</div>
</>
)}
<div className="radio-promotion">
<h6> Select Promotion Type</h6>
<Selector
type="radio"
options={[
{ value: "premium", label: "Premium" },
{ value: "featured", label: "Featured" },
{ value: "spotlighted", label: "Spotlighted" },
]}
checked={promotionType}
onChange={setPromotionType}
/>
</div>
{promotionType === "premium" && (
<div className="input-info">
<InputBox
size="medium"
title="Duration in days"
helpText="Number only"
suffix="days"
value={duration}
onChange={(e) => setDuration(e.target.value)}
/>
<img
src={InfoIcon}
alt="Info"
title="Duration determines the promotion's expiration date."
/>
<div className="expiry-time">
<h6>The Promotion will expire on</h6>
<p>{calculateExpiryDate(duration)}</p>
</div>
</div>
)}
{promotionType === "featured" && (
<>
<div className="input-info">
<InputBox
size="medium"
title="Cost per click limit (in €)"
helpText="In Euros (0.01 Euro / click)"
value={costPerClick}
onChange={(e) => setCostPerClick(e.target.value)}
/>
<img
src={InfoIcon}
alt="Info"
title="Determine the number of clicks based on the cost limit."
/>
<div className="expiry-time">
<h6>Allotted Clicks</h6>
<p>{calculateClicks(costPerClick)}</p>
</div>
</div>
</>
)}
{promotionType === "spotlighted" && (
<div className="spotlighted-section">
<div className="input-group">
<InputBox
size="medium"
title="Duration in days"
helpText="Number only"
suffix="days"
value={duration}
onChange={(e) => setDuration(e.target.value)}
/>
<div className="divider"></div>
<InputBox
size="medium"
title="Cost per click limit (in €)"
helpText="In Euros (0.01 Euro / click)"
value={costPerClick}
onChange={(e) => setCostPerClick(e.target.value)}
/>
</div>

<div className="expiry-conditions">
<img
src={InfoIcon}
alt="Info"
title="The spotlighted promotion will expire either after the duration is over or after the allotted number of clicks is reached, whichever comes first."
/>
<p>Ends on {calculateExpiryDate(duration)}</p>
<p>OR</p>
<p>After {calculateClicks(costPerClick)} clicks</p>
</div>
</div>
)}
</>
)}
{step === 2 && (
<>
<h1>Verify Details</h1>
<h6>Activity Title</h6>
<h3>{selectedActivity?.title}</h3>
<div className="provider-location-logo">
<img
src={selectedActivity?.logo}
alt="Provider Logo"
className="provider-logo"
/>
<div className="providers-location">
<h5>{selectedActivity?.provider}</h5>
<h6>
Location: {selectedActivity?.city},{" "}
{selectedActivity?.country}
</h6>
</div>
</div>
<div className="selection-details">
<h6>Promotion Type</h6>
<p>{promotionType}</p>
{promotionType === "premium" && (
<div className="selection-details">
<h6>Duration</h6>
<p>
{duration} days (Expiry Date:{" "}
{calculateExpiryDate(duration)})
</p>
</div>
)}
{promotionType === "featured" && (
<div className="selection-details">
<h6>Cost per click limit</h6>
<p>
{" "}
{costPerClick} Euros (Number of Clicks:{" "}
{calculateClicks(costPerClick)})
</p>
</div>
)}
{promotionType === "spotlighted" && (
<>
<div className="selection-details">
<h6>Duration</h6>
<p>
{duration} days (Expiry Date:{" "}
{calculateExpiryDate(duration)})
</p>
<h6>Cost per click limit</h6>
<p>
{" "}
{costPerClick} Euros (Number of Clicks:{" "}
{calculateClicks(costPerClick)})
</p>
</div>
</>
)}
</div>
<div className="verification-confirmation-text">
<p>
Please verify the above details before proceeding with the
promotion creation.
</p>
</div>
</>
)}

<div className="modal-actions">
<Button
variant="tertiary"
label={step === 1 ? "Cancel" : "Back"}
handleClick={step === 1 ? handleClose : handleBack}
/>
<Button
size="medium"
variant="primary"
label={step === 1 ? "Next" : "Create Promotion"}
handleClick={handleNext}
/>
</div>
</div>
</div>
)}
</>
);
};

export default PromotionModal;
