import React, { useState,useEffect } from 'react';
import axios from 'axios';
import InputBox from '../InputBox/InputBox';
import Button from '../button/Button';
import './KpiModal.css';

const KpiModal = ({ isOpen, onClose, onSave }) => {
const [activitiesPosted, setActivitiesPosted] = useState('');
const [blogsPosted, setBlogsPosted] = useState('');
const [newUsers, setNewUsers] = useState('');
const [viewsGenerated, setViewsGenerated] = useState('');

const handleSubmit = () => {
const numericTargets = {
activitiesPosted: activitiesPosted ? Number(activitiesPosted) : 0,
blogsPosted: blogsPosted ? Number(blogsPosted) : 0,
newUsers: newUsers ? Number(newUsers) : 0,
viewsGenerated: viewsGenerated ? Number(viewsGenerated) : 0,
year: new Date().getFullYear()
};

axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/analytics/kpi-targets`, numericTargets)
.then(response => {
console.log("Successfully saved KPI targets:", response.data);
onSave(numericTargets);  // Call the onSave function here
})
.catch(error => {
console.error("Error saving KPI targets:", error);
})
.finally(() => {
onClose();
});
};

return isOpen ? (
<div className="modal-overlay" onClick={onClose}>
<div className="modal-contents kpi-modal-content" onClick={e => e.stopPropagation()}>
<h1 className="kpi-modal-title">Set Annual Targets</h1>

<InputBox 
title="Activities Posted"
type="number"
value={activitiesPosted.toString()}
onChange={(e) => setActivitiesPosted(e.target.value)}
name="activitiesPosted"
size='large'
/>

<InputBox 
title="Blogs Posted"
type="number"
value={blogsPosted.toString()}
onChange={(e) => setBlogsPosted(e.target.value)}
name="blogsPosted"
size='large'
/>

<InputBox 
title="New Users"
type="number"
value={newUsers.toString()}
onChange={(e) => setNewUsers(e.target.value)}
name="newUsers"
size='large'
/>

<InputBox 
title="Views Generated"
type="number"
value={viewsGenerated.toString()}
onChange={(e) => setViewsGenerated(e.target.value)}
name="viewsGenerated"
size='large'
/>

<div className="kpi-modal-buttons"> 
<Button 
label="Save Targets" 
type="button"
variant="primary"
size="small"
handleClick={handleSubmit}
/>
<Button 
label="Cancel" 
type="button"
variant="tertiary"
size="small"
handleClick={onClose}
/>
</div>

</div>
</div>
) : null;
};

export default KpiModal;
