import React, { useState, useRef } from 'react';
import './ActivityHover.css';
import pinIcon from '../../assets/icons/location.svg';
import durationIcon from '../../assets/icons/timer-color-outline.svg';
import LocationIcon from '../../assets/icons/pin-color-outline.svg';
import ActivityHoverCardModal from '../modal/ActivityHoverCardModal';
import axios from "axios";

const ActivityHover = ({ serverBaseUrl, planId,  activity, onCardClick, onCardDrag, isInActivitiesSection, editModes, itineraries, promptRemoveActivity, onRemoveActivity, onOpenActivityPage, onAddToItinerary, onToggleEditMode, itineraryId , onRemove, ...props }) => {

const [isDragging, setIsDragging] = useState(false);
const [showModal, setShowModal] = useState(false);
const [isVisible, setIsVisible] = useState(true); 
const dragItemNode = useRef();
const isEdit = editModes;
console.log(`ActivityHover for ${activity._id} isVisible prop: ${isVisible}`);


if (!isVisible) {
  console.log(`Not rendering ActivityHover for ${activity._id} due to isVisible being false`);
  return null;
}

const handleRemoveActivity = async () => {
  if (isInActivitiesSection) {
    // Assume this makes an API call to remove from plan's activities
    try {
      const response = await axios.delete(`${serverBaseUrl}/api/plans/${planId}/activities`, { params: { activityId: activity._id } });
      if (response.status === 200) {
        setIsVisible(false);
      }
    } catch (error) {
      console.error("Error in activity removal:", error);
    }
  } else {
    // For removing from a specific itinerary, just call the onRemoveActivity prop
    onRemoveActivity(activity._id, itineraryId);
  }
};



if (!activity || activity.isDisabled || !isVisible) {
return null;
}

const { _id, title, headerImage, city, country, duration, location } = activity;
const truncatedTitle = title && title.length > 30 ? `${title.substr(0, 30)}...` : title || '';

const handleCardClick = () => {
if (!isDragging) {
setShowModal(true);
if (typeof onCardClick === 'function') {
onCardClick(activity, itineraryId, isEdit[itineraryId]);
}
}
};

const closeModal = () => {
setShowModal(currentShowModal => {
const updatedState = !currentShowModal;
return updatedState;
});
};

const handleDragStart = (e) => {
e.dataTransfer.setData('activityId', activity._id);
setIsDragging(true);

// Cloning the entire card element
const dragImage = e.currentTarget.cloneNode(true);
dragImage.style.position = 'absolute';
dragImage.style.top = '-1000px'; // Position off-screen
document.body.appendChild(dragImage);

// Set the custom drag image and position it near the cursor
e.dataTransfer.setDragImage(dragImage, e.currentTarget.offsetWidth / 2, e.currentTarget.offsetHeight / 2);

console.log('Dragging Started:', activity._id); // Debugging line
if (typeof onCardDrag === 'function') {
onCardDrag(activity, -1); // Assuming -1 is for activities not in any column
}

// Clean up after drag operation ends
setTimeout(() => {
document.body.removeChild(dragImage);
}, 0);
};

const handleOpenActivityPage = (activityId) => {
window.open(`/activity/${activityId}`, '_blank');
};

const handleDragEnd = () => {
setIsDragging(false);
if (dragItemNode.current) {
dragItemNode.current.style.opacity = "1"; // Reset the opacity when dragging ends
}
console.log('Drag Ended:', activity._id); // Debugging line
};

const isAnyItineraryInEditMode = editModes && itineraries && itineraries.some(itinerary => editModes[itinerary._id]);


return (
<div 
className={`activityHover ${isDragging ? 'dragging' : ''}`} 
onClick={handleCardClick} 
draggable 
onDragStart={handleDragStart} 
onDragEnd={handleDragEnd}
>
<div className='activityhover-image-title'>
<div className='header-section'>
<img className="activity-card-header-image" src={headerImage} alt={title} />
</div>
<div className='activityhover-title'>
<div className="title-line">
<h6 className="title-card">{truncatedTitle}</h6>
</div>
<div className='country-city-activity'>
<p><img src={LocationIcon} className='LocationIcon-activity-hover' alt="" />{location}</p>
</div>
<div className='country-city-activity'>
<p><img src={pinIcon} className='pinIcon-activity-hover' alt="" />{city}, {country}</p>
</div>
<div className='activityHover-duration'>
<p><img src={durationIcon} className='duration-activity-hover' alt="" />{duration}</p>
</div>
</div>
</div>

{showModal && (
<ActivityHoverCardModal
activity={activity}
isInActivitiesSection={isInActivitiesSection}
isEditMode={isAnyItineraryInEditMode}
itineraries={itineraries || []}
onOpenActivityPage={handleOpenActivityPage}
onRemoveActivity={handleRemoveActivity}
onClose={closeModal}
onAddToItinerary={onAddToItinerary}
onToggleEditMode={onToggleEditMode}
isEdit={isEdit}
/>
)}
</div>
);
};

export default ActivityHover;
