import React, { useState, useEffect } from 'react';
import './ImagesModal.css';
import closeIcon from '../../assets/icons/close.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import Button from '../button/Button';
import Selector from '../selector/Selector';

const ImagesModal = ({ isOpen, onClose, onUploadSelected, initialImages }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const validatedImages = initialImages.map((image) => {
            const fileType = image.originalFile.type.split('/')[1];
            const isFileTypeValid = ['jpg', 'jpeg', 'png', 'webp'].includes(fileType.toLowerCase());
            const isFileSizeValid = image.originalFile.size <= 4 * 1024 * 1024;
            return {
                ...image,
                isCompatible: isFileTypeValid && isFileSizeValid,
                selected: isFileTypeValid && isFileSizeValid,
                sizeText: `${(image.originalFile.size / 1024 / 1024).toFixed(2)} MB`,
                fileSizeValid: isFileSizeValid,
                fileTypeValid: isFileTypeValid,
                errorMessage: isFileSizeValid ? (isFileTypeValid ? "Ready for upload" : "Error: Incorrect file format") : "Error: File size above 4MB",
            };
        });
        setImages(validatedImages);
    }, [initialImages]);

    const handleDelete = (index) => {
        setImages(images.filter((_, i) => i !== index));
    };

    const handleSelect = (index) => {
        setImages(images.map((img, i) => i === index ? { ...img, selected: !img.selected } : img));
    };

    const handleUpload = () => {
        const selectedFiles = images.filter(image => image.selected);
        onUploadSelected(selectedFiles.map(image => image.originalFile));
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content-images">
                <div className="modal-header">
                    <h2>Selected Images</h2>
                    <button className="modal-close-button" onClick={onClose}>
                        <img src={closeIcon} alt="Close" />
                    </button>
                </div>
                <div className="images-table-container">
                    <table className="images-table">
                        <tbody>
                            {images.map((image, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td>
                                            {image.fileSizeValid && image.fileTypeValid && (
                                                <Selector
                                                    type="checkbox"
                                                    label=""
                                                    options={[{ value: 'selected', label: '' }]}
                                                    checked={image.selected ? ['selected'] : []}
                                                    onChange={(newValue) => handleSelect(index, newValue)}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            <img
                                                src={image.preview}
                                                alt="Preview"
                                                className={`thumbnail-img ${!image.fileSizeValid || !image.fileTypeValid ? 'grayscale' : ''}`}
                                            />
                                        </td>
                                        <td>
                                            <p className="file-size">{image.sizeText}</p>
                                        </td>
                                        <td className={image.fileSizeValid && image.fileTypeValid ? 'ready-message' : 'error-message-images'}>
                                            {image.errorMessage}
                                        </td>
                                        <td>
                                            {image.fileSizeValid && (
                                                <button onClick={() => handleDelete(index)} className="delete-button-images">
                                                    <img src={deleteIcon} alt="Delete" className="delete-icon" />
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                    <tr><td colSpan="5"><hr className='horizontal-line-table'/></td></tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="modal-footer">
                    <Button variant='primary' size='small' handleClick={handleUpload} disabled={images.every(image => !image.selected)} label='Upload Selected'></Button>
                </div>
            </div>
        </div>
    );
};

export default ImagesModal;