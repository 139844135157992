import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIconWhite from '../../assets/icons/search-white.svg';
import SearchIconBlack from '../../assets/icons/search-black.svg';
import './SearchBar.css';
import SearchModal from '../modal/SearchModal';

const SearchBar = ({ variant = "default" }) => {
const [searchValue, setSearchValue] = useState('');
const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
const navigate = useNavigate();
const [showModal, setShowModal] = useState(false);

const handleModalSearch = (value) => {
    setShowModal(false);
    if (value.trim()) {
      const params = new URLSearchParams();
      params.append('search', value);
      navigate(`/searchResult?${params.toString()}`);
    }
  };

useEffect(() => {
const handleResize = () => {
setIsMobile(window.innerWidth <= 1024);
};
window.addEventListener('resize', handleResize);
return () => {
window.removeEventListener('resize', handleResize);
};
}, []);

const handleSearch = (event) => {
event.preventDefault();
if (searchValue.trim()) {
const params = new URLSearchParams();
params.append('search', searchValue);
navigate(`/searchResult?${params.toString()}`);
}
};

if (variant === "search-nav" && isMobile) {
    return (
      <>
        <button className="search-button" onClick={() => setShowModal(true)}>
          <img src={SearchIconBlack} alt="Search" />
        </button>
        <SearchModal 
          isOpen={showModal} 
          onClose={() => setShowModal(false)}
          onSearch={handleModalSearch}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </>
    );
  }

return (
<form onSubmit={handleSearch} className={`searchbar-container ${variant}`}>
<img src={SearchIconWhite} alt="Search" className="search-icon" />
<input 
type="text" 
placeholder="Search a Location"
value={searchValue} 
onChange={e => setSearchValue(e.target.value)} 
/>
<button type="submit" className="search-button">
<img src={SearchIconWhite} alt="Search" />
</button>
</form>
);
};

export default SearchBar;
