import React, { useState, useEffect } from 'react';
import InputBox from '../components/InputBox/InputBox';
import Button from '../components/button/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import './SetNewPassword.css';
import {sanitizeInput} from "../components/utils/sanitizeInput";

const SetNewPassword = () => {
const [newPassword, setNewPassword] = useState('');
const [confirmPassword, setConfirmPassword] = useState('');
const [message, setMessage] = useState('');
const [redirectCountdown, setRedirectCountdown] = useState(null);
const [isSubmitting, setIsSubmitting] = useState(false);
const navigate = useNavigate();

useEffect(() => {
const token = localStorage.getItem('token');
if (!token) {
navigate('/login');
return;
}

const fetchUserData = async () => {
try {
const decoded = jwtDecode(token);
const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/user/profile?userId=${decoded.userId}`, {
headers: { Authorization: `Bearer ${token}` }
});

if (response.data.googleRegistration) {
navigate('/error404');
return;
}
} catch (error) {
console.error('Error fetching user data:', error);
}
};

fetchUserData();

let interval;
if (redirectCountdown > 0) {
interval = setInterval(() => {
setRedirectCountdown((prevCountdown) => prevCountdown - 1);
}, 1000);
} else if (redirectCountdown === 0) {
navigate('/userProfile');
}

return () => clearInterval(interval);
}, [redirectCountdown, navigate]);

const handleChangePassword = async () => {
    // Sanitize new password and confirm password inputs
    const sanitizedNewPassword = sanitizeInput(newPassword);
    const sanitizedConfirmPassword = sanitizeInput(confirmPassword);

    if (sanitizedNewPassword !== sanitizedConfirmPassword) {
        setMessage('Passwords do not match.');
        return;
    }

    try {
        setIsSubmitting(true);
        const token = localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/change-password`, { newPassword: sanitizedNewPassword }, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setMessage('Password has been changed successfully. Navigating you to the user profile page in...');
        setRedirectCountdown(5);
    } catch (error) {
if (error.response && error.response.data) {
setMessage(error.response.data.message);
} else {
setMessage('An error occurred. Please try again.');
}
} finally {
setIsSubmitting(false);
}
};

return (
<div className='set-new-password-container'>
<h1>Set New Password</h1>

{redirectCountdown === null && (
<>
<InputBox
className='input-box'
size='large'
title="New Password"
variant="password"
value={newPassword}
onChange={(e) => setNewPassword(e.target.value)}
/>
<InputBox
className='input-box'
size='large'
title="Confirm Password"
variant="password"
value={confirmPassword}
onChange={(e) => setConfirmPassword(e.target.value)}
/>
<Button 
size='large' 
variant='primary' 
label={isSubmitting ? 'Changing Password...' : 'Change Password'} 
handleClick={handleChangePassword} 
disabled={isSubmitting}
/>
<div className='error-message'>
{message && <p>{message} {redirectCountdown !== null && `${redirectCountdown}s`}</p>}
</div>
</>
)}
</div>
);
};

export default SetNewPassword;
