import React from 'react';
import { Link } from 'react-router-dom';
import countriesAndCities from '../components/keywords/cities.json';
import categories from '../components/tags/subCategory.json';
import seasonOptions from '../components/tags/seasonOptions.json';
import accessibilityOptions from '../components/tags/accessibilityOptions.json';
import activityTypeOptions from '../components/tags/activityType.json';
import moodOptions from '../components/tags/moodOptions.json';
import ageLimitOptions from '../components/tags/ageLimitOptions.json';
import fitnessOptions from '../components/tags/fitnessLevelOptions.json';
import tags from '../components/tags/tags.json';
import './SiteMap.css';

const SiteMap = () => {
  return (
    <div className="sitemap">
      <div className='sitemap-category-section'>

      <div className="by-category">
      <h5>Primary Links</h5>
        <div className='sitemap-section'>
          <p><Link to="/">Home</Link></p>
          <p><Link to="/discover">Discover</Link></p>
          <p><Link to="/sitemap">Sitemap</Link></p>
          <p><Link to="/category">Category</Link></p>
          <p><Link to="/mybucketlist">My Bucketlist</Link></p>
          <p><Link to="/contactus">Contact Us</Link></p>
          <p><Link to="/tnc">Terms & Conditions</Link></p>
          <p><Link to="/privacyPolicy">Privacy Policy</Link></p>
        </div>
      </div>

        <div className='by-category'>
          <h5>Activities By Country</h5>
          <div className="sitemap-section">
            {Object.keys(countriesAndCities).map(country => (
              <p key={country}>
                <Link to={`/location/${country}`}>Things to do in {country}</Link>
              </p>
            ))}
          </div>
        </div>

        <div className='by-category'>
          <h5>Activities By City</h5>
          <div className="sitemap-section">
            {Object.entries(countriesAndCities).flatMap(([country, cities]) => 
              cities.map(city => (
                <p key={city}>
                  <Link to={`/searchResult?search=${city}`}>Things to do in {city}</Link>
                </p>
              ))
            )}
          </div>
        </div>

        <div className='by-category'>
          <h5>By Categories</h5>
          <div className="sitemap-section">
            {categories.map(({ label, value }) => (
              <p key={value}>
                <Link to={`/category/${value}`}>{label}</Link>
              </p>
            ))}
          </div>
        </div>

        <div className='by-category'>
          <h5>Accessibility</h5>
          <div className="sitemap-section">
            {accessibilityOptions.map(({ label, value }) => (
              <p key={value}>
                <Link to={`/searchResult?search=${value}`}>{label} activities</Link>
              </p>
            ))}
          </div>
        </div>

        <div className='by-category'>
          <h5>By Season</h5>
          <div className="sitemap-section">
            {seasonOptions.map(({ label, value }) => (
              <p key={value}>
                <Link to={`/searchResult?search=${value}`}>Things to do during the {label} season</Link>
              </p>
            ))}
          </div>
        </div>

        <div className='by-category'>
          <h5>By Type</h5>
          <div className="sitemap-section">
            {activityTypeOptions.map(({ label, value }) => (
              <p key={value}>
                <Link to={`/searchResult?search=${value}`}>{label}</Link>
              </p>
            ))}
          </div>
        </div>

        <div className='by-category'>
          <h5>By Mood</h5>
          <div className="sitemap-section">
            {moodOptions.map(({ label, value }) => (
              <p key={value}>
                <Link to={`/searchResult?search=${value}`}>{label} activities</Link>
              </p>
            ))}
          </div>
        </div>

        <div className='by-category'>
          <h5>By Fitness Level</h5>
          <div className="sitemap-section">
            {fitnessOptions.map(({ label, value }) => (
              <p key={value}>
                <Link to={`/searchResult?search=${value}`}>{label} friendly activities</Link>
              </p>
            ))}
          </div>
        </div>

        <div className='by-category'>
          <h5>By Age</h5>
          <div className="sitemap-section">
            {ageLimitOptions.map(({ label, value }) => (
              <p key={value}>
                <Link to={`/searchResult?search=${value}`}>{label} friendly activities</Link>
              </p>
            ))}
          </div>
        </div>

        <div className='by-category'>
          <h5>Tags</h5>
          <div className="sitemap-section">
            {tags.map(({ label, value }) => (
              <p key={value}>
                <Link to={`/searchResult?search=${value}`}>{label}</Link>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteMap;
