import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Dropdown from '../dropdown/Dropdown';
import KpiModal from '../modal/KpiModal';
import './KpiTracker.css';
import Button from '../button/Button';


const KpiTracker = () => {
const [targetMetrics, setTargetMetrics] = useState({
activitiesPosted: 0,
blogsPosted: 0,
newUsers: 0,
viewsGenerated: 0
});

const [metrics, setMetrics] = useState({
activitiesPosted: 0,
blogsPosted: 0,
newUsers: 0,
viewsGenerated: 0
});

const isKpiSet = Object.values(targetMetrics).some(val => val > 0);

const [targetValue, setTargetValue] = useState(0);
const [duration, setDuration] = useState('this week');
const [title, setTitle] = useState('Weekly Tracker');
const [isModalOpen, setIsModalOpen] = useState(false);

const durations = [
{ value: 'this week', label: 'This Week' },
{ value: 'this month', label: 'This Month' },
{ value: 'this quarter', label: 'This Quarter' },
{ value: 'this half-year', label: 'This Half-Year' },
{ value: 'this year', label: 'This Year' },
];


const [previousMetrics, setPreviousMetrics] = useState({
activitiesPosted: 0,
blogsPosted: 0,
newUsers: 0,
viewsGenerated: 0
});

useEffect(() => {
// Fetch metrics from server
axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/analytics/kpi?duration=${duration}`)
.then(response => {
console.log("Fetched KPI metrics:", response.data);
setMetrics(response.data);

// Compute rollovers
const rollovers = {
activitiesPosted: targetMetrics.activitiesPosted - response.data.activitiesPosted,
blogsPosted: targetMetrics.blogsPosted - response.data.blogsPosted,
newUsers: targetMetrics.newUsers - response.data.newUsers,
viewsGenerated: targetMetrics.viewsGenerated - response.data.viewsGenerated
};
setPreviousMetrics(rollovers);
})
.catch(error => {
console.error("Error fetching KPI metrics:", error);
});


// Fetch KPI targets for the current year
const currentYear = new Date().getFullYear();
axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/analytics/kpi-targets/${currentYear}`)
.then(response => {
const { activitiesPosted, blogsPosted, newUsers, viewsGenerated } = response.data;

// Calculate the scaled target based on the duration
let scalingFactor;
switch (duration) {
  case 'this week':
    scalingFactor = 1 / 52;
    break;
  case 'this month':
    scalingFactor = 1 / 12;
    break;
  case 'this quarter':
    scalingFactor = 1 / 4;
    break;
  case 'this half-year':
    scalingFactor = 1 / 2;
    break;
  case 'this year':
  default:
    scalingFactor = 1;
    break;
}

setTargetMetrics({
  activitiesPosted: activitiesPosted * scalingFactor,
  blogsPosted: blogsPosted * scalingFactor,
  newUsers: newUsers * scalingFactor,
  viewsGenerated: viewsGenerated * scalingFactor
});

})
.catch(error => {
console.error("Error fetching KPI targets for the year:", error);
});
}, [duration]);

const handleDropdownChange = (selectedOption) => {
setDuration(selectedOption.value);
setTitle(`${selectedOption.label} Tracker`);
};

const getProgressColor = (percentage) => {
if (percentage < 50) return 'red';
if (percentage >= 50 && percentage < 80) return 'yellow';
return 'green';
};

const handleSaveTargets = (newTargets) => {
const newTarget = (newTargets.activitiesPosted + newTargets.blogsPosted + newTargets.newUsers + newTargets.viewsGenerated) / 4;
setTargetValue(newTarget);
};

const calculateCircumference = (radius) => 2 * Math.PI * radius;

const renderProgressRing = (achieved, target) => {
const radius = 22;
const circumference = calculateCircumference(radius);
const percentage = target === 0 ? 0 : (achieved / target) * 100;
const offset = circumference - (percentage / 100) * circumference;

return (
<svg className="progress-ring" viewBox="0 0 50 50">
<circle
className="progress-ring__circle"
stroke="var(--gray1-color)"
strokeWidth="3"
fill="transparent"
r={radius}
cx="25"
cy="25" />
<circle
className="progress-ring__circle"
stroke={getProgressColor(percentage)}
strokeDasharray={circumference}
strokeDashoffset={offset}
r={radius}
cx="25"
cy="25" />
</svg>
);
};

return (
<div className="kpi-tracker">
<h2>{title}</h2>
<Dropdown
title="Select Duration"
options={durations}
value={duration}
onChange={handleDropdownChange}
/>
<div className="metric-item">
<h3>Activities Posted</h3>
{renderProgressRing(metrics.activitiesPosted, targetMetrics.activitiesPosted)}
<p>{metrics.activitiesPosted}/{Math.round(targetMetrics.activitiesPosted)}</p>
</div>
<div className="metric-item">
<h3>Blogs Posted</h3>
{renderProgressRing(metrics.blogsPosted, targetMetrics.blogsPosted)}
<p>{metrics.blogsPosted}/{Math.round(targetMetrics.blogsPosted)}</p>
</div>
<div className="metric-item">
<h3>New Users</h3>
{renderProgressRing(metrics.newUsers, targetMetrics.newUsers)}
<p>{metrics.newUsers}/{Math.round(targetMetrics.newUsers)}</p>
</div>
<div className="metric-item">
<h3>Views Generated</h3>
{renderProgressRing(metrics.viewsGenerated, targetMetrics.viewsGenerated)}
<p>{metrics.viewsGenerated}/{Math.round(targetMetrics.viewsGenerated)}</p>
</div>
{
isKpiSet ? (
<Button
variant="secondary"
label="Edit KPI"
handleClick={() => setIsModalOpen(true)}
/>
) : (
<>
<p style={{ textAlign: 'center' }}>No KPIs set</p>
<Button
variant="primary"
label="Create KPI"
handleClick={() => setIsModalOpen(true)}
style={{ margin: '16px auto', display: 'block' }}
/>
</>
)
}

<KpiModal
isOpen={isModalOpen}
onClose={() => setIsModalOpen(false)}
onSave={handleSaveTargets}
/>
</div>
);

}

export default KpiTracker;
