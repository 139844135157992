import React from 'react';

function Stats() {
  return (
    <div>
      <h1>This is the Stats page.</h1>
    </div>
  );
}

export default Stats;
