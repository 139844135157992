import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ManageActivities.css";
import Button from "../components/button/Button";
import AdminWrapper from "../components/adminwrapper/AdminWrapper";
import InputBox from "../components/InputBox/InputBox";
import Modal from "../components/modal/modal";
import PromotionModal from "../components/modal/PromotionModal";
import Dropdown from '../components/dropdown/Dropdown';

const ManageActivities = () => {
const [activities, setActivities] = useState([]);
const [searchTerm, setSearchTerm] = useState("");
const [showModal, setShowModal] = useState(false);
const [selectedActivity, setSelectedActivity] = useState(null);
const [showPromotionModal, setShowPromotionModal] = useState(false);
const [showDeactivatePromotionModal, setShowDeactivatePromotionModal] = useState(false);

const sortingOptions = [
    { value: 'title_asc', label: 'Title (A-Z)' },
    { value: 'title_desc', label: 'Title (Z-A)' },
    { value: 'views_desc', label: 'Most Views' },
    { value: 'views_asc', label: 'Least Views' },
    { value: 'postingTime_desc', label: 'Recently Updated' },
    { value: 'postingTime_asc', label: 'Least Recently Updated' },
  ];
  
  const [selectedSort, setSelectedSort] = useState(sortingOptions[0]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/activity`
        );
        let sortedActivities = response.data.data;
        
        // Sorting logic
        switch (selectedSort.value) {
          case 'title_asc':
            sortedActivities.sort((a, b) => a.title.localeCompare(b.title));
            break;
          case 'title_desc':
            sortedActivities.sort((a, b) => b.title.localeCompare(a.title));
            break;
          case 'views_desc':
            sortedActivities.sort((a, b) => b.views - a.views);
            break;
          case 'views_asc':
            sortedActivities.sort((a, b) => a.views - b.views);
            break;
          case 'postingTime_desc':
            sortedActivities.sort((a, b) => new Date(b.postingTime) - new Date(a.postingTime));
            break;
          case 'postingTime_asc':
            sortedActivities.sort((a, b) => new Date(a.postingTime) - new Date(b.postingTime));
            break;
          default:
            // Default sort or error handling
            break;
        }
  
        setActivities(sortedActivities);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };
  
    fetchData();
  }, [selectedSort]);
  

const handleCreatePromotionClick = (activity) => {
setSelectedActivity(activity);
setShowPromotionModal(true);
};

const handleSearchChange = (e) => {
setSearchTerm(e.target.value);
};

const handleDeactivatePromotionClick = (activity) => {
setSelectedActivity(activity);
setShowDeactivatePromotionModal(true);
};

const confirmDeactivatePromotion = async () => {
const defaultPromotionValues = {
activityId: selectedActivity._id,
isPremiumPromotion: false,
isFeaturedPromotion: false,
isSpotlighted: false,
duration: 0,
costPerClick: 0,
creationDate: new Date().toISOString(),
promotionType: "",
expiryDate: new Date().toISOString()
};

try {
const response = await axios.post(
`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/promotion`,
defaultPromotionValues
);

if (response.data && response.data.activity) {
setActivities((prevActivities) =>
prevActivities.map((activity) =>
activity._id === selectedActivity._id ? response.data.activity : activity
)
);
}

setShowDeactivatePromotionModal(false);
} catch (error) {
console.error("Error deactivating promotion:", error);
}
};

const filteredActivities = Array.isArray(activities)
? activities.filter((activity) =>
activity.title.toLowerCase().includes(searchTerm.toLowerCase())
)
: [];

const handleDisableClick = (activity) => {
setSelectedActivity(activity);
setShowModal(true);
};

const confirmDisableActivity = async () => {
try {
const response = await axios.put(
`${process.env.REACT_APP_SERVER_BASE_URL}/api/activity/toggle/${selectedActivity._id}`
);

setActivities((prevActivities) =>
prevActivities.map((activity) =>
activity._id === selectedActivity._id
? { ...activity, isDisabled: response.data.isDisabled }
: activity
)
);

setShowModal(false);
} catch (error) {
console.error("Error updating activity:", error);
}
};

const renderPromotionDetails = (activity) => {
return (
<>
<h5>Promotion Details:</h5>
{activity.promotion.isPremiumPromotion && (
<>
<p>Type: Premium</p>
<p>
Start Date:{" "}
{new Date(
activity.promotion.promotionCreationDate
).toLocaleDateString()}
</p>
<p>
Expiry Date:{" "}
{new Date(activity.promotion.expiryDate).toLocaleDateString()}
</p>
</>
)}
{activity.promotion.isFeaturedPromotion && (
<>
<p>Type: Featured</p>
<p>Initial Amount: €{activity.promotion.featuredCostPerClick}</p>
<p>
Remaining: €
{activity.promotion.featuredCostPerClick -
0.01 * activity.promotion.viewsSincePromotion}
</p>
</>
)}
{activity.promotion.isSpotlighted && (
<>
<p>Type: Spotlighted</p>
<p>Duration: {activity.promotion.spotlightedDuration} days</p>
<p>Initial Amount: €{activity.promotion.spotlightedCostPerClick}</p>
<p>
Remaining: €
{activity.promotion.spotlightedCostPerClick -
0.01 * activity.promotion.viewsSincePromotion}
</p>
</>
)}
</>
);
};

return (
<AdminWrapper>
<div className="manage-activities">
<div className="title-line">
<h1 className="title">Manage Activities</h1>
<Button
label="Create Activity"
type="button"
variant="primary"
disabled={false}
withIcon={false}
size="medium"
to="/createActivity"
/>
</div>
<div className="all-activity-cards-manage">
<h3>All Activities ({activities.length})</h3>
<div className="search-area">
<h4> Filters</h4>
<div className="search-box-input">
<InputBox
title="Search"
helpText="Search activities..."
onChange={handleSearchChange}
value={searchTerm}
isSearch={true}
size="large"
/>
</div>
<Dropdown
  title="Sort Activities"
  options={sortingOptions}
  value={selectedSort}
  onChange={setSelectedSort}
/>

</div>
</div>
<div className="all-activitycards">
{filteredActivities.map((activity, index) => (
<div
key={index}
className={`activity-card ${activity.isDisabled ? "disabled" : ""}`}
>
<img src={activity.headerImage} alt={activity.title} />
<div className="activity-manager">
<div className="activity-information">
<div className="title-date">
<h4>{activity.title}</h4>
<p className="posting-date">
Last updated on:{" "}
{new Date(activity.postingTime).toLocaleDateString()}
</p>
<hr />
</div>
<div className="provider-location">
<h6>Provider Details</h6>
<div className="provider-logo-manager">
<img
className="logo-icon"
src={activity.logo}
alt={activity.provider}
/>
<div className="provider-details">
<p className="provider">{activity.provider}</p>
<p className="location-provider">
{activity.city}, {activity.country}
</p>
</div>
</div>
<hr />
</div>
<div className="activity-manage-button">
<h6>Activity Manager</h6>
<div className="activity-manager">
<Button
label="Edit Activity"
type="button"
variant="secondary"
size='large'
to={`/editActivity/${activity._id}`}
className="activity-edit-button"
/>
<Button
label={activity.isDisabled ? "Activate Activity" : "Disable Activity"}
type="button"
variant={activity.isDisabled ? "primary" : "danger"}
handleClick={() => handleDisableClick(activity)}
/>
</div>
<hr />
</div>
<div className="activity-stats">
<h6>Basic Analytics</h6>
<div className="activity-manager-card-stats">
<p><strong>Total Views: </strong>{activity.views}</p>
<p><strong>Total Bucketlisted: </strong>{activity.totalmyBucketlisted}</p>
</div>
<hr />
</div>
<div className="activity-manage-promotion">
<h6>Promotions</h6>
<div className="promotion-manage-buttons">
{/* Updated Promotion Button Logic */}
{activity.promotion && (activity.promotion.isPremiumPromotion || activity.promotion.isFeaturedPromotion || activity.promotion.isSpotlighted) ? (
<Button
label="Deactivate Promotion"
type="button"
variant="danger"
disabled={activity.isDisabled}
handleClick={() => handleDeactivatePromotionClick(activity)}
/>
) : (
<Button
label="Create Promotion"
type="button"
variant="primary"
disabled={activity.isDisabled}
handleClick={() => handleCreatePromotionClick(activity)}
/>
)}

</div>
{/* Promotion Details */}
<div>{activity.promotion && (activity.promotion.isPremiumPromotion || activity.promotion.isFeaturedPromotion || activity.promotion.isSpotlighted) && renderPromotionDetails(activity)}</div>
</div>
</div>
</div>
</div>
))}
</div>
<PromotionModal
selectedActivity={selectedActivity}
show={showPromotionModal}
handleClose={() => setShowPromotionModal(false)}
/>

<Modal
title="Confirm"
message="Are you sure you want to deactivate the promotion?"
handleClose={() => setShowDeactivatePromotionModal(false)}
show={showDeactivatePromotionModal}
actions={[
{
label: "Cancel",
variant: "secondary",
onClick: () => setShowDeactivatePromotionModal(false),
},
{
label: "Deactivate",
variant: "primary",
onClick: confirmDeactivatePromotion,
},
]}
/>

<Modal
title="Confirm"
message={`Are you sure you want to ${selectedActivity && selectedActivity.isDisabled ? "activate" : "disable"} '${selectedActivity && selectedActivity.title}'?`}
handleClose={() => setShowModal(false)}
show={showModal}
actions={[
{
label: "Cancel",
variant: "secondary",
onClick: () => setShowModal(false),
},
{
label: `${selectedActivity && selectedActivity.isDisabled ? "Activate" : "Disable"}`,
variant: "primary",
onClick: confirmDisableActivity,
},
]}
/>
</div>
</AdminWrapper>
);

};

export default ManageActivities;
