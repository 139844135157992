import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from 'react-helmet';
import axios from "axios";
import "./Discover.css";
import ActivityCard from "../components/activityCard/ActivityCard";
import FeaturedPromotion from "../components/promotions/FeaturedPromotion";
import Pagination from "../components/pagination/Pagination";
import Dropdown from "../components/dropdown/Dropdown";
import CategoryCapsules from "../components/categoryCapsules/CategoryCapsules";

import Category from "../components/tags/subCategory.json";
import Accessibility from "../components/tags/accessibilityOptions.json";
import Country from "../components/tags/country.json";
import Season from "../components/tags/seasonOptions.json";
import Mood from "../components/tags/moodOptions.json";
import AgeLimit from "../components/tags/ageLimitOptions.json";
import FitnessLevel from "../components/tags/fitnessLevelOptions.json";
import Button from "../components/button/Button";
import FilterIcon from "../assets/icons/filter-black.svg";
import FilterIconActive from "../assets/icons/filter-active.svg";

const Discover = () => {
const [currentPage, setCurrentPage] = useState(0);
const itemsPerPage = 20;
const [totalItems, setTotalItems] = useState(0);
const [activities, setActivities] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const [loadingProgress, setLoadingProgress] = useState(0);
const [filters, setFilters] = useState({
activityType: null,
accessibility: null,
country: null,
season: null,
mood: null,
ageLimit: null,
fitnessLevel: null,
subCategory: null,
});

const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const [showFilters, setShowFilters] = useState(false);

const toggleFilters = () => {
setShowFilters((prevShowFilters) => !prevShowFilters);
};

useEffect(() => {
const handleResize = () => {
setWindowWidth(window.innerWidth);
};

window.addEventListener("resize", handleResize);
return () => window.removeEventListener("resize", handleResize);
}, []);

const renderActivitiesWithPromotions = () => {
const elements = [];
activities.forEach((activity, index) => {
elements.push(<ActivityCard key={activity._id} activity={activity} />);

if ((index + 1) % 8 === 0) {
// Wrap FeaturedPromotion in a div with a class to control its styling
elements.push(
<div className="featured-promotion-class" key={`promo-${index}`}>
<FeaturedPromotion />
</div>
);
}
});
return elements;
};


const handleFilterChange = (name, selectedOption) => {
setFilters(prev => ({
...prev,
[name]: selectedOption ? selectedOption.value : null,
}));
setCurrentPage(0);
};


const fetchActivities = useCallback(async () => {
setIsLoading(true);
try {
const response = await axios.get(
`${process.env.REACT_APP_SERVER_BASE_URL}/activity`,
{
params: {
...filters,
limit: itemsPerPage,
skip: itemsPerPage * currentPage,
},
}
);
setActivities(response.data.data);
setTotalItems(response.data.totalItems);
} catch (error) {
console.error("Error fetching activities:", error);
} finally {
setIsLoading(false);
}
}, [filters, currentPage, itemsPerPage]);

useEffect(() => {
let interval = setInterval(() => {
setLoadingProgress((prevProgress) => {
let nextProgress = prevProgress + Math.floor(Math.random() * 10);
return Math.min(nextProgress, 95);
});
}, 500);

fetchActivities();

return () => {
clearInterval(interval);
};
}, [currentPage, filters, fetchActivities]);

const handlePageChange = (newPage) => {
setCurrentPage(newPage);
};

const renderFilterDropdowns = () => {
return (
<>
<Dropdown
size="medium"
title="Sub-Category"
options={Category}
value={
filters.subCategory !== null
? {
value: filters.subCategory,
label: Category.find(
(option) => option.value === filters.subCategory
)?.label,
}
: null
}
onChange={(option) => handleFilterChange("subCategory", option)}
/>
<Dropdown
                size="small"
                title="Activities/Places"
                options={[{ value: 'activity', label: 'Activity' }, { value: 'place', label: 'Place' }]}
                value={filters.activityOrPlace !== undefined ? 
                    { value: filters.activityOrPlace, label: filters.activityOrPlace.charAt(0).toUpperCase() + filters.activityOrPlace.slice(1) } : null}
                onChange={(option) => handleFilterChange("activityOrPlace", option)}
            />
            <Dropdown
                size="small"
                title="Free or Paid"
                options={[{ value: 'true', label: 'Free' }, { value: 'false', label: 'Paid' }]}
                value={filters.isActivityFree !== null && filters.isActivityFree !== undefined ? 
                    { value: filters.isActivityFree.toString(), label: filters.isActivityFree ? 'Free' : 'Paid' } : null}
                onChange={(option) => handleFilterChange("isActivityFree", option)}
            />
<Dropdown
size="small"
title="Mood"
options={Mood}
value={
filters.mood !== null
? {
value: filters.mood,
label: Mood.find((option) => option.value === filters.mood)
?.label,
}
: null
}
onChange={(option) => handleFilterChange("mood", option)}
/>
<Dropdown
size="medium"
title="Accessibility"
options={Accessibility}
value={
filters.accessibility !== null
? {
value: filters.accessibility,
label: Accessibility.find(
(option) => option.value === filters.accessibility
)?.label,
}
: null
}
onChange={(option) => handleFilterChange("accessibility", option)}
/>
<Dropdown
size="small"
title="Season"
options={Season}
value={
filters.season !== null
? {
value: filters.season,
label: Season.find(
(option) => option.value === filters.season
)?.label,
}
: null
}
onChange={(option) => handleFilterChange("season", option)}
/>
<Dropdown
size="small"
title="Country"
options={Country}
value={filters.country !== null ? { value: filters.country, label: Country.find(option => option.value === filters.country)?.label } : null}
onChange={(option) => handleFilterChange("country", option)}
/>
<Dropdown
size="small"
title="Fitness Level"
options={FitnessLevel}
value={filters.fitnessLevel !== null ? { value: filters.fitnessLevel, label: FitnessLevel.find(option => option.value === filters.fitnessLevel)?.label } : null}
onChange={(option) => handleFilterChange("fitnessLevel", option)}
/>
</>
);
};


return (
<div className="discover">
<Helmet>
<link rel="canonical" href="https://www.rebrid.co/discover" />
</Helmet>
<div className="discover-title">
<h1>Discover Activities</h1>
<p>
Use the filters to find the activity that best suits your requirements
</p>
</div>
<div className="filters-line">
<CategoryCapsules
onCategorySelect={(selectedCategory) =>
handleFilterChange("activityType", selectedCategory)
}
/>
<Button
label={windowWidth < 500 ? "" : "Filter"}
variant={showFilters ? "active-filter" : "tertiary"}
handleClick={toggleFilters}
icon={showFilters ? FilterIconActive : FilterIcon}
size="small"
withIcon
className={showFilters ? "filter-button-active" : ""}
/>
</div>
<div className={`filters-container ${!showFilters ? "hidden-filters" : ""}`}>
{renderFilterDropdowns()}
</div>
<div className="filtered-section">
{/* <h2>{displayMessage}</h2> */}
{isLoading ? (
<div className="loading-container">
<div className="loading-line-bg"></div>
<div className="loading-line" style={{ width: `${loadingProgress}%` }}></div>
<p className="loading-text">Loading activities... {loadingProgress}%</p>
</div>
) : activities.length > 0 ? (
<div className="activity-cards">
<div className="filtered-cards">
{renderActivitiesWithPromotions()}
</div>
</div>
) : (
<div className="no-activities">
<p>No Activities Found</p>
</div>
)}
{totalItems > itemsPerPage && (
<div className="pagination">
<Pagination
totalItems={totalItems}
itemsPerPage={itemsPerPage}
currentPage={currentPage}
onPageChange={handlePageChange}
/>
</div>
)}
</div>
</div>
);
};

export default Discover;
