import React, { useState, useRef, useEffect } from 'react';
import CategoryCard from '../categoryCards/CategoryCard';
import './CategorySection.css';

import Nature from '../../assets/images/categories/Nature.svg';
import Relaxation from '../../assets/images/categories/relaxation.svg';
import City from '../../assets/images/categories/city.svg';
import Workshop from '../../assets/images/categories/workshop.svg';
import Volunteer from '../../assets/images/categories/volunteer.svg';
import Active from '../../assets/images/categories/active.svg';
import Games from '../../assets/images/categories/Games.svg';
import Unique from '../../assets/images/categories/unique.svg';
import Romantic from '../../assets/images/categories/romantic.svg';
import Family from '../../assets/images/categories/family.svg';
import Elderly from '../../assets/images/categories/elderly.svg';
import Eco from '../../assets/images/categories/eco.svg';

import Previous from '../../assets/icons/left-active.svg';
import Next from '../../assets/icons/right-active.svg';
import PreviousDisabled from '../../assets/icons/left-disabled.svg';
import NextDisabled from '../../assets/icons/right-disabled.svg';

const CategorySection = () => {

    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const categoriesContainerRef = useRef(null);

const categories = [
    { title: "Nature", image: Nature, keyword: "nature" },
    { title: "Relaxation", image: Relaxation, keyword: "relaxation" },
    { title: "City Tour & Culture", image: City, keyword: "city" },
    { title: "Workshops & Certifications", image: Workshop, keyword: "workshop" },
    { title: "Volunteer", image: Volunteer, keyword: "volunteer" },
    { title: "Sports & Active", image: Active, keyword: "active" },
    { title: "Games", image: Games, keyword: "games" },
    { title: "Unique", image: Unique, keyword: "unique" },
    { title: "Romantic", image: Romantic, keyword: "romantic" },
    { title: "Family & Kids", image: Family, keyword: "family" },
    { title: "Elderly Friendly", image: Elderly, keyword: "elderly" },
    { title: "Eco-friendly", image: Eco, keyword: "eco" }
];

const checkScrollPosition = () => {
    if (categoriesContainerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = categoriesContainerRef.current;
        setIsAtStart(scrollLeft === 0);
        setIsAtEnd(scrollLeft >= scrollWidth - clientWidth);
    }
};

const scrollCategories = (direction) => {
    if (categoriesContainerRef.current) {
        const scrollAmount = categoriesContainerRef.current.offsetWidth / 2;
        const newScrollPosition = direction === 'next' ? 
        categoriesContainerRef.current.scrollLeft + scrollAmount : 
        categoriesContainerRef.current.scrollLeft - scrollAmount;

        categoriesContainerRef.current.scrollTo({
            left: newScrollPosition,
            behavior: 'smooth'
        });
    }
};

useEffect(() => {
    checkScrollPosition();
    const categoriesContainer = categoriesContainerRef.current;
    if (categoriesContainer) {
        categoriesContainer.addEventListener('scroll', checkScrollPosition);
    }
    return () => {
        if (categoriesContainer) {
            categoriesContainer.removeEventListener('scroll', checkScrollPosition);
        }
    };
}, []);

return (
    <div className="category-section">
        <h1>Categories</h1>
        <div className="categories-container-wrapper">
            <img 
                src={isAtStart ? PreviousDisabled : Previous} 
                alt="Previous" 
                onClick={() => !isAtStart && scrollCategories('prev')}
                className={'scroll-button' + (isAtStart ? ' disabled' : '')}
            />
            <div className="categories-container" ref={categoriesContainerRef}>
                {categories.map((category, index) => (
                    <CategoryCard key={index} {...category} />
                ))}
            </div>
            <img 
                src={isAtEnd ? NextDisabled : Next} 
                alt="Next" 
                onClick={() => !isAtEnd && scrollCategories('next')}
                className={'scroll-button' + (isAtEnd ? ' disabled' : '')}
            />
            {!isAtStart && <div className="categories-gradient-left"></div>}
            {!isAtEnd && <div className="categories-gradient-right"></div>}
        </div>
    </div>
);
};

export default CategorySection;