import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../components/button/Button';
import UserContext from '../components/UserContext';
import './Blog.css';

const Blog = (token) => {
const [blog, setBlog] = useState(null);
const { id } = useParams();
const navigate = useNavigate(); 
const { userType } = useContext(UserContext);

useEffect(() => {
const fetchData = async () => {
try {
const token = localStorage.getItem("token");
const headers = token
? { Authorization: `Bearer ${token}` }
: undefined;

const response = await axios.get(
`${process.env.REACT_APP_SERVER_BASE_URL}/api/blogs/${id}`,
headers ? { headers } : undefined
);

setBlog(response.data);
} catch (error) {
console.error('Error fetching blog data:', error);
}
};

fetchData();
}, [id, userType]);

// function to handle click on the Edit button
const handleEditClick = () => {
navigate(`/editblog/${id}`);
};

if (!blog) {
return <div>Loading...</div>;
}

return (
<div className="BlogPage">
<Helmet>
<link rel="canonical" href={`https://www.rebrid.co/blogs/${id}`} />
</Helmet>
<img className="blog-header-image" src={blog.headerImage} alt={blog.title} />
<div className="blogpage-container">
<div className="card-buttons">
<h1 className="title">{blog.title}</h1>
<div className='buttons-together'>
{userType === "admin" && (
<Button label="Edit" variant="primary" handleClick={handleEditClick} />
)}
</div>
</div>
<h5 className="subtitle">{blog.subtitle}</h5>
<div className="read-duration">{`Read Time: ${blog.readDuration} minutes`}</div>

<div className="blog-contents" dangerouslySetInnerHTML={{ __html: blog.content }} />
</div>
</div>
);
};

export default Blog;
