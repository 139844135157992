import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CategoryCard.css';

const CategoryCard = ({ title, image, keyword }) => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate(`/searchResult?search=${keyword}`);
    };

    return (
        <div className="category-card" onClick={handleCardClick}>
            <img src={image} alt={title} className="category-icon" />
            <p className="category-card-title">{title}</p>
        </div>
    );
};

export default CategoryCard;
