import React, { useState, useEffect } from 'react';
import InputBox from '../components/InputBox/InputBox';
import Button from '../components/button/Button';
import './ContactUs.css';
import { useNavigate } from 'react-router-dom';
import {sanitizeInput} from "../components/utils/sanitizeInput";

const ContactUs = () => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sendingStatus, setSendingStatus] = useState('idle');
  const [countdown, setCountdown] = useState(5);

  const navigate = useNavigate();

  useEffect(() => {
    let interval;
    if (sendingStatus === 'sent' && countdown > 0) {
      interval = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    } else if (sendingStatus === 'sent' && countdown === 0) {
      navigate('/');
    }
    return () => clearInterval(interval);
  }, [sendingStatus, countdown, navigate]);

  const handleSend = async () => {
    setValidationTriggered(true);

    const sanitizedFirstName = sanitizeInput(firstName);
    const sanitizedLastName = sanitizeInput(lastName);
    const sanitizedEmail = sanitizeInput(email);
    const sanitizedSubject = sanitizeInput(subject);
    const sanitizedMessage = sanitizeInput(message);

    setSendingStatus('sending');
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: sanitizedFirstName, 
          lastName: sanitizedLastName, 
          email: sanitizedEmail, 
          subject: sanitizedSubject, 
          message: sanitizedMessage
      }),
      });
      const data = await response.json();
      if (data.success) {
        setSendingStatus('sent');
      } else {
        alert("Error sending the message. Please try again later.");
        setSendingStatus('idle'); // Resetting status to idle on error
      }
    } catch (error) {
      alert("Error sending the message. Please try again later.");
      setSendingStatus('idle'); // Resetting status to idle on error
    }
  };

  return (
    <>
<div className="contactus-page">
    <div className="contactus-form-container">
        {sendingStatus === 'sent' ? (
            <>
                <h1 className="text-h2">Mail successfully sent!</h1>
                <p className='text-p'>Navigating to Homepage in {countdown} seconds</p>
            </>
        ) : (
            <>
              <h2 className="text-h2">Contact Us</h2>
              <div className="input-row">
                  <InputBox
                      title="First Name"
                      helpText="Enter your first name"
                      type="text"
                      size="medium"
                      isRequired={true}
                      forceValidation={validationTriggered}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                  />
                  <InputBox
                      title="Last Name"
                      helpText="Enter your last name"
                      type="text"
                      size="medium"
                      isRequired={true}
                      forceValidation={validationTriggered}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                  />
                  <InputBox
                      title="Email"
                      helpText="Enter your email"
                      type="email"
                      size="medium"
                      isRequired={true}
                      forceValidation={validationTriggered}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                  />
              </div>
              <InputBox
                  title="Subject"
                  helpText="Enter the subject"
                  type="text"
                  size="large"
                  isRequired={true}
                  forceValidation={validationTriggered}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
              />
              <InputBox
                  title="Message"
                  helpText="Enter your Message"
                  type="paragraph"
                  size="large"
                  isRequired="true"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                  label={sendingStatus === 'sending' ? 'Sending email' : 'Send'}
                  type="button"
                  variant={sendingStatus === 'sending' ? 'disabled' : 'primary'}
                  disabled={sendingStatus === 'sending'}
                  withIcon={false}
                  size="large"
                  handleClick={handleSend}
                />
       
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ContactUs;