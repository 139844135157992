import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CountryCard.css';

const CountryCard = ({ title, image, keyword, onHover, onLeave }) => {
const navigate = useNavigate();

const handleCardClick = () => {
const params = new URLSearchParams();
params.append('keyword', keyword);
navigate(`/location/${keyword}`);
};

return (
<div className="country-card" 
onClick={handleCardClick} 
style={{ backgroundImage: `url(${image})` }} 
onMouseEnter={onHover} 
onMouseLeave={onLeave}
>
<div className="gradient-overlay"></div>
<h3 className="country-card-title">{title}</h3>
</div>
);
};

export default CountryCard;
