import React, { useState } from 'react';
import './ActivityHoverCardModal.css';
import CloseIcon from '../../assets/icons/close.svg';
import Button from '../button/Button';
import Dropdown from '../dropdown/Dropdown';

const ActivityHoverCardModal = ({ activity, itineraryId, isEditItinerary, isInActivitiesSection, isEdit, isEditMode, itineraries, onOpenActivityPage, onRemoveActivity, onClose, onAddToItinerary,onToggleEditMode }) => {
    const [selectedItinerary, setSelectedItinerary] = useState(null);

    const handleBackgroundClick = (e) => {
        e.stopPropagation();
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleCloseIconClick = (e) => {
        e.stopPropagation();
        onClose();
    };

    const handleAddToItinerary = (e) => {
        e.stopPropagation();
        if (selectedItinerary) {
            onAddToItinerary(activity._id, selectedItinerary.value);
            onClose();
        }
    };
    

    const dropdownOptions = itineraries.map(itinerary => ({
        value: itinerary._id,
        label: itinerary.title
    }));

    return (
  <div className="modal-background" onClick={handleBackgroundClick}>
    <div className="modal-content">
      <img src={CloseIcon} alt="Close" className="close-icon" onClick={handleCloseIconClick} />
      <div className='activityHovermModal-title'>
        <div className="activity-image-circle" style={{ backgroundImage: `url(${activity.headerImage})` }}></div>
        <h4>{activity.title}</h4>
      </div>

      {isInActivitiesSection && (
        <>
          {isEditMode ? (
            <div className='activityHover-modal-editMode'>
              <div className='edit-mode-dropdown'>
                <Dropdown 
                  options={dropdownOptions} 
                  value={selectedItinerary}
                  size="medium" 
                  title="Itinerary Names"
                  onChange={setSelectedItinerary}
                />
                <Button 
                  label="Add to Itinerary" 
                  variant={selectedItinerary ? "primary" : "disabled"} 
                  size="medium" 
                  handleClick={handleAddToItinerary} 
                  disabled={!selectedItinerary}
                />
              </div>
              <Button label="Open Activity Page" variant="secondary" size="large" handleClick={() => onOpenActivityPage(activity._id)} />
              <p className="edit-mode-note">Enable edit in all itineraries you wish to add the activity to.</p>
            </div>
          ) : (
            <div className='activityHover-modal-buttons'>
              <Button label="Open Activity Page" variant="primary" size="large" handleClick={() => onOpenActivityPage(activity._id)} />
              <Button label="Remove from Plan" variant="tertiary" size="large" handleClick={() => onRemoveActivity(activity._id)} />
              <div className='activityHover-modal-note'>
              <h6>Note:</h6>
              <p>To add activity to itinerary, click on the edit icon on the itinerary card.</p>
              </div>
            </div>
          )}
        </>
      )}

{!isInActivitiesSection && (
  <>
  <div className='itinerary-buttons'>
    <Button 
      label="Open Activity Page" 
      variant="primary" 
      size="large" 
      handleClick={() => onOpenActivityPage(activity._id)} 
    />
    <p>Enable edit to remove activity from this itinerary. </p>
    {isEdit && (
      <Button 
        label="Remove From Current Itinerary" 
        variant="tertiary" 
        size="large" 
        handleClick={() => {
          onRemoveActivity(activity._id);
          onClose();
        }} 
      />
    )}
    </div>
  </>
)}

    </div>
  </div>
);

};

export default ActivityHoverCardModal;
