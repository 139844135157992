import React from 'react';
import PropTypes from 'prop-types';
import CheckboxSelected from '../../assets/icons/checkbox-selected.svg';
import CheckboxUnselected from '../../assets/icons/checkbox-unselected.svg';
import RadioSelected from '../../assets/icons/radio-selected.svg';
import RadioUnselected from '../../assets/icons/radio-unselected.svg';
import './Selector.css';

const Selector = ({type, label, options, checked, onChange, isRequired}) => {
const handleOptionClick = (value) => {
if (type === 'checkbox') {
if (checked.includes(value)) {
onChange(checked.filter(item => item !== value));
} else {
onChange([...checked, value]);
}
} else {
onChange(value);
}
}

return (
<div className="selector">
<p className="selector-label">{label} {isRequired && <span className="required">*</span>}</p>
{options.map(option => (
<div key={option.value} className="option" onClick={() => handleOptionClick(option.value)}>
<img 
src={(type === 'checkbox' ? checked.includes(option.value) : checked === option.value) ? 
(type === 'checkbox' ? CheckboxSelected : RadioSelected) :
(type === 'checkbox' ? CheckboxUnselected : RadioUnselected)
} 
alt="" 
className="option-icon"
/>
<span className="option-label">{option.label}</span>
</div>
))}
</div>
)
}

Selector.propTypes = {
type: PropTypes.string.isRequired,
label: PropTypes.string,
options: PropTypes.arrayOf(PropTypes.shape({
value: PropTypes.string,
label: PropTypes.string
})).isRequired,
checked: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
onChange: PropTypes.func.isRequired,
isRequired: PropTypes.bool,
};

Selector.defaultProps = {
isRequired: false,
};

export default Selector;
