import React, { useState, useEffect, useRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import InputContainer from '../inputContainer/InputContainer';
import InputMask from 'react-input-mask';
import parse from 'date-fns/parse';
import differenceInYears from 'date-fns/differenceInYears';
import "react-datepicker/dist/react-datepicker.css";
import './DatePicker.css';

registerLocale('enGB', enGB);

const CustomInput = React.forwardRef(({ value, onClick, onChange, error }, ref) => (
  <InputMask mask="99/99/9999" value={value} onChange={onChange}>
    {(inputProps) =>
      <input
        {...inputProps}
        onClick={onClick}
        className={error ? "date-picker-input error" : "date-picker-input"}
        placeholder="DD/MM/YYYY"
        ref={ref}
      />
    }
  </InputMask>
));

const DatePickerComponent = ({ title, selectedDate, size, isRequired = true, onDateChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const datePickerRef = useRef(null);

  const handleInputChange = e => {
    const { value } = e.target;
    setInputValue(value);
    const parsedDate = parse(value, "dd/MM/yyyy", new Date());
    if (isNaN(parsedDate)) {
      setStartDate(null);
    } else {
      setStartDate(parsedDate);
    }
  }

  const handleDateChange = date => {
    if (date) {
        const age = differenceInYears(new Date(), date);
        if (age < 16) {
            setError("Children under 16 are not allowed.");
        } else if (age > 110) {
            setError("Too old for our platform.");
        } else {
            setError("");
        }
        setInputValue(date.toLocaleDateString("en-GB"));
        setStartDate(date);
    } else {
        setInputValue("");
        setError("");
        setStartDate(null);
    }
    onDateChange(date);
}


  useEffect(() => {
    if (selectedDate) {
        const dateObject = new Date(selectedDate);
        if (!isNaN(dateObject.getTime())) {
            setStartDate(dateObject);
            setInputValue(dateObject.toLocaleDateString("en-GB"));
        } else {
            console.error('Invalid selectedDate:', selectedDate);
            setStartDate(null);
            setInputValue("");
        }
    }
}, [selectedDate]);

  useEffect(() => {
    const parsedDate = parse(inputValue, "dd/MM/yyyy", new Date());
    if (!isNaN(parsedDate)) {
      const age = differenceInYears(new Date(), parsedDate);
      if (age < 16) {
        setError("Children under 16 are not allowed.");
      } else if (age > 110) {
        setError("Too old for our platform.");
      } else {
        setError("");
        setStartDate(parsedDate);
      }
    } else if (inputValue.replace(/_/g, "").length === 10) {
      setError("Invalid date format. Please use dd/mm/yyyy.");
    } else {
      setError("");
    }
  }, [inputValue])

  return (
    <InputContainer 
      title={title + (isRequired ? '*' : '')}
      error={error}
      size={size}
    >
      <DatePicker
        ref={datePickerRef}
        selected={startDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        locale="enGB"
        className={`date-picker-input ${size}`}
        customInput={<CustomInput value={inputValue} onChange={handleInputChange} error={error} />}
      />
    </InputContainer>
  );
}

export default DatePickerComponent;
