import React from 'react';
import CountryCard from '../countryCard/CountryCard';
import './CountrySection.css';

import Portugal from '../../assets/images/countries/portugal.jpg';
import Spain from '../../assets/images/countries/spain.jpg';
import France from '../../assets/images/countries/france.jpg';
import Italy from '../../assets/images/countries/italy.jpg';
import India from '../../assets/images/countries/india.jpg';
import Korea from '../../assets/images/countries/korea.jpg';
import HongKong from '../../assets/images/countries/hongkong.jpg';
import Indonesia from '../../assets/images/countries/indonesia.jpg';
import Thailand from '../../assets/images/countries/thailand.jpg';
import Philippines from '../../assets/images/countries/philippines.jpg';
import Greece from '../../assets/images/countries/greece.jpg';
import Finland from '../../assets/images/countries/finland.jpg';
import Norway from '../../assets/images/countries/norway.jpg';
import Sweden from '../../assets/images/countries/sweden.jpg';
import Denmark from '../../assets/images/countries/denmark.jpg';
import Iceland from '../../assets/images/countries/iceland.jpg';

const CountrySection = () => {
    const countries = [
        { title: "Portugal", image: Portugal, keyword: "Portugal" },
        { title: "Spain", image: Spain, keyword: "Spain" },
        { title: "France", image: France, keyword: "France" },
        { title: "Italy", image: Italy, keyword: "Italy" },
        { title: "India", image: India, keyword: "India" },
        { title: "South Korea", image: Korea, keyword: "SouthKorea" },
        { title: "Hong Kong", image: HongKong, keyword: "Hong Kong" },
        { title: "Indonesia", image: Indonesia, keyword: "Indonesia" },
        { title: "Thailand", image: Thailand, keyword: "Thailand" },
        { title: "Philippines", image: Philippines, keyword: "Philippines" },
        { title: "Greece", image: Greece, keyword: "Greece" },
        { title: "Finland", image: Finland, keyword: "Finland" },
        { title: "Norway", image: Norway, keyword: "Norway" },
        { title: "Sweden", image: Sweden, keyword: "Sweden" },
        { title: "Denmark", image: Denmark, keyword: "Denmark" },
        { title: "Iceland", image: Iceland, keyword: "Iceland" },
        ];

return (
<div className="country-section">
<h1>Popular Countries</h1>
<p>Click on any card to view activities in the country.</p>

<div className="country-container">
{countries.map((country) => (
  <div className='countries-card' key={country.title}>
    <CountryCard {...country} />
  </div>
))}
</div>
</div>
);
};

export default CountrySection;
