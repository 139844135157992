import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '../button/Button';
import pinIcon from '../../assets/icons/pin-location.svg';
import leftActiveIcon from '../../assets/icons/left-active.svg';
import rightActiveIcon from '../../assets/icons/right-active.svg';
import leftDisabledIcon from '../../assets/icons/left-disabled.svg';
import rightDisabledIcon from '../../assets/icons/right-disabled.svg';
import noImage from '../../assets/images/no-image.jpg';
import './FeaturedPromotion.css';
import { Link } from 'react-router-dom';

const FeaturedPromotion = () => {
    const [activities, setActivities] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/activity`);
                const featuredActivities = response.data.data.filter(activity => activity.promotion && activity.promotion.isFeaturedPromotion);
                setActivities(featuredActivities);
                // Randomize the initial index
                setCurrentIndex(Math.floor(Math.random() * featuredActivities.length));
            } catch (error) {
                console.error('Failed to fetch featured activities', error);
            }
        };

        fetchActivities();
    }, []);

    const currentActivity = activities[currentIndex];

    const handleNext = () => {
        if (currentIndex < activities.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    function htmlToText(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    return (
        <div className='featured-page'>
        <div className='featured-promo-text'>
            <h1>Featured Activities</h1>
            <p>Scroll to see some of our Featured Activities from across the world.</p>
        </div>
        <div className="featured-container">

            <div className="featured-navigation-small">
                <p>{currentIndex + 1} of {activities.length}</p>
                <img src={currentIndex === 0 ? leftDisabledIcon : leftActiveIcon} alt="Previous" onClick={handlePrevious} />
                <img src={currentIndex === activities.length - 1 ? rightDisabledIcon : rightActiveIcon} alt="Next" onClick={handleNext} />
            </div>

            <div className="featured-image-section">
                <img src={currentActivity?.headerImage || noImage} alt={currentActivity?.title} />
            </div>

            <div className="featured-details-section">
                <div className="featured-navigation">
                    <p>{currentIndex + 1} of {activities.length}</p>
                    <img src={currentIndex === 0 ? leftDisabledIcon : leftActiveIcon} alt="Previous" onClick={handlePrevious} />
                    <img src={currentIndex === activities.length - 1 ? rightDisabledIcon : rightActiveIcon} alt="Next" onClick={handleNext} />
                </div>
                <h3>{currentActivity?.title}</h3>
                <p className="location-capsule">
                    <img src={pinIcon} alt="Location" />
                    {currentActivity?.location}, {currentActivity?.city}, {currentActivity?.country}
                </p>
                <div className='features-description'>
                    <p>{htmlToText(currentActivity?.description).substring(0, 250)}...</p>
                </div>
                <Link to={`/activity/${currentActivity?._id}`}>
                    <Button label="View Activity" type="button" variant="hero" size='large'/>
                </Link>
            </div>
        </div>
        </div>
    );
};

export default FeaturedPromotion;
