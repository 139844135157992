import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setCurrentUser(decoded); // assuming the decoded token has the user's information
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, []);

  return currentUser;
};
