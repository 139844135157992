import React, { useState } from 'react';
import Button from '../button/Button';
import './KeywordModal.css';

function KeywordModal({ isOpen, onClose, onConfirm, selectedItems, keywordsData=[] }) {
  const [selectedKeywords, setSelectedKeywords] = useState(selectedItems || []);

  const handleKeywordClick = (keyword) => {
    if (selectedKeywords.includes(keyword)) {
      setSelectedKeywords(prev => prev.filter(item => item !== keyword));
    } else {
      setSelectedKeywords(prev => [...prev, keyword]);
    }
  };

  return (
    <div className={isOpen ? "keyword-modal-container open" : "keyword-modal-container"}>
      <div className="preference-groups">
        <div className="preference-group">
          <h2 className='preference-header'>Preferences</h2>
          <div className="preference-cards">
            {keywordsData.map(({ value, label }) => (
              <span
                key={value}
                className={`keywordItem ${selectedKeywords.includes(value) ? "selected" : ""}`}
                onClick={() => handleKeywordClick(value)}
              >
                {label}
              </span>
            ))}
          </div>
        </div>
        <Button 
          variant='cta' 
          size='large' 
          label="Save Tags" 
          type="button" 
          handleClick={() => {
            onConfirm(selectedKeywords);
            onClose();
          }} 
        />
      </div>
    </div>
  );  
}

export default KeywordModal;
