import React, { useRef, useState, useEffect } from 'react';
import attachment from '../../assets/icons/attachment.svg';
import close from '../../assets/icons/trash.svg';
import './ImagesBox.css';
import InputBox from '../InputBox/InputBox';
import axios from 'axios';
import ImagesModal from '../modal/ImagesModal';

const ImagesBox = ({ size = 'medium', label = 'Select Images', onFilesChange, initialImages = [] }) => {
const fileInputRef = useRef(null);
const [selectedFiles, setSelectedFiles] = useState([]);
const [errorMessage, setErrorMessage] = useState('');
const [uploadProgress, setUploadProgress] = useState({});
const [queuedChanges, setQueuedChanges] = useState([]);
const [modalIsOpen, setModalIsOpen] = useState(false);
const [imagesForModal, setImagesForModal] = useState([]);

const onUploadSelected = async (approvedFiles) => {
    const formattedFiles = approvedFiles.map((file, index) => ({
      id: `approved-${Date.now()}-${index}`,
      originalFile: file,
      status: 'waiting'
    }));
  
    // Add formatted files to the selectedFiles state
    setSelectedFiles(prevFiles => [...prevFiles, ...formattedFiles]);
  
    // Call uploadFilesSequentially to handle the actual uploading
    await uploadFilesSequentially();
  };
  

const uploadFile = async (file) => {
const formData = new FormData();
formData.append('image', file.originalFile);

try {
const response = await axios.post(
`${process.env.REACT_APP_SERVER_BASE_URL}/api/upload`, 
formData, 
{
onUploadProgress: (progressEvent) => {
const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
setUploadProgress(prev => ({ ...prev, [file.id]: percentCompleted }));
},
headers: { 'Content-Type': 'multipart/form-data' }
}
);

setSelectedFiles(prev => {
const updatedFiles = prev.map(f => f.id === file.id ? { 
...f, 
url: response.data.Location, 
size: response.data.Size, 
status: 'uploaded'
} : f);
onFilesChange(updatedFiles);
return updatedFiles;
});

} catch (error) {
console.error('Upload error:', error);
}
};

const uploadFilesSequentially = async () => {
    for (const file of selectedFiles) {
      if (file.status === 'waiting') {
        await uploadFile(file); // Ensure this awaits the uploadFile function
      }
    }
  };

const getFileName = (item) => {
if (item.originalFile) {
return item.originalFile.name;
}

if (item.url && typeof item.url === 'string') {
let name = item.url.split('/').pop();
let limit;
switch (size) {
case 'small':
limit = 7;
break;
case 'medium':
limit = 15;
break;
case 'large':
limit = 30;
break;
default:
limit = 15; // Default to medium size if none is specified
}

if (name.length > limit) {
const extension = name.lastIndexOf('.') !== -1 ? name.substring(name.lastIndexOf('.')) : '';
name = `${name.substring(0, limit)}..${extension}`;
}
return name;
}

return '';
};

useEffect(() => {
const formattedInitialImages = initialImages.map(image => ({
id: image._id || `initial-${Math.random()}`, // Use MongoDB _id if available
url: image.url,
originalFile: null, // No file object for initial images
creator: image.creator || '',
sourceWebsite: image.sourceWebsite || '',
status: 'uploaded'
}));
setSelectedFiles(formattedInitialImages);
}, [initialImages]);


const handleMetadataChange = (id, field, value) => {
// Queue the change instead of applying it directly
const change = { id, field, value };
setQueuedChanges(prev => [...prev, change]);
};

// Use an effect to apply the changes
useEffect(() => {
if (queuedChanges.length > 0) {
const updatedFiles = selectedFiles.map(file => {
const change = queuedChanges.find(c => c.id === file.id);
return change ? { ...file, [change.field]: change.value } : file;
});
setSelectedFiles(updatedFiles);
onFilesChange(updatedFiles);
// Clear the queue
setQueuedChanges([]);
}
}, [queuedChanges, selectedFiles, onFilesChange]);


const openFileDialog = () => {
if (fileInputRef.current) {
fileInputRef.current.click();
}
};

const onFilesSelected = async (newFiles) => {
    // Convert files to the format expected by ImagesModal
    const filesWithPreview = newFiles.map(file => ({
      ...file,
      preview: URL.createObjectURL(file.originalFile),
      sizeText: `${(file.originalFile.size / 1024).toFixed(2)} KB`
    }));
    setImagesForModal(filesWithPreview);
    setModalIsOpen(true); // Show the modal
  };

  const handleFilesApproval = (approvedFiles) => {
    onUploadSelected(approvedFiles);
    setModalIsOpen(false); // Close the modal after approval
  };


const onFilesChangeLocal = event => {
if (event.target.files && event.target.files.length > 0) {
const newFilesPromises = Array.from(event.target.files).map((file, index) => {
return new Promise((resolve) => {
const reader = new FileReader();
reader.onloadend = () => {
resolve({
id: `file-${Date.now()}-${index}`,
url: reader.result,
originalFile: file,
creator: '',
sourceWebsite: ''
});
};
reader.onerror = () => console.error('File reading error');
reader.readAsDataURL(file);
});
});

Promise.all(newFilesPromises)
.then(newFiles => {
onFilesSelected(newFiles).catch(error => {
console.error('Error uploading files:', error);
setErrorMessage('Error uploading files');
});
})
.catch(error => {
console.error('Error reading files:', error);
setErrorMessage('Error reading files');
});
};
};

const removeSelectedFile = async (itemToRemove) => {
if (itemToRemove.status === 'uploaded' && itemToRemove.url) {
try {
await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/api/delete`, {
data: { imageUrl: itemToRemove.url }
});
console.log(`Deleted image with URL ${itemToRemove.url}`);
} catch (error) {
console.error('Error deleting image:', error);
setErrorMessage('Error deleting image');
return;
}
}

const updatedFiles = selectedFiles.filter(item => item.id !== itemToRemove.id);
setSelectedFiles(updatedFiles);

onFilesChange(updatedFiles.map(file => ({
originalFile: file.originalFile,
url: file.url,
creator: file.creator,
sourceWebsite: file.sourceWebsite
})));
};

const onFilesDrop = (event) => {
event.preventDefault();
if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
const newFiles = Array.from(event.dataTransfer.files).map((file, index) => {
return new Promise((resolve) => {
const reader = new FileReader();
reader.onloadend = () => {
resolve({
id: `file-${Date.now()}-${index}`,
url: reader.result,
originalFile: file,
creator: '',
sourceWebsite: ''
});
};
reader.onerror = () => console.error('File reading error');
reader.readAsDataURL(file);
});
});

Promise.all(newFiles)
.then(newFiles => {
onFilesSelected(newFiles);
})
.catch(error => {
console.error('Error reading files:', error);
setErrorMessage('Error reading files');
});
} else {
setErrorMessage('Cannot select more than 10 images');
}
};

const simulateUpload = async (fileId, fileSize) => {
const uploadTime = (fileSize / 1024 / 1024) * 6000; // 6 seconds per MB
let progress = 0;
const interval = 100; // Update interval in ms

return new Promise(resolve => {
const uploadInterval = setInterval(() => {
progress += (interval / uploadTime) * 100;
if (progress >= 100) {
clearInterval(uploadInterval);
setUploadProgress(prev => ({ ...prev, [fileId]: 100 }));
resolve();
} else {
setUploadProgress(prev => ({ ...prev, [fileId]: progress }));
}
}, interval);
});
};

useEffect(() => {
if (selectedFiles.some(file => file.status === 'waiting')) {
uploadFilesSequentially();
}
}, [selectedFiles]);

return (
<div className={`images-box ${size}`}>
<label className="images-box-label">{label}</label>
{errorMessage && <div className="error-message">{errorMessage}</div>}
<div 
className={`images-box-container`}
onClick={openFileDialog}
>
<input 
id="file-upload"
ref={fileInputRef}
type="file"
multiple
className="file-upload-input"
accept="image/*"
onChange={onFilesChangeLocal}
/>
<div className="images-box-drag-drop">
<img src={attachment} alt="Attachment Icon" className="attachment-icon" />
{selectedFiles.length ? (
<div className="images-box-file-tab">
{selectedFiles.map((item, index) => (
<div key={item.id || item.url}>
<span className='filename'>{getFileName(item)}</span>
</div>
))}

</div>
) : (
<span className='help-text'>Attach files</span>
)}
</div>
</div>
<div className='images-preview-container'>
{selectedFiles.map((item, index) => (
<div key={item.id || index} className="file-wrapper">
<div className={`image-preview ${item.status}`}>
{/* Display the upload progress */}
{item.status !== 'uploaded' && (
<div className="upload-progress-container">
<div className="upload-progress-bar" style={{ width: `${uploadProgress[item.id] || 0}%` }}></div>
<div className="upload-progress-text">{`Uploading: ${Math.round(uploadProgress[item.id] || 0)}%`}</div>
</div>
)}

{/* Display the uploaded image */}
{item.status === 'uploaded' && (
<img src={item.url} alt="Preview" className="uploaded-image" />
)}

{/* Display the remove button */}
<button className="close-icon-preview" onClick={() => removeSelectedFile(item)}>
<img src={close} alt="Close Icon" />
</button>
</div>

<div className="file-details">
{/* Display image size for uploaded images */}
{item.status === 'uploaded' && item.size && (
<div className="image-size">
<p>{`${(item.size / 1024).toFixed(2)} KB`}</p>
</div>
)}

{/* Display InputBoxes for 'Creator' and 'Source Website' */}
<InputBox
title="Creator"
type="text"
size="medium"
value={item.creator}
onChange={e => handleMetadataChange(item.id, 'creator', e.target.value)}
/>
<InputBox
title="Source Website"
type="text"
size="medium"
value={item.sourceWebsite}
onChange={e => handleMetadataChange(item.id, 'sourceWebsite', e.target.value)}
/>
</div>

</div>
))}
</div>

<ImagesModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        onUploadSelected={handleFilesApproval}
        initialImages={imagesForModal}
      />

</div>
);
};

export default ImagesBox;