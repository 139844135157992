import React, { useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useMyBucketlist } from '../MyBucketlistContext';
import UserContext from '../UserContext';
import Button from '../button/Button';
import './MyBucketlistButton.css';

const getUserIdFromToken = () => {
  const token = localStorage.getItem('token');
  return token ? jwtDecode(token).userId : null;
};

const MyBucketlistButton = ({ activityId, size = 'medium' }) => {
  const { userType } = useContext(UserContext);
  const { myBucketlist, dispatch } = useMyBucketlist();
  const isMyBucketlisted = myBucketlist.includes(activityId);
  const navigate = useNavigate();

  const handleMyBucketlistClick = async (e) => {
    e.stopPropagation();
    if (!userType) {
      return navigate('/login');
    }

    const token = localStorage.getItem('token');
    const userId = getUserIdFromToken();
    const action = isMyBucketlisted ? 'remove' : 'add';

    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/user/${userId}/myBucketlisted-activities`,
        { activityId, action },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Update local state to reflect the change
      dispatch({
        type: isMyBucketlisted ? 'REMOVE_FROM_MYBUCKETLIST' : 'ADD_TO_MYBUCKETLIST',
        activityId
      });

      // Optionally re-fetch the updated bucketlist
      const fetchResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/user/${userId}/myBucketlisted-activities`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const activityIds = (fetchResponse?.data?.activities || []).map(activity => activity._id);
      dispatch({ type: 'SET_MYBUCKETLIST', activityIds });
    } catch (error) {
      console.error('Error updating myBucketlist:', error);
    }
  };

  return (
    <Button 
      variant={isMyBucketlisted ? 'remove' : 'secondary'} 
      size={size}
      handleClick={handleMyBucketlistClick} 
      label={isMyBucketlisted ? 'Remove From Bucketlist' : 'Add To Bucketlist'}
      className={`bucketlist-button-size-${size}`}
    />
  );
};

export default MyBucketlistButton;
