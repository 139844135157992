import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/button/Button';
import Image404 from '../assets/images/404.png';
import UserContext from '../components/UserContext';
import './Error404.css';

const Error404 = () => {
  const navigate = useNavigate();
  const { userType } = useContext(UserContext);
  const isAdmin = userType === 'admin';

  useEffect(() => {
    if (isAdmin) {
      navigate('/adminDashboard');
    }
  }, [isAdmin, navigate]);

  return (
    <div className="error404-container">
        <img className='e404-image' src={Image404} alt="404 Error" />
      <div className='e404-container'>
      <h2>"Looks like this page took a detour. Maybe it’s looking for the meaning of life? Anyway, home is where the heart is."</h2>

      <Button 
        label="Back to Homepage" 
        to="/" 
        type="button" 
        variant="primary" 
        size="large" 
      />
    </div>
    </div>
  );
};

export default Error404;
