import React, { useState, useEffect, useCallback } from 'react';
import Dropdown from '../dropdown/Dropdown';
import axios from 'axios';
import './UserCount.css';

const UserCount = () => {
    const [countData, setCountData] = useState({ totalCount: 0, countChange: 0 });
    const [selectedDuration, setSelectedDuration] = useState({ label: 'Since inception', value: 'since inception' });
    const [error, setError] = useState(null);

    const durations = [
        { label: '30 mins', value: '30m' },
        { label: '1 hour', value: '1h' },
        { label: '1 day', value: '1d' },
        { label: '1 week', value: '1w' },
        { label: '1 month', value: '1m' },
        { label: '3 months', value: '3m' },
        { label: '6 months', value: '6m' },
        { label: '1 year', value: '1y' },
        { label: 'Since inception', value: 'since inception' }
    ];

    const fetchData = useCallback(async () => { 
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/userCount?duration=${selectedDuration.value}`);
            setCountData(response.data);
            setError(null);
        } catch (err) {
            console.error('Error fetching user count:', err.message);
            setError('Failed to fetch user count. Please try again.');
        }
    }, [selectedDuration]);

    useEffect(() => {
        fetchData();

        const interval = setInterval(fetchData, 5000);

        return () => clearInterval(interval);
    }, [fetchData]);

    return (
        <div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <span>Total Users: {countData.totalCount}</span> <br/>
            <span>Previous Users: {countData.previousCount}</span> <br/>
            <span>Change: 
                {countData.countChange >= 0 ? '↑' : '↓'} 
                <span style={{ color: countData.countChange >= 0 ? 'green' : 'red' }}>
                    {Math.abs(countData.countChange)}
                </span>
            </span>
            <Dropdown 
                title="Select Duration" 
                options={durations} 
                value={selectedDuration}
                onChange={(selectedOption) => setSelectedDuration(selectedOption)} 
            />
        </div>
    );
    
}

export default UserCount;
